import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Link, Slide, Skeleton } from "@mui/material";
import axios from "axios";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function FEATermsOfServiceLink({ children, underline }) {
    
    const terms = useRef("");
    const [ open, setOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        if (open) {
            setIsLoading(true);
            // Fetches terms from /public folder
            axios.get(`${process.env.PUBLIC_URL}/terms_of_service.txt`, {
                cancelToken: source.token
            })
                .then((response) => response.data)
                .then(text => terms.current = text)
                .then(() => setIsLoading(false))
    
            return function cleanup() {
                // Cancels requests (cleanup) on unmount
                source.cancel()
            }
        }
    }, [open])

    return (
        <React.Fragment>
            <Link underline={underline || "always"} onClick={handleOpen}>{children || "Terms of Service"}</Link>
            <Dialog 
                open={open}
                scroll="paper"
                onClose={handleClose}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Terms of Service
                    <IconButton
                        size="large"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {isLoading ? (
                        <>
                            <Skeleton variant="rectangular" width="50%" style={{ marginBottom: 6 }}/>
                            <Skeleton variant="rectangular" width="100%" style={{ marginBottom: 6 }}/>
                            <Skeleton variant="rectangular" width="75%" style={{ marginBottom: 6 }}/>
                        </>
                    ) : (
                        <pre style={{ whiteSpace: "pre-wrap" }}>
                            {terms.current}
                        </pre>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default FEATermsOfServiceLink;