import { Link } from "@mui/material";

function DwollaPrivacyPolicyLink({ children, underline }) {
    return (
        <Link 
            underline={underline || "always"}
            target="_blank"
            rel="noreferrer"
            href="https://www.dwolla.com/legal/privacy/"
        >
            {children || "Privacy Policy"}
        </Link>
    )
}

export default DwollaPrivacyPolicyLink;