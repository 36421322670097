import { OpenInBrowser } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: '1rem',
        gap: '1rem',
        marginBottom: '2rem',
    },
    icon: {
        fontSize: "3rem",
    },
    bankIcon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export const loadPlaidJS = () => new Promise((resolve) => {
    if (window.Plaid) {
        console.log("Plaid JS is already loaded.")
        resolve();
    } else {
        // Creates <script> element to load Plaid's JS library
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        
        s.addEventListener('load', () => {
            console.log(`Plaid JS library loaded.`);
            resolve();
        })
        
        // Appends <script> element to the page body
        document.body.appendChild(s)
    }
})

function PlaidAccountVerification({ linkToken, onCancel, onError, onSuccess }) {
    
    const classes = useStyles();

    const [ plaidLink, setPlaidLink ] = useState();
    const [ ready, setReady ] = useState(false);
    const [ open, setOpen ] = useState(false);

    const onLinkSuccess = (_, metadata) => {
        if (process.env.REACT_APP_ENABLE_NME_DEBUG_DATA === "true") {
            console.log("onLinkSuccess", metadata)
        }
        setOpen(false);
        
        // Extract token and account data from Plaid metadata
        const { public_token, accounts } = metadata;

        onSuccess({
            public_token,
            accounts,
        });
    };
    
    const onLinkExit = (error, metadata) => {
        if (process.env.REACT_APP_ENABLE_NME_DEBUG_DATA === "true") {
            console.log("onLinkExit", error, metadata)
        }
        setOpen(false);
        
        if (!error) {
            onCancel(metadata);
        }
    };
    
    const onLinkEvent = (eventName, metadata) => {
        if (process.env.REACT_APP_ENABLE_NME_DEBUG_DATA === "true") {
            console.log("onLinkEvent", eventName, metadata)
        }
        // Handle errors in the event end-user does not exit with onExit function error enabled.
        if (metadata.error_code != null) {
            onError(metadata);
        }
    };

    useEffect(() => {
        setReady(false);

        if(linkToken) {
            console.log("Loading Plaid Link...");

            loadPlaidJS()
                .then(() => {
                    const linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: linkToken,
                        onLoad: () => {
                            // Link module finished loading.
                            console.log("Plaid Link loaded.");
                            setReady(true);
                        },
                        onSuccess: onLinkSuccess,
                        onEvent: onLinkEvent,
                        onExit: onLinkExit,
                    });

                    setPlaidLink(linkHandler);
                })
                .catch(onError)
        }
        // eslint-disable-next-line
    }, [linkToken])

    useEffect(() => {
        // Initiallizes Link automatically when ready
        if (ready && plaidLink && !open) {
            // Flags link as open
            setOpen(true);
            // Initiates Plaid link dialog
            plaidLink.open();
        }
        // eslint-disable-next-line
    }, [ready, plaidLink]);
    
    return ready ? (
        <div className={classes.container}>
            <OpenInBrowser color="inherit" className={classes.icon} />
            <Typography variant="h6"  sx={{ textAlign: "center" }}>
                Waiting for Plaid account set up...
            </Typography>
        </div>
    ) : (
        <div className={classes.container}>
            <CircularProgress color="inherit" className={classes.icon} />
            <Typography variant="h6" sx={{ textAlign: "center" }}>
                Loading Plaid secure environment...
            </Typography>
        </div>
    );
}

export default PlaidAccountVerification;