import { Error as ErrorIcon } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Buffer } from "buffer";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BlurredBackdrop from "../../components/BlurredBackdrop";
import { useAuth } from "../../providers/AuthProvider";
import { useNotifications } from "../../providers/NotificationProvider";
import useQuery from "../../util/QueryParams";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        flexDirection: "column",
        gap: "1rem",
    },
    icon: {
        fontSize: "3rem",
    },
}));

function AuthenticateWithTokenView() {

    const classes = useStyles();
    const history = useHistory();
    const { token } = useQuery();
    const { authenticate } = useAuth();
    const showNotification = useNotifications();

    const [ error, setError ] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (token) {
            // Token is expected to be encoded in base64
            const decodedToken = Buffer.from(token, 'base64');
            
            // Check if token is valid
            authenticate(decodedToken?.toString(), source.token)
                .then(() => {
                    showNotification({ message: "Successfully logged in! Remember to log out when you're done.", severity: 'success', duration: 20000 });
                    history.replace('/home');
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log("Invalid token", error);
                        setError(true);
                    }
                });
        } else {
            console.log("No token provided.");
            setError(true);
        }

        return function cleanup() {
            // Cancels requests (cleanup) on unmount
            source.cancel()
        }
        // eslint-disable-next-line 
    }, [token])

    return (
        <BlurredBackdrop open className={classes.backdrop}>
            {error ? (
                <>
                    <ErrorIcon color="inherit" className={classes.icon}/>
                    <Typography variant="h6">
                        Invalid or expired authentication link
                    </Typography>
                </>
            ) : (
                <>
                    <CircularProgress color="inherit" className={classes.icon}/>
                    <Typography variant="h6">
                        Logging you in...
                    </Typography>
                </>
            )}
        </BlurredBackdrop>
    )
}

export default AuthenticateWithTokenView;