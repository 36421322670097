import { ThumbUpAlt } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function EmailSyncDialog({ open, primaryEmailAddress, newEmailAddress, onConfirm }) {

    const { control, handleSubmit, watch } = useForm({
        mode: "onChange"
    });

    const answer = watch("overridePrimaryEmailAddress");

    const submitForm = handleSubmit(onConfirm);

    return (
        <Dialog open={open || false} maxWidth="sm">
            <DialogTitle>Primary Email Address</DialogTitle>
            <DialogContent>
                <form onSubmit={submitForm}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                The email address you typed is different than the primary email address we have on file:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                label="Current Primary Email" 
                                variant="outlined" 
                                value={primaryEmailAddress}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth disabled />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                label="New Email Address" 
                                variant="outlined" 
                                value={newEmailAddress}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth disabled />
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <Controller
                                    name="overridePrimaryEmailAddress"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ 
                                        required: true,
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <>
                                            <FormLabel>Do you want to update your primary email address?</FormLabel>
                                            <RadioGroup {...field}>
                                                <FormControlLabel value={true} control={<Radio />} label="Yes, update my primary email" />
                                                <FormControlLabel value={false} control={<Radio />} label={<>No, leave my primary email as <b>{primaryEmailAddress}</b> and use <b>{newEmailAddress}</b> for eDues communications</>} />
                                            </RadioGroup>
                                            {Boolean(error) && (
                                                <FormHelperText>{error.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={submitForm} 
                    color="primary"
                    variant="contained"
                    startIcon={<ThumbUpAlt />}
                    disabled={answer === null || answer === undefined}
                    fullWidth
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmailSyncDialog;