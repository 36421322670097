import { useState } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Drawer, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsView from "../SettingsView";
import Menu from "./Menu";
import SignOut from "./SignOut";

import "./Home.css";

const routes = [
    {
        name: "Account",
        items: [
            {
                path: "/account/settings",
                name: "Settings",
                exact: false,
                component: SettingsView
            },
            {
                path: "/account/signout",
                name: "Sign out",
                exact: true,
                component: SignOut
            }
        ]
    }
];

const landingRoute = routes[0].items[0].path;

const createRoute = ({ name, path, exact, component: Component}) => {
    return (
        <Route key={path} path={path} exact={exact}>
            <Component title={name}/>
        </Route>
    );
};

function HomeView() {
    const [ sidebarOpen, setSidebarOpen ] = useState(false);
    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);

    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <div className="home">
            {isMobileDevice ? (
                <>
                    <AppBar position="sticky">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={openSidebar}>
                                <MenuIcon />
                            </IconButton>
                            <Link underline="hover" to={landingRoute} style={{ color: "#fff", textDecoration: "none" }}>
                                <Typography variant="h6">
                                    FEA Member Portal
                                </Typography>
                            </Link>
                        </Toolbar>
                    </AppBar>
                    <Drawer anchor="left" open={sidebarOpen} onClose={closeSidebar}>
                        <Menu options={routes} onItemClicked={closeSidebar}/>
                    </Drawer>
                </>
            ) : (
                <Menu className="sidebar" options={routes}/>
            )}
            <main>
                <Switch>
                    {routes.map(route => {
                        if (route.items !== undefined) {
                            return route.items.map(subroute => createRoute(subroute))
                        } else if (route.component !== undefined) {
                            return createRoute(route);
                        }
                        return null;
                    })}
                    {/* Redirects user to memberships page when route is not defined */}
                    <Route path="*">
                        <Redirect to={landingRoute}/>
                    </Route>
                </Switch>
            </main>
        </div>
    );
}

export default HomeView;