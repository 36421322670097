import React, { useEffect, useRef, useState } from "react";
import { Grid, TextField, Typography, Autocomplete, createFilterOptions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocationOn } from "@mui/icons-material";
import axios from "axios";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    })
);

// Used for custom filtering in the Autocomplete
const filter = createFilterOptions();

const SmartyStreetsAutocomplete = React.forwardRef(({ label, error, fullWidth, helperText, onChange, onSelect, required, ...props }, ref) => {
    
    const classes = useStyles();

    const [ inputValue, setInputValue ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);

    const options = useRef([]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        setIsLoading(true);

        if(inputValue === "") {
            options.current = [];
            setIsLoading(false);
        } else {
            setTimeout(() => {
                axios.get(`${process.env.REACT_APP_SMARTYSTREETS_API_URI}?key=${process.env.REACT_APP_SMARTYSTREETS_KEY}&search=${inputValue}&source=all&license=us-autocomplete-pro-cloud`, {
                    cancelToken: source.token
                })
                    .then(response => response.data)
                    .then(({ suggestions }) => {
                        const addresses = _.uniqBy(suggestions, (o) => o.street_line + o.city + o.state + o.zipcode);
                        options.current = (addresses || []);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }, 100)
        }
        
        return function cleanup() {
            // Cancels requests (cleanup) on unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [inputValue])

    return (
        <Autocomplete
            {...props}
            ref={ref}
            autoComplete
            autoSelect
            autoHighlight
            blurOnSelect
            handleHomeEndKeys
            includeInputInList
            noOptionsText={inputValue && inputValue.length > 0 ? "No matching address found." : "Start typing to load options."}
            options={options.current}
            loading={isLoading}
            loadingText="Loading..."
            getOptionLabel={(option) => (typeof option === 'string' ? option : option?.street_line)}
            isOptionEqualToValue={(option, value) => option?.street_line === value}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // If there were options in the query but none after filtering out(i.e. added apartment number)
                // Create a custom one using the matching city, state, and zipcode
                if (params.inputValue !== '' && filtered.length === 0) {
                    const potentialOption = options.find((opt) => params.inputValue.includes(opt.street_line))

                    if (potentialOption) {
                        filtered.push({
                            ...potentialOption,
                            inputValue: params.inputValue,
                            street_line: params.inputValue,
                        });
                    } else {
                        filtered.push({
                            customAddress: true,
                            inputValue: params.inputValue,
                            street_line: params.inputValue,
                        });
                    }
                }
                
                return filtered;
            }}
            onChange={(event, selectedOption, reason) => {
                // Triggers onSelect event and passes the whole object
                onSelect(selectedOption);
                // Trigger onChange passing only the street value
                onChange(selectedOption?.street_line || "");
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    label={label}
                    error={error}
                    helperText={helperText}
                    variant="outlined" 
                    fullWidth={fullWidth}
                    required={required} />
            )}
            renderOption={(props, option) => (
                <Grid 
                    {...props} 
                    key={`${option?.street_line}, ${option?.city}, ${option?.state} ${option?.zipcode}`} 
                    container alignItems="center"
                >
                    <Grid item>
                        <LocationOn className={classes.icon}/>
                    </Grid>
                    <Grid item xs>
                        {option.street_line}
                        {option.city && option.state && option.zipcode && (
                            <Typography variant="body2" color="textSecondary">
                                {option.city}, {option.state} {option.zipcode}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            )} 
            />
    )
});

export default SmartyStreetsAutocomplete;