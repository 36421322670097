import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, useLocation, matchPath } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import "./CaptchaProtectedRoute.css";

// A wrapper for <Route> that requests user to
// complete captcha challenge before proceding
function CaptchaProtectedRoute({ children, path, subroutesToBypass = [], ...rest }) {

    const { pathname } = useLocation();
    
    const [ captchaToken, setCaptchaToken ] = useState(false);
    const [ isNotARobot, setIsNotARobot ] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaToken(value);
    }

    const handleContinue = () => {
        if (captchaToken && captchaToken.length > 0) {
            setIsNotARobot(true);
        }
    }

    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {   
        // First checks if subroutesToBypass is an array
        if (Symbol.iterator in Object(subroutesToBypass)) {
            // Checks if current pathname is in list of subroutes to bypass     
            for (const subroute of subroutesToBypass) {
                // Tests current pathname
                const match = matchPath(pathname, {
                    path: (path + subroute),
                    exact: false,
                    strict: false
                })

                // If matched with any of the subroutes,
                // then it skips recaptcha test
                if (match) {
                    // Forces `isNotARobot` flag to true
                    setIsNotARobot(true);
                    break;
                }
            }
        }
    }, [pathname, path, subroutesToBypass])

    return (
        <Route path={path} {...rest} render={({ location }) => (
            // Renders children components if and only if user has completed captcha
            isNotARobot ? (
                children
            ) : (
                <Dialog 
                    open
                    fullScreen={isMobileDevice}
                    className="split-dialog"
                >
                    <Box className="logo-panel" sx={{ display: { xs: 'flex', md: 'flex' } }}>
                        <div className="logo" />
                    </Box>
                    <Box className="content" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <DialogContent>
                            <Typography 
                                component="div"
                                variant="subtitle2" 
                                color="textSecondary" 
                                sx={{ marginBottom: "1rem", maxWidth: { sm: "300px" } }}
                            >
                                Please check the "I'm not a robot" box below and click continue to be taken to the enrollment form.
                            </Typography>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY}
                                onChange={handleCaptchaChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                fullWidth
                                color="primary" 
                                variant="contained" 
                                disabled={!captchaToken}
                                onClick={handleContinue}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            )
        )}/>
    );
}

export default CaptchaProtectedRoute;