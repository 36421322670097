import { memo, useState } from "react";
import { Alert, AlertTitle, Button, Dialog, Typography, useMediaQuery } from "@mui/material";
import { CreditCard, Fingerprint } from "@mui/icons-material";
import BlurredBackdrop from "../../../../components/BlurredBackdrop";
import DwollaACHSetup from "../../../../components/DwollaACHSetup";
import { AccountVerificationStatus, UCAStatus } from "..";

import "./PaymentMethodAlert.css";

const PaymentMethodAlert = memo(({ customerId, status, terms, verificationStatus, onSuccess, onClose }) => {

    const [ dialogOpen, setDialogOpen ] = useState(false);
    
    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('md'));

    const handleOpen = () => setDialogOpen(true);

    const handleClose = (e, reason) => {
        if (reason !== "backdropClick") {
            setDialogOpen(false);
            onClose();
        }
    }

    const onPaymentSuccess = () => {
        handleClose();
        // Delays on success call to show closing effect
        setTimeout(() => {
            onSuccess();
        }, 200);
    }

    const severity = status?.id === UCAStatus.SETUP_COMPLETE ? (
        "success"
    ) : (status?.id === UCAStatus.AWAITING_VERIFICATION) ? (
        "info"
    ) : (
        "warning"
    );

    const buttonText = status?.id === UCAStatus.AWAITING_VERIFICATION ? (
        "Verify Now"
    ) : status?.id === UCAStatus.SETUP_COMPLETE ? (
        "Change Payment Method"
    ) : (
        "Set up Now"
    );

    return (
    <>
        <Alert 
            className="payment-alert"
            severity={severity}
            action={status?.id === UCAStatus.AWAITING_VERIFICATION 
                && (verificationStatus?.id === AccountVerificationStatus.AUTOMATED_MICRO_DEPOSIT_INITIATED || verificationStatus?.id === AccountVerificationStatus.NEEDS_MORE_INFORMATION) ? (
                // Do not show button if account is awaiting automated micro-deposits or is needing more information
                null
            ) : (
                <Button
                    size="small"
                    disableElevation
                    onClick={handleOpen}
                    variant={status?.id === UCAStatus.AWAITING_VERIFICATION ? "contained" : "outlined"}
                    color={severity}
                    startIcon={status?.id === UCAStatus.AWAITING_VERIFICATION ? <Fingerprint /> : <CreditCard /> }
                >
                    {buttonText}
                </Button>
            )}
        >
            {status?.id === UCAStatus.AWAITING_VERIFICATION ? (
                <>
                    <AlertTitle><b>New Account Pending Verification</b></AlertTitle>
                    It looks like your payment method is pending verification.
                    The verification deposit should post to your account within one business day but it can take up to 7 days.&nbsp;
                    {verificationStatus?.id === AccountVerificationStatus.MICRO_DEPOSIT_INITIATED ? (
                        <><b>Once you receive the micro-deposit</b>, click the button to verify account ownership.</>
                    ) : (
                        <>The verification will be done automatically and <b>no action is required</b> right now.</>
                    )}
                    <br/><br/>
                    <Typography variant="subtitle2">
                        <b>Verification Requested On:</b> {status?.lastUpdated.toLocaleDateString()}
                    </Typography>
                </>
            ) : status?.id === UCAStatus.SETUP_COMPLETE ? (
            <>
                <AlertTitle><b>You are all set!</b></AlertTitle>
                It looks like your payment method is good to go.
            </>
            ) : (
            <>
                <AlertTitle><b>Pending Payment Method Set-up</b></AlertTitle>
                It looks like you chose to pay your dues with ACH but you still need to set up your payment method.
            </>
            )}
        </Alert>
        <Dialog 
            open={dialogOpen}
            BackdropComponent={BlurredBackdrop}
            onClose={handleClose}
            scroll="body"
            fullScreen={isMobileDevice}
            fullWidth
        >
            {/* If status is NOT pending, Dialog should open in "create new account" mode */}
            <DwollaACHSetup dwollaCustomerId={customerId} paymentTerms={terms} onCancel={handleClose} onSuccess={onPaymentSuccess} />
        </Dialog>
    </>
    )
})

export default PaymentMethodAlert;