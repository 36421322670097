import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../../components/LoadingButton";
import { isValidEmailAddress } from "../../util/Validators";
import { useNotifications } from "../../providers/NotificationProvider";
import AuthService from "../../services/AuthService";

function ForgotPasswordDialog({ username, onClose }) {
    
    const showNotification = useNotifications();

    const [ open, setOpen ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const { control, formState: { isValid }, handleSubmit, reset } = useForm({
        mode: "onChange"
    });
    
    const onSubmit = ({ email }) => {
        // Triggers loading state
        setIsLoading(true);

        AuthService.sendAccountRecoveryEmail(email)
            .then(() => {
                showNotification({ message: "Your password reset request has been received. If there is an account matching the email address provided, you will receive an email with a link to reset your password.", severity: 'success', duration: 10000 });
                handleClose();
            })
            .catch((error) => {
                showNotification({ message: error.message, severity: 'error' });
                setIsLoading(false);
            });
    }

    const handleClose = (event, reason) => {
        // Prevents dialog from closing when user clicks the backdrop or if it is still loading
        if (reason !== 'backdropClick' && !isLoading) {
            setOpen(false);
            // Delays the onClose event for 100ms (so that the closing animation shows up)
            setTimeout(onClose, 100);
        }
    }

    const submitForm = handleSubmit(onSubmit);

    useEffect(() => {
        reset({ email: username })
    }, [reset, username])

    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            maxWidth="xs"
        >
            <DialogTitle>Password Recovery</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Please enter the email address associated with your account:
                </Typography>
                <form onSubmit={submitForm}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: true,
                            validate: {
                                isValidEmailAddress
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField 
                                type="email"
                                margin="dense"
                                label="Email address" 
                                autoComplete="username" 
                                error={Boolean(error)} 
                                helperText={(error && error.message)}
                                required autoFocus fullWidth {...field}/>
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions className="space-between">
                <Link 
                    onClick={handleClose} 
                    underline={isLoading ? "none" : "hover"}
                    color={isLoading ? "textSecondary" : "primary"}
                >
                    Cancel
                </Link>
                <LoadingButton 
                    onClick={submitForm} 
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    loading={isLoading}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ForgotPasswordDialog;