import { useRef, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, Grid, InputAdornment, Link, TextField } from "@mui/material";
import { CheckCircle, VpnKey } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../LoadingButton";
import { useAuth } from "../../providers/AuthProvider";
import { useNotifications } from "../../providers/NotificationProvider";
import { isValidPassword } from "../../util/Validators";

import "./ChangePasswordForm.css";
import PasswordRequirements from "./PasswordRequirements";

const defaultValues = {
    "currentPassword": "",
    "newPassword": "",
    "confirmPassword": "",
}

function ChangePasswordForm({ onSuccess, onCancel, subheader }) {

    const { control, formState: { errors, isValid }, handleSubmit, reset, setValue, watch } = useForm({
        mode: "onChange",
        defaultValues
    });

    const { user, updatePassword } = useAuth();
    const showNotification = useNotifications();

    const invalidCredentials = useRef(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showRequirements, setShowRequirements ] = useState(false);
    
    const currentPassword = watch("currentPassword");
    const newPassword = watch("newPassword");
    const confirmPassword = watch("confirmPassword");

    const showPasswordRequirements = () => setShowRequirements(true);
    const hidePasswordRequirements = () => setShowRequirements(false);

    const handleChangePassword = () => {
        setIsLoading(true);
        
        updatePassword(currentPassword, newPassword)
            .then(() => {
                showNotification({ message: "Password reset successfully!", severity: 'success' });

                if(onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    invalidCredentials.current = true;
                    showNotification({ message: "Current password invalid. Try again!", severity: 'error', duration: 10000 });
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    showNotification({ message: error.message, severity: 'error' });
                }
                // Resets form fields
                reset(defaultValues);
                // Stops loading indicator
                setIsLoading(false);
            })
    }
    
    const submitForm = handleSubmit(handleChangePassword);

    return (
        <Card>
            <CardHeader title="Change Password" subheader={subheader} />
            <CardContent>
                <form onSubmit={submitForm}>
                    {
                    /* The following input field is here just for compliance with the Chromium form design guidelines.
                     * It suppresses the warning "[DOM] Password forms should have (optionally hidden) username fields for accessibility" in the console.
                     * REF.: https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                     */
                    }
                    <input type="text" name="username" defaultValue={user.username} autoComplete="username email" style={{ display: "none" }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="currentPassword"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, ...field } }) => (
                                    <TextField 
                                        label="Old Password" 
                                        variant="outlined" 
                                        type="password" 
                                        autoComplete="current-password" 
                                        error={invalidCredentials.current}
                                        onChange={(e) => {
                                            invalidCredentials.current = false;
                                            onChange(e);
                                        }}
                                        required fullWidth {...field}/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{ 
                                    required: "Please type a new password.",
                                    validate: {
                                        isValidPassword
                                    }
                                }}
                                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                                    <TextField 
                                        error={Boolean(error)}
                                        helperText={(error && error.message)} 
                                        disabled={!currentPassword}
                                        onChange={(e) => {
                                            // Empties confirm password field
                                            setValue("confirmPassword", "");
                                            onChange(e);
                                        }}
                                        inputProps={{
                                            onFocus: showPasswordRequirements,
                                            onBlur: hidePasswordRequirements,
                                        }}
                                        label="New password" 
                                        variant="outlined" 
                                        type="password" 
                                        autoComplete="new-password" 
                                        required fullWidth {...field}/>
                                )}
                            />
                        </Grid>
                        <PasswordRequirements hide={!showRequirements} password={newPassword} />
                        <Grid item xs={12}>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{ 
                                    required: "Please confirm password",
                                    validate: {
                                        matchesPreviousPassword: (value) => {
                                            return newPassword === value || "Passwords should match.";
                                        }
                                    }
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                        error={Boolean(error)}
                                        helperText={error && error.message} 
                                        disabled={!newPassword || Boolean(errors["newPassword"])}
                                        label="Confirm new password" 
                                        variant="outlined" 
                                        type="password" 
                                        autoComplete="confirm-password"
                                        InputProps={{
                                            endAdornment: confirmPassword && !error && (
                                                <InputAdornment position="end">
                                                    <CheckCircle color="primary"/>
                                                </InputAdornment>
                                            )
                                        }}
                                        required fullWidth {...field}/>
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <CardActions className={onCancel ? "space-between" : "align-right"}>
                {onCancel && (
                    <Link underline="hover"onClick={onCancel}>Cancel</Link>
                )}
                <LoadingButton 
                    variant="contained" 
                    color="primary"
                    startIcon={<VpnKey />}
                    disabled={!isValid}
                    loading={isLoading}
                    onClick={submitForm}
                >
                    Change Password
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default ChangePasswordForm;