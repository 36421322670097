import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NewMemberEnrollmentView from "./views/NewMemberEnrollmentView";
import AuthenticateWithTokenView from "./views/AuthenticateWithTokenView";
import HomeView from "./views/HomeView";
import LoginView from "./views/LoginView";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import AuthProvider from "./providers/AuthProvider";
import NotificationProvider from "./providers/NotificationProvider";
import FastTrackEnrollmentView from "./views/FastTrackEnrollmentView";
import CaptchaProtectedRoute from "./components/CaptchaProtectedRoute";

import "./App.css";

// REF.: https://material-ui.com/customization/palette/
const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#002b52",
            // main: "#022038",
        },
        secondary: {
        //     light: "#0066ff",
            main: "#ff9000",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <NotificationProvider>
                <AuthProvider>
                    <CssBaseline />
                    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                        <ScrollToTop />
                        <Switch>
                            <Route exact path="/authenticate">
                                <AuthenticateWithTokenView />
                            </Route>
                            <Route path={"/(signin|registration|recovery)"}>
                                <LoginView />
                            </Route>
                            <CaptchaProtectedRoute path="/fasttrack/:preSelectedLocalUnionId(\d+)?">
                                <div className="fasttrack-enrollment-container">
                                    <FastTrackEnrollmentView />
                                </div>
                            </CaptchaProtectedRoute>
                            <CaptchaProtectedRoute path="/newEnrollment/:preSelectedLocalUnionId(\d+)?" subroutesToBypass={["/sign"]}>
                                <div className="enrollment-container">
                                    <NewMemberEnrollmentView />
                                </div>
                            </CaptchaProtectedRoute>
                            <PrivateRoute path="/*">
                                <HomeView />
                            </PrivateRoute>
                        </Switch>
                    </BrowserRouter>
                </AuthProvider>
            </NotificationProvider>
        </ThemeProvider>
    );
}

export default App;
    