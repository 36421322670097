import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { CheckCircle, OpenInNew, PersonPin, RotateLeft, Save } from "@mui/icons-material";
import { Autocomplete, Card, CardActions, CardContent, CardHeader, TextField, Grid, Typography, Paper, FormControlLabel, Checkbox, InputAdornment, CircularProgress, Dialog, Link } from '@mui/material';
import { doesNotContainDoubleQuotes, isValidEmailAddress, isValidPassword } from "../../util/Validators";
import { states } from "../../resources";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import { useNotifications } from "../../providers/NotificationProvider";
import { MoneyInput, PhoneInput, ZipInput } from "../../components/FormattedInputs";
import SmartyStreetsAutocomplete from "../../components/SmartyStreetsAutocomplete";
import PasswordRequirements from "../../components/ChangePassword/PasswordRequirements";
import ResizableSignatureCanvas from "../../components/ResizableSignatureCanvas";
import ConfirmActionButton from "../../components/ConfirmActionButton";
import MemberService from "../../services/MemberService";
import EnrollmentService from "../../services/EnrollmentService";
import LocalUnionHeader from "../../components/LocalUnionHeader";
import LoadingButton from "../../components/LoadingButton";
import SignInForm from "../LoginView/SignInForm";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: '.5rem',
    },
    icon: {
        fontSize: "3rem !important",
    },
}));

function FastTrackForm({ navigateBack, navigateForward, save, saveAndNavigate }) {
    
    const classes = useStyles();
    const showNotification = useNotifications();
    const { localUnionInfo } = useEnrollmentInfo();
    const { control, formState: { errors }, handleSubmit, trigger, setFocus, setValue, getValues, watch } = useForm({
        mode: "onChange"
    });
    
    const duesInfo = useRef();
    const emailIsAvailable = useRef(true);
    
    // Holds a ref to the signature canvas
    const signatureCanvasRef = useRef();
    
    const [ jobs, setJobs ] = useState([]); 
    const [ worksites, setWorksites ] = useState([]); 
    const [ paymentSchedules, setPaymentSchedules ] = useState([]); 

    const [ isSigned, setIsSigned ] = useState(false);
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ isLoggingIn, setIsLoggingIn ] = useState(false);
    const [ isLoggingOut, setIsLoggingOut ] = useState(false);
    const [ isCheckingIfAccountExists, setIsCheckingIfAccountExists ] = useState(false);
    const [ isLoadingOptions, setIsLoadingOptions ] = useState(false);
    const [ isLoadingDuesInfo, setIsLoadingDuesInfo ] = useState(false);
    const [ isLoadingPaymentSchedules, setIsLoadingPaymentSchedules ] = useState(false);
    const [ isLoadingDefaultPaymentSchedule, setIsLoadingDefaultPaymentSchedule ] = useState(false);
    const [ showRequirements, setShowRequirements ] = useState(false);

    const showPasswordRequirements = () => setShowRequirements(true);
    const hidePasswordRequirements = () => setShowRequirements(false);

    const job = watch("personalInfo.job");
    const cellPhone = watch("personalInfo.cellPhone");
    const salary = watch("employerInfo.salary");
    const email = watch("accountInfo.emailAddress");
    const password = watch("accountInfo.password");
    const confirmPassword = watch("accountInfo.confirmPassword");
    const paymentSchedule = watch("agreementInfo.paymentSchedule");
    const agreedToTerms = watch("agreementInfo.agreedToTerms");
    const userLoggedIn = watch("accountInfo.userLoggedIn");
    const individual = watch("accountInfo.individual");

    const hasProvidedPhoneNumber = Boolean(cellPhone && cellPhone.length > 0);

    const submitForm = handleSubmit(saveAndNavigate);
    
    // Flag that disables form if local union hasn't been selected yet
    const disableForm = !localUnionInfo.localUnion;

    const resetLoginFields = () => {
        // Clears email, individual id, and login flag
        setValue("accountInfo.emailAddress", "");
        setValue("accountInfo.userLoggedIn", false);
        setValue("accountInfo.individual", "");
    }

    const onCancelLogIn = () => {
        setIsLoggingIn(false);
        resetLoginFields();
    }

    const onAuthenticate = ({ user }) => {
        setValue("accountInfo.userLoggedIn", true);
        setValue("accountInfo.individual", {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`
        });

        const attributesToUpdate = [ "firstName", "lastName", "nickname" ];

        // Autofills information on the form if fields are still empty
        for (const attrName of attributesToUpdate) {
            const formValue = getValues(`personalInfo.${attrName}`);
            
            if (!formValue || formValue.length === 0) {
                setValue(`personalInfo.${attrName}`, user[attrName]);
            }
        }

        setIsLoggingIn(false);
    }

    const onClickNotYou = () => {
        setIsLoggingOut(true);

        resetLoginFields();

        showNotification({ message: "You signed out. Please sign in with your correct account or create a new account.", severity: 'info', duration: 5000 });
        
        setIsLoggingOut(false);
    }

    const handleSubmitForm = () => {
        setSubmitting(true);

        trigger()
            .then((isValid) => {
                if (isValid) {
                    if (userLoggedIn) {
                        submitForm();
                    } else {
                        emailIntegrityCheck()
                            .then(() => {
                                submitForm();
                            })
                            .catch(async () => {
                                emailIsAvailable.current = false;
                                setSubmitting(false);
                                // Repaints control so the message shows below it
                                trigger(["accountInfo.emailAddress"]);
                                // Sets focus on email address field 
                                setFocus("accountInfo.emailAddress");
                                
                                showNotification({ message: "Please fix the highlighted errors and try again", severity: 'error' });
                            });
                    }
                } else {
                    setSubmitting(false);
                    showNotification({ message: "Please fix the highlighted errors and try again", severity: 'error' });
                    // Finds the first field with an error
                    const firstError = Object.values(Object.values(errors)[0])[0];

                    if (firstError && firstError.ref) {
                        // Sets focus on field with error 
                        setFocus(firstError.ref.name);
                    }
                }
            });
    };

    const emailIntegrityCheck = () => {
        const email = getValues("accountInfo.emailAddress");
        const dateOfBirth = getValues("personalInfo.dateOfBirth");
        const firstName = getValues("personalInfo.firstName");
        const lastName = getValues("personalInfo.lastName");

        return MemberService.checkIfEmailIsInUse(email, dateOfBirth, firstName, lastName);
    }

    const emailIsAvailableValidator = () => (
        emailIsAvailable.current || "You cannot use this email address, it is already in use by another account."
    )

    const autocompleteAddress = (address) => {
        if (address) {
            const { city, state, zipcode } = address;
            // Resets the address information
            setValue("personalInfo.city", city);
            setValue("personalInfo.state", state);
            setValue("personalInfo.zipCode", zipcode);
            // Sets focus on phone field (the first field after address stuff)
            setFocus("personalInfo.cellPhone", true);
        }
    }

    const handleClearCanvas = () => {
        if (signatureCanvasRef.current) {
            // Clears canvas
            signatureCanvasRef.current.clear();
            // Calls event that executes after any change to the canvas
            handleSigCanvasTouched();
        }
    }

    const handleSigCanvasTouched = (e) => {
        if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
            setIsSigned(false);
        } else {
            setIsSigned(true);
    
            const signature = signatureCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
            setValue("agreementInfo.signature", signature);
        }
    }

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        if (localUnionInfo.localUnion && job) {
            setIsLoadingPaymentSchedules(true);

            EnrollmentService.loadPaymentSchedulesByLocalAndJobId(localUnionInfo.localUnion?.id, job?.id, 1, source.token)
                .then((schedules) => {
                    if (active) {
                        setPaymentSchedules(schedules);
                        
                        if (schedules.length > 1) {
                            // Queries suggested default payment schedule
                            setIsLoadingDefaultPaymentSchedule(true);
                            
                            EnrollmentService.loadDefaultPaymentScheduleByLocalIdAndJobId(localUnionInfo.localUnion?.id, job?.id, source.token)
                            .then((defaultPaymentSchedule) => {
                                if (active) {
                                    setValue("agreementInfo.paymentSchedule", defaultPaymentSchedule);
                                }
                            })
                            .catch((error) => {
                                if (!axios.isCancel(error)) {
                                    console.log(error);
                                }
                            })
                            .finally(() => {
                                if (active) {
                                    setIsLoadingDefaultPaymentSchedule(false)
                                }
                            });
                        } else {
                            // Resets the selected payment schedule value to the only option available
                            setValue("agreementInfo.paymentSchedule", schedules[0]);
                        }
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                })
                .finally(() => {
                    if (active) {
                        setIsLoadingPaymentSchedules(false)
                    }
                });
        }
        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localUnionInfo, job])

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (localUnionInfo.localUnion && localUnionInfo.employer) {
            // Triggers loading state
            setIsLoadingOptions(true);
    
            Promise.all([
                EnrollmentService.loadWorksitesByEmployerId(localUnionInfo.employer?.id, source.token)
                    .then((worksites) => {
                        if (active) {
                            setWorksites(worksites);
                        }
                    }),
                EnrollmentService.loadEnrolleeTypesByLocalId(localUnionInfo.localUnion?.id, source.token)
                    .then((jobs) => {
                        if (active) {
                            setJobs(jobs);
                            
                            // Pre-selects job if only 1 record found
                            if (jobs.length === 1) {
                                setValue("personalInfo.job", jobs[0])
                            }
                        }
                    }),
            ])
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    showNotification({ message: error.message, severity: 'error' });
                }
            })
            .finally(() => {
                if (active) {
                    setIsLoadingOptions(false)
                }
            });
        }
        else if (localUnionInfo.localUnion) { //no employer - a valid condition
            // Triggers loading state
            setIsLoadingOptions(true);
    
            Promise.all([
                EnrollmentService.loadEnrolleeTypesByLocalId(localUnionInfo.localUnion?.id, source.token)
                    .then((jobs) => {
                        if (active) {
                            setJobs(jobs);
                            
                            // Pre-selects job if only 1 record found
                            if (jobs.length === 1) {
                                setValue("personalInfo.job", jobs[0])
                            }
                        }
                    }),
            ])
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    showNotification({ message: error.message, severity: 'error' });
                }
            })
            .finally(() => {
                if (active) {
                    setIsLoadingOptions(false)
                }
            });
        }
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localUnionInfo])

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        // Only load dues payment information if there's a job and payment schedule selected
        if (localUnionInfo.localUnion && job?.id && paymentSchedule?.id) {
            setIsLoadingDuesInfo(true);
            EnrollmentService.loadOffsetDaysByPaymentScheduleId(paymentSchedule?.id, source.token)
                .then((cutoffDays) => {
                    if (active) {
                        let effectiveDate = new Date();
                        effectiveDate.setDate(effectiveDate.getDate() + cutoffDays);
                        
                        EnrollmentService.loadDuesPaymentsInfoByLocalIdAndPaymentScheduleId(paymentSchedule?.id, job?.id, salary, effectiveDate, source.token)
                            .then((info) => {
                                if (active) {
                                    duesInfo.current = info;
                                }
                            })
                            .catch((error) => {
                                if (!axios.isCancel(error)) {
                                    console.log(error);
                                }
                            })
                            .finally(() => {
                                if (active) {
                                    setIsLoadingDuesInfo(false);
                                }
                            });
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                    if (active) {
                        setIsLoadingDuesInfo(false);
                    }
                });
        }
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [localUnionInfo, paymentSchedule, job, salary])
    
    return (
        <>
            <LocalUnionHeader localUnion={localUnionInfo.localUnion} />
            <Card variant="outlined" disabled={disableForm}>
                <CardHeader title="Personal Information" />
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="personalInfo.job"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            options={jobs}
                                            loading={isLoadingOptions}
                                            getOptionLabel={option => option.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    label="Who are you?"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {isLoadingOptions ? <CircularProgress size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="personalInfo.firstName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: true,
                                        validate: {
                                            doesNotContainDoubleQuotes
                                        }
                                    }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField 
                                            {...field}
                                            inputRef={ref}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            label="First Name"
                                            variant="outlined"
                                            required fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="personalInfo.lastName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: true,
                                        validate: {
                                            doesNotContainDoubleQuotes
                                        }
                                    }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField 
                                            {...field}
                                            inputRef={ref}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            label="Last Name"
                                            variant="outlined"
                                            required fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Controller
                                    name="personalInfo.address"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <SmartyStreetsAutocomplete
                                            {...field}
                                            label="Address"
                                            onSelect={autocompleteAddress}
                                            error={Boolean(error)}
                                            required fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} lg={2}>
                                <Controller
                                    name="personalInfo.city"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField
                                            {...field} 
                                            inputRef={ref}
                                            label="City"
                                            variant="outlined"
                                            error={Boolean(error)}
                                            required fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={2}>
                                <Controller
                                    name="personalInfo.state"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            options={states}
                                            getOptionLabel={state => state}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    label="State"
                                                    variant="outlined"
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={2} lg={2}>
                                <Controller
                                    name="personalInfo.zipCode"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <ZipInput {...field}
                                            inputProps={{
                                                label: "Zip Code",
                                                variant: "outlined",
                                                error: Boolean(error),
                                                required: true,
                                                fullWidth: true
                                            }}>
                                        </ZipInput>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Controller
                                    name="personalInfo.cellPhone"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                        <PhoneInput {...field}
                                            inputRef={ref}
                                            inputProps={{
                                                label: "Cell Phone",
                                                variant: "outlined",
                                                error: Boolean(error),
                                                fullWidth: true,
                                                required: true
                                            }}>
                                        </PhoneInput>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Controller
                                    name="personalInfo.dateOfBirth"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField
                                            {...field} 
                                            inputRef={ref}
                                            label="Date of birth"
                                            type="date"
                                            variant="outlined"
                                            error={Boolean(error)}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth required />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="personalInfo.agreedToBeTexted"
                                    control={control}
                                    defaultValue={true}
                                    rules={{ required: false }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            disabled={!hasProvidedPhoneNumber}
                                            control={<Checkbox color="primary" checked={value} onChange={onChange} required />}
                                            label="By providing my cell phone number, I understand that my local association, the Florida Education Association, the National Education Association, and the American Federation of Teachers may use automated calling techniques and/or text message me on a regular basis. These entities will never charge me for text message alerts. Carrier message and data rates may apply to such alerts."
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Card variant="outlined" disabled={disableForm} hidden={!localUnionInfo.employer}>
                <CardHeader title="Employer Information"/>
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Employer"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true, readOnly: true }}
                                    value={localUnionInfo.employer?.name}
                                    fullWidth disabled />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="employerInfo.worksite"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field} 
                                            autoHighlight openOnFocus
                                            options={worksites}
                                            disabled={!localUnionInfo.employer}
                                            loading={isLoadingOptions}
                                            getOptionLabel={option => option?.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    error={Boolean(error)} 
                                                    label="Worksite" 
                                                    variant="outlined" 
                                                    fullWidth />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="employerInfo.employeeID"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: false }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField 
                                            error={Boolean(error)} 
                                            label="Employee ID" 
                                            variant="outlined" 
                                            fullWidth {...field}/>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller 
                                    name="employerInfo.salary"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: duesInfo.current && duesInfo.current.requiresSalary }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField
                                            {...field} 
                                            inputRef={ref}
                                            label="Salary"
                                            variant="outlined"
                                            error={Boolean(error)}
                                            InputProps={{
                                                ...field.InputProps,
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputComponent: MoneyInput
                                            }}
                                            required={duesInfo.current && duesInfo.current.requiresSalary} 
                                            fullWidth />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Card variant="outlined" disabled={disableForm}>
                <CardHeader title="Additional Information"/>
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="additionalInfo.recruiterName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: false }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField 
                                            error={Boolean(error)} 
                                            label="Name of Recruiter" 
                                            variant="outlined" 
                                            fullWidth {...field}/>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Card variant="outlined" disabled={disableForm}>
                <CardHeader title="Account Information"/>
                <CardContent>
                    <Controller
                        name="accountInfo.userLoggedIn"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <input type="hidden" {...field} />
                        )}
                    />
                    <Controller
                        name="accountInfo.individual"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <input type="hidden" {...field} />
                        )}
                    />
                    {userLoggedIn ? (
                        <div className={classes.container}>
                            <PersonPin className={classes.icon} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Welcome, {individual.name}!
                            </Typography>
                            <Link underline="always" onClick={onClickNotYou}>Not you?</Link>
                        </div>
                    ) : isLoggingIn ? (
                        <div className={classes.container}>
                            <OpenInNew className={classes.icon} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Logging you in...
                            </Typography>
                        </div>
                    ) : isLoggingOut ? (
                        <div className={classes.container}>
                            <CircularProgress className={classes.icon} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                Logging out...
                            </Typography>
                        </div>
                    ) : (
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="accountInfo.emailAddress"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: !userLoggedIn,
                                            validate: !userLoggedIn && {
                                                isValidEmailAddress,
                                                emailIsAvailableValidator
                                            }
                                        }}
                                        render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
                                            <TextField
                                                {...field} 
                                                inputRef={ref}
                                                error={Boolean(error)}
                                                helperText={error && error.message}
                                                label="Personal Email Address"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: isCheckingIfAccountExists && (
                                                        <InputAdornment position="end">
                                                            <CircularProgress size={20} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => {
                                                    // Resets validation flag
                                                    emailIsAvailable.current = true;
                                                    onChange(e);
                                                }}
                                                onBlur={() => {
                                                    if (!error && email) {
                                                        setIsCheckingIfAccountExists(true);

                                                        EnrollmentService.checkIfAccountIsInUse(email)
                                                            .catch(() => {
                                                                emailIsAvailable.current = false;
                                                                setIsLoggingIn(true);
                                                            })
                                                            .finally(() => {
                                                                setIsCheckingIfAccountExists(false);
                                                            });
                                                    }
                                                }}
                                                required fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="accountInfo.password"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ 
                                            required: !userLoggedIn && "Please create a password.",
                                            validate: !userLoggedIn && { 
                                                isValidPassword
                                            }
                                        }}
                                        render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
                                            <TextField 
                                                {...field} 
                                                inputRef={ref}
                                                error={Boolean(error)}
                                                helperText={(error && error.message)} 
                                                onChange={(e) => {
                                                    // Empties confirm password field
                                                    setValue("accountInfo.confirmPassword", "");
                                                    onChange(e);
                                                }}
                                                inputProps={{
                                                    onFocus: showPasswordRequirements,
                                                    onBlur: hidePasswordRequirements,
                                                }}
                                                label="Password" 
                                                variant="outlined" 
                                                type="password" 
                                                autoComplete="new-password" 
                                                required fullWidth />
                                        )}
                                    />
                                </Grid>
                                <PasswordRequirements hide={!showRequirements} password={password} />
                                <Grid item xs={12}>
                                    <Controller
                                        name="accountInfo.confirmPassword"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ 
                                            required: !userLoggedIn && "Please confirm the password.",
                                            validate: !userLoggedIn && {
                                                matchesPreviousPassword: (value) => {
                                                    return password === value || "Passwords should match.";
                                                }
                                            }
                                        }}
                                        render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                            <TextField 
                                                {...field} 
                                                inputRef={ref}
                                                error={Boolean(error)}
                                                helperText={(error && error.message)} 
                                                disabled={!password || (Boolean(errors["accountInfo"] && Boolean(errors["accountInfo"]["password"])))}
                                                label="Confirm password" 
                                                variant="outlined" 
                                                type="password" 
                                                autoComplete="confirm-password"
                                                InputProps={{
                                                    endAdornment: confirmPassword && !error && (
                                                        <InputAdornment position="end">
                                                            <CheckCircle color="primary"/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                required fullWidth />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </CardContent>
            </Card>
            <Card variant="outlined" disabled={disableForm}>
                <CardHeader title="Membership Agreement" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item container xs={12} className="inline-form">
                            <Grid item>
                                <Typography variant="body1">I would like to sign up for payments using the following payment schedule</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={4} xg={3}>
                                <Controller
                                    name="agreementInfo.paymentSchedule"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            disableClearable
                                            size="small"
                                            disabled={!job}
                                            options={paymentSchedules}
                                            loading={isLoadingPaymentSchedules || isLoadingDefaultPaymentSchedule}
                                            getOptionLabel={option => option?.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    placeholder="Payment Schedule *"
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {isLoadingOptions || isLoadingDefaultPaymentSchedule ? <CircularProgress size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">Read the following agreement <strong>carefully</strong>:</Typography>
                        </Grid>
                        <Grid item xs={12}>                            
                            <Paper variant="outlined" style={{ padding: "1em"}}>
                                <Typography component="div" variant="body1">
                                {duesInfo?.current?.membershipAgreementFormatted ? (
                                    <>
                                    {/* Note that the SQL stored procedure that generates the formatted text does safe HTML encoding */}
                                    <div dangerouslySetInnerHTML={{ __html: duesInfo?.current?.membershipAgreementFormatted }} />
                                    </>
                                ) : 
                                (
                                    <>
                                    Please fill in "Who are you?" and select a payment schedule for the membership agreement.
                                    </>
                                )}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Payment Authorization
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant="outlined" style={{ padding: "1em" }}>
                                <Typography component="div" variant="body1">
                                {duesInfo?.current?.paymentAgreementFormatted ? (
                                    <>
                                    {/* Note that the SQL stored procedure that generates the formatted text does safe HTML encoding */}
                                    <div dangerouslySetInnerHTML={{ __html: duesInfo?.current?.paymentInfoFormatted }} />
                                    <div dangerouslySetInnerHTML={{ __html: duesInfo?.current?.paymentAgreementFormatted }} />
                                    </>
                                ) : 
                                (
                                    <>
                                    Please fill in "Who are you?" and select a payment schedule for the payment agreement.
                                    </>
                                )}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="agreementInfo.agreedToTerms"
                                control={control}
                                defaultValue={false}
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={value} onChange={onChange} required />}
                                        label="I have read and agree to the terms presented in the Membership Agreement and Payment Authorization sections above"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card variant="outlined" disabled={disableForm}>
                <CardHeader title="Signature" />
                <CardContent>
                    <ResizableSignatureCanvas 
                        ref={(ref) => { signatureCanvasRef.current = ref }} 
                        clearOnResize={false} 
                        onClear={handleClearCanvas}
                        onEnd={handleSigCanvasTouched}
                    />
                </CardContent>
                <CardActions className="space-between">
                    <ConfirmActionButton
                        variant="text"
                        cancelText="No, go back"
                        confirmText="Yes, I'm sure"
                        confirmIcon={<RotateLeft />}
                        dialogTitle="Are you sure you want to start over?"
                        onConfirm={navigateBack}
                        disabled={isSubmitting || isCheckingIfAccountExists || isLoggingIn}
                    >
                        Cancel
                    </ConfirmActionButton>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitForm}
                        disabled={!agreedToTerms || !isSigned || isLoggingIn || isCheckingIfAccountExists}
                        loading={isSubmitting}
                        startIcon={<Save />}
                    >
                        Submit
                    </LoadingButton>
                </CardActions>
            </Card>
            <Dialog open={isLoggingIn}>
                <SignInForm presetEmailAddress={email} onCancel={onCancelLogIn} onAuthenticate={onAuthenticate}/>
            </Dialog>
        </>
    )
}

export default FastTrackForm;