import { createContext, useContext, useEffect, useRef, useState } from "react";
import LocalStorageManager from "../util/LocalStorageManager";
import AuthService from "../services/AuthService";
import { refreshToken } from "../services/HttpService";

export const AuthContext = createContext();

// Provider component that wraps the app and makes auth object
// available to any child component that calls useAuth().
export default function AuthProvider({ children }) {
    const auth = useProvideAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    // Tries to load the user data from the browser storage
    const user = useRef(LocalStorageManager.getLoggedInUser());
    const [ isSignedIn, setIsSignedIn] = useState(Boolean(user.current));
    
    useEffect(() => {
        // Checks if stored token is valid
        if (user.current) {
            const tryToRefreshToken = async () => {
                console.log("Checking if user is still good...");
                
                const token = await refreshToken();
        
                if (token) {
                    setIsSignedIn(true);
                } else {
                    console.log("Refresh token expired.");
                    signout();
                }
            }

            tryToRefreshToken();
        }
    }, [])

    const updateUserData = (userData) => {
        LocalStorageManager.setLoggedInUser(userData);
        user.current = userData;
    }

    const signin = (username, password, inline = false) => {
        return AuthService
            .signIn(username, password, inline)
            .then((response) => {
                // If it's an inline sign-in then return the response data
                if (inline) {
                    return ({ 
                        token: response.token, 
                        user: response.user 
                    });
                } else {
                    // If not, then save user info 
                    LocalStorageManager.setAuthToken(response.token);
                    updateUserData(response.user);
                    setIsSignedIn(true);
                }
            });
    };

    const signout = () => {
        return AuthService
            .signOut()
            .then(() => {
                LocalStorageManager.clearLoggedInUserAndToken();
                user.current = false;
                setIsSignedIn(false);
            });
    };

    const sendAccountRecoveryEmail = (email) => {
        return AuthService
            .sendAccountRecoveryEmail(email)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
        };
        
    const recoverAccountWithLink = (guid, newPassword) => {
        return AuthService
            .recoverAccountWithLink(guid, newPassword)
            .then((response) => {
                LocalStorageManager.setAuthToken(response.token);
                updateUserData(response.user);
                setIsSignedIn(true);
            });
    };

    const updateProfile = (newUserProfile) => {
        const newUser = {
            ...user.current,
            ...newUserProfile
        }

        return AuthService
            .updateProfile(newUser)
            .then((userData) => {
                updateUserData(userData);
            });
    }

    const updatePassword = (currentPassword, newPassword) => {
        return AuthService
            .updatePassword(user.current.id, currentPassword, newPassword)
            .then(({ status }) => {
                const newUser = {
                    ...user.current,
                    status
                }
                // Modifies local user data
                updateUserData(newUser);
            })
    }

    const createPortalAccount = (guid, email, password, overridePrimaryEmailAddress, cancelToken) => {
        return AuthService
            .createPortalAccount(guid, email, password, overridePrimaryEmailAddress, cancelToken)
            .then((response) => {
                LocalStorageManager.setAuthToken(response.token);
                updateUserData(response.user);
                setIsSignedIn(true);
            });
    }

    const authenticate = (token, cancelToken) => {
        LocalStorageManager.clearLoggedInUserAndToken();
        user.current = false;
        setIsSignedIn(false);

        return AuthService
            .checkToken(token, true, cancelToken)
            .then((response) => {
                LocalStorageManager.setAuthToken(response.token);
                updateUserData(response.user);
                setIsSignedIn(true);
            });
    }

    // Returns the user object and auth methods
    return {
        authenticate,
        createPortalAccount,    
        isSignedIn,
        recoverAccountWithLink,
        sendAccountRecoveryEmail,
        signin,
        signout,
        updateProfile,
        updatePassword,
        user: user.current,
    };
}