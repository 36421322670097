import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Alert, AlertTitle, Backdrop, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { EnrollmentContext } from '../../providers/EnrollmentProvider';
import LocalUnionInformation from './LocalUnionInformation';
import AdditionalInformation from './AdditionalInformation';
import PersonalInformation from './PersonalInformation';
import EmployerInformation from './EmployerInformation';
import MembershipAgreement from './MembershipAgreement';
import SubmitAndSign from './SubmitAndSign';
import EnrollmentService from '../../services/EnrollmentService';
import LocalUnionHeader from '../../components/LocalUnionHeader';
import { debugEnrollmentInfo } from '../../resources';
import { EnrolleeTypes } from './Entities';
import { useAuth } from '../../providers/AuthProvider';
import axios from 'axios';

import "./NewMemberEnrollmentView.css";

const defaultEnrollmentInfo = {
    localUnionInfo: {},
    personalInfo: {},
    employerInfo: {},
    agreementInfo: {},
    additionalInfo: {}
}

const NavigationDirection = {
    BACKWARD: -1,
    FORWARD: +1,
}

const enrollmentSteps = [
    {
        route: "",
        component: LocalUnionInformation
    },
    {
        route: "/personalInfo",
        component: PersonalInformation
    },
    {
        route: "/employerInfo",
        component: EmployerInformation
    },
    {
        route: "/membershipAgreement",
        component: MembershipAgreement
    },
    {
        route: "/additionalInfo",
        component: AdditionalInformation
    },
    {
        route: "/sign/:envelopeId?",
        // Used when route has optional parameters
        initialRoute: "/sign",
        component: SubmitAndSign
    }
]

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        flexDirection: "column",
        gap: "1rem",
    },
    text: {
        marginLeft: "1rem",
    },
}));

function NewMemberEnrollmentView({ title, hideHeader, children, onCancel, onFinish }) {
    const classes = useStyles();
    const history = useHistory();
    const { signout } = useAuth();
    const { pathname } = useLocation();
    const { path, url } = useRouteMatch();

    // Holds the id of a pre-selected local union
    const { preSelectedLocalUnionId } = useParams();
    // Indicates that the pre-selected local union is invalid (not active or enrolled in CMS)
    const isLocalUnionInvalid = useRef(false);
    const localUnionErrorMessage = useRef();
    // Indicates that the system is loading information for a pre-selected local union
    const [isLoadingLocalUnion, setIsLoadingLocalUnion] = useState(false);
    // The enrollInfo object contains all the enrollment form information
    const [enrollmentInfo, setEnrollmentInfo] = useState(process.env.REACT_APP_ENABLE_NME_DEBUG_DATA === "true" ? debugEnrollmentInfo : defaultEnrollmentInfo);

    // Merges the enrollment info being submitted from a step
    const onChangeEnrollmentInfo = (data, callback) => {
        const newState = {
            ...enrollmentInfo,
            ...data
        };

        if (process.env.REACT_APP_ENABLE_NME_DEBUG_DATA === "true") {
            console.log(newState);
        }

        setEnrollmentInfo(newState);
        // Invokes the callback if defined
        if (callback) {
            callback(newState);
        }
    }

    // Filters out routes that have special conditions
    const navigateTo = (index, direction, newState = enrollmentInfo) => {
        // Treats invalid index cases
        if (index < 0 && direction === NavigationDirection.BACKWARD) {
            // Executes Cancel action
            onCancel();
        } else if (index >= enrollmentSteps.length && direction === NavigationDirection.FORWARD) {
            // Resets all form values to default
            setEnrollmentInfo(defaultEnrollmentInfo);

            // Executes finishing action if defined
            if (onFinish) {
                onFinish()
            } else {
                // If not, redirects user to first page
                history.push(url);
            }
        } else {
            // Skips to the next or previous step depending on navigation direction
            const skipStep = () => navigateTo(index + direction, direction);
            const step = enrollmentSteps[index];
            const route = step.initialRoute || step.route;

            switch (route) {
                case "/employerInfo":
                    // Exposes the attribute 'job' from the local union
                    const { localUnionInfo: { job } } = newState;
                    // Skips Employer Information step if selected occupation is Retiree or College Student
                    if (job && (job.id === EnrolleeTypes.RETIREE || job.id === EnrolleeTypes.COLLEGE_STUDENT)) {
                        return skipStep();
                    }
                    break;
                default:
                // Redirects user to route if none of the conditions are met
            }
            // Appends route to current base path
            history.push(url + route);
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source();

        // This is for when the local union id is set in the route
        if (preSelectedLocalUnionId) {
            // Sets loading state
            setIsLoadingLocalUnion(true);
            // Tries to load the local union by id
            EnrollmentService.loadLocalUnionById(preSelectedLocalUnionId, false, source.token)
                .then((localUnion) => {
                    // Checks if it's a valid local union
                    if (localUnion && localUnion.active && localUnion.enrolledInCMS) {
                        // Loads the employer for selected local
                        EnrollmentService.loadEmployerByLocalUnionId(localUnion.id, source.token)
                            .then(employer => {
                                // Loads the contact information for the selected local
                                EnrollmentService.loadLocalUnionContactInfo(localUnion.id, source.token)
                                    .then(({ address, phone }) => {
                                        onChangeEnrollmentInfo({
                                            localUnionInfo: {
                                                job: null,
                                                county: null,
                                                employer,
                                                localUnion: {
                                                    ...localUnion,
                                                    address,
                                                    phone,
                                                },
                                            }
                                        })
                                    })
                                    .catch(error => {
                                        if (!axios.isCancel(error)) {
                                            console.log("Failed to load local union's contact info", error)
                                            
                                            // Sets the local union without contact info
                                            onChangeEnrollmentInfo({
                                                localUnionInfo: {
                                                    job: null,
                                                    county: null,
                                                    employer,
                                                    localUnion,
                                                }
                                            })
                                        }
                                    })
                                    .then(() => {
                                        // Disables loading state
                                        setIsLoadingLocalUnion(false);
                                    })
                            })
                            .catch(error => {
                                if (!axios.isCancel(error)) {
                                    // Flags pre-selected local union as invalid
                                    isLocalUnionInvalid.current = true;
                                    localUnionErrorMessage.current = <span>Unable to find employer information for the local union <b>{localUnion?.name}</b>.</span>
                                    // Disables loading state
                                    setIsLoadingLocalUnion(false);
                                }
                            })
                    } else {
                        // Local union is either not active or not enrolled in CMS
                        // Flags pre-selected local union as invalid
                        isLocalUnionInvalid.current = true;
                        localUnionErrorMessage.current = <span>The local union <b>{localUnion?.name}</b> is not active in the state CMS system. Please contact the state and provide the local union's information and enroll in CMS.</span>
                        // Disables loading state
                        setIsLoadingLocalUnion(false);
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        // Flags pre-selected local union as invalid
                        isLocalUnionInvalid.current = true;
                        localUnionErrorMessage.current = <span>Local union not found.</span>
                        // Disables loading state
                        setIsLoadingLocalUnion(false);
                    }
                });
        }

        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [preSelectedLocalUnionId]);

    useEffect(() => {
        // Redirects to first step if localUnion or job hasn't been selected
        // This is for when the page is refreshed or the user uses an url to another step.
        if (!enrollmentInfo.localUnionInfo.localUnion || !enrollmentInfo.localUnionInfo.job) {
            // Regex to match the signing page
            const isSigningPage = new RegExp(/\/sign\/[\w|\W]+/);
            // If it's not a redirect from Docusign's website
            if (!isSigningPage.test(window.location.pathname)) {
                // Then, take the user to the first step
                history.push(url);
            }
        }
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        // Makes sure user is not logged in at the beggining of the form
        signout();
        // eslint-disable-next-line
    }, [])

    return (
        <EnrollmentContext.Provider value={enrollmentInfo}>
            {
                !hideHeader && enrollmentInfo.localUnionInfo?.localUnion && (
                    <LocalUnionHeader localUnion={enrollmentInfo.localUnionInfo.localUnion} />
                )
            }
            {   // Renders children components here (ex: side panel)
                children
            }
            {
                isLoadingLocalUnion && (
                    <Backdrop open className={classes.backdrop}>
                        <CircularProgress color="inherit" />
                        <Typography variant="h6" className={classes.text}>
                            Loading...
                        </Typography>
                    </Backdrop>
                )
            }
            {
                isLocalUnionInvalid.current ? (
                    <Alert severity="error">
                        <AlertTitle><b>Error</b></AlertTitle>
                        {localUnionErrorMessage.current}
                    </Alert>
                ) : (
                    <Switch>
                        {
                            enrollmentSteps.map(({ route, component: Component }, i) => {
                                const props = {
                                    // Just saves the form info
                                    save: onChangeEnrollmentInfo,
                                    // Saves and navigates forward
                                    saveAndNavigate: (data) => onChangeEnrollmentInfo(data, (newState) => navigateTo(i + 1, NavigationDirection.FORWARD, newState)),
                                    // Navigates backwards
                                    navigateBack: () => navigateTo(i - 1, NavigationDirection.BACKWARD),
                                    // Navigates forward
                                    navigateForward: () => navigateTo(i + 1, NavigationDirection.FORWARD),
                                }

                                return (
                                    <Route key={route} path={path + route} exact>
                                        <Component {...props} />
                                    </Route>
                                );
                            })
                        }
                    </Switch>
                )
            }
        </EnrollmentContext.Provider>
    );
}

export default NewMemberEnrollmentView;