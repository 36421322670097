import React, { useState } from "react";
import { useNotifications } from "../../../../providers/NotificationProvider";
import SignUpForm from "./Form";
import RegistrationResult from "./Result";

function SignUp() {
    
    const showNotification = useNotifications();

    const [ registrationResult, setRegistrationResult ] = useState();

    const handleSignUpComplete = (result) => {
        if (result === "error") {
            showNotification({ message: "Server error! Try again.", severity: 'error' });
        } else {
            setRegistrationResult(result);
        }
    }
    
    return (
        registrationResult ? (
            <RegistrationResult result={registrationResult} />
        ) : (
            <SignUpForm onComplete={handleSignUpComplete} />
        )
    );
}

export default SignUp;