import { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import { CheckCircle, PersonAdd } from "@mui/icons-material";
import LoadingButton from "../../../../components/LoadingButton";
import MemberService from "../../../../services/MemberService";
import AuthService from "../../../../services/AuthService";
import { useNotifications } from "../../../../providers/NotificationProvider";
import { useAuth } from "../../../../providers/AuthProvider";
import axios from "axios";
import PasswordRequirements from "../../../../components/ChangePassword/PasswordRequirements";
import { isValidEmailAddress, isValidPassword } from "../../../../util/Validators";
import Memberships from "../../../SettingsView/Memberships";
import EmailSyncDialog from "./EmailSyncDialog";

function CompleteRegistrationForm() {
    
    const { control, formState: { errors, isValid }, handleSubmit, reset, trigger, setFocus, setValue, watch, getValues } = useForm({
        mode: "onChange"
    });

    const history = useHistory();
    const { guid } = useParams();
    const { createPortalAccount, isSignedIn, signout } = useAuth();
    const showNotification = useNotifications();

    const memberships = useRef([]);
    const emailIsAvailable = useRef(true);
    const primaryEmailAddress = useRef("");

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingMemberships, setIsLoadingMemberships ] = useState(true);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isEmailDialogOpen, setIsEmailDialogOpen ] = useState(false);
    const [ isCheckingEmailIntegrity, setIsCheckingEmailIntegrity ] = useState(false);
    const [ showRequirements, setShowRequirements ] = useState(false);
    
    const emailAddress = watch("emailAddress");
    const password = watch("password");
    const confirmPassword = watch("confirmPassword");
    const overridePrimaryEmailAddress = watch("overridePrimaryEmailAddress");

    const showPasswordRequirements = () => setShowRequirements(true);
    const hidePasswordRequirements = () => setShowRequirements(false);
    
    const handleCreateAccount = ({ emailAddress, password, overridePrimaryEmailAddress }) => {
        setIsSubmitting(true);

        createPortalAccount(guid, emailAddress, password, overridePrimaryEmailAddress)
            .then(() => {
                showNotification({ message: "Member account created successfully!", severity: 'success', duration: 10000 });
            })
            .catch(() => {
                showNotification({ message: "This one-time registration link has already been used or is no longer valid. If you have already created an account, please try logging in with your email and password. If you are still having issues, please contact centralmembership@floridaea.org.", severity: 'error', duration: 60000 });
            })
            .finally(() => {
                history.replace('/signin');
            })
    }
    
    const emailIsAvailableValidator = () => (
        emailIsAvailable.current || "You cannot use this email address, it is already in use by another account."
    )

    const submitForm = handleSubmit(handleCreateAccount);

    const onClickSubmit = () => {
        setIsSubmitting(true);
        trigger()
            .then((isValid) => {
                if (isValid) {
                    submitForm();
                } else {
                    showNotification({ message: "Fix the highlighted fields and try again.", severity: 'error', duration: 10000 });
                    setIsSubmitting(false);
                }
            })
    }

    const onEmailFieldBlur = () => {
        setIsCheckingEmailIntegrity(true);
        
        const dateOfBirth = getValues('dateOfBirth');
        const firstName = getValues('firstName');
        const lastName = getValues('lastName');

        MemberService.checkIfEmailIsInUse(emailAddress, dateOfBirth, firstName, lastName)
            .then(() => {
                MemberService.checkPrimaryEmailIntegrity(guid, emailAddress)
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            if (error.response) {
                                if (error.response.status === 409) {
                                    primaryEmailAddress.current = error.response.data.primaryEmail;
                                    setIsEmailDialogOpen(true);
                                }
                            } else {
                                console.log("Failed to check email integrity.")
                            }
                        }
                    })
                    .then(() => { 
                        setIsCheckingEmailIntegrity(false);
                    });
            })
            .catch(async () => {
                emailIsAvailable.current = false;
                setIsCheckingEmailIntegrity(false);
                //force repaint of control so the message shows below the control
                await trigger(["emailAddress"]);
            })
    }

    const handleConfirmEmailOverride = ({ overridePrimaryEmailAddress }) => {
        setValue("overridePrimaryEmailAddress", overridePrimaryEmailAddress);
        setIsCheckingEmailIntegrity(false);
        setIsEmailDialogOpen(false);
        setFocus("password");
    }

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        if(active) {
            if (isSignedIn && !isSubmitting) {
                signout();
            } else {
                AuthService.loadRegistrationLink(guid, source.token)
                    .then(({ id, firstName, lastName, emailAddress }) => {
                        if (active) {
                            // Load Memberships for the user
                            setIsLoadingMemberships(true);

                            MemberService.loadMemberships(id, source.token)
                                .then((result) => {
                                    if(active) {
                                        memberships.current = result;
                                    }
                                })
                                .catch(error => {
                                    if (!axios.isCancel(error)) {
                                        console.log("Couldn't load user memberships.");
                                    }
                                })
                                .then(() => {
                                    if(active) {
                                        setIsLoadingMemberships(false);
                                    }
                                });
                            
                            reset({
                                firstName, lastName, emailAddress
                            });
                            
                            setIsLoading(false);
                        }
                    })
                    .catch(() => {
                        if (active) {
                            showNotification({ message: "This one-time registration link has already been used or is no longer valid. If you have already created an account, please try logging in with your email and password. If you are still having issues, please contact centralmembership@floridaea.org.", severity: 'error', duration: 60000 });
                            history.replace('/signin');
                        }
                    });
            }
        }
        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        //eslint-disable-next-line
    }, [isSignedIn, guid, reset])

    return (
        <>        
            <Card>
                <CardHeader title="Complete Registration" subheader="Let's get your new account set up!"/>
                {isLoading ? (
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    </CardContent>
                ) : (
                    <>
                        <CardContent>
                            <EmailSyncDialog open={isEmailDialogOpen} onConfirm={handleConfirmEmailOverride} primaryEmailAddress={primaryEmailAddress.current} newEmailAddress={emailAddress}/>
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField 
                                                    label="First Name" 
                                                    variant="outlined" 
                                                    required disabled fullWidth {...field} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField 
                                                    label="Last Name" 
                                                    variant="outlined" 
                                                    required disabled fullWidth {...field} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="overridePrimaryEmailAddress"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <input type="hidden" {...field} />
                                            )}
                                        />
                                        <Controller
                                            name="emailAddress"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ 
                                                required: true,
                                                validate: {
                                                    isValidEmailAddress,
                                                    emailIsAvailableValidator
                                                }
                                            }}
                                            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                                                <TextField 
                                                    label="Email" 
                                                    variant="outlined" 
                                                    error={Boolean(error)}
                                                    helperText={(error && error.message) || (overridePrimaryEmailAddress === "true" && "Your primary email on file will be overriden.")} 
                                                    autoComplete="signin username" 
                                                    onChange={(e) => {
                                                        // Resets validation flag
                                                        emailIsAvailable.current = true;
                                                        onChange(e);
                                                    }}
                                                    InputProps={{
                                                        onBlur: onEmailFieldBlur,
                                                        readOnly: isCheckingEmailIntegrity,
                                                        endAdornment: isCheckingEmailIntegrity && (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Checking email integrity...">
                                                                    <CircularProgress size="1.5rem" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    required fullWidth autoFocus {...field} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="password"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ 
                                                required: "Please create a password.",
                                                validate: {
                                                    isValidPassword
                                                }
                                            }}
                                            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                                                <TextField 
                                                    error={Boolean(error)}
                                                    helperText={(error && error.message)} 
                                                    onChange={(e) => {
                                                        // Empties confirm password field
                                                        setValue("confirmPassword", "");
                                                        onChange(e);
                                                    }}
                                                    inputProps={{
                                                        onFocus: showPasswordRequirements,
                                                        onBlur: hidePasswordRequirements,
                                                    }}
                                                    label="Password" 
                                                    variant="outlined" 
                                                    type="password" 
                                                    autoComplete="new-password" 
                                                    disabled={isCheckingEmailIntegrity}
                                                    required fullWidth {...field}/>
                                            )}
                                        />
                                    </Grid>
                                    <PasswordRequirements hide={!showRequirements} password={password} />
                                    <Grid item xs={12}>
                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ 
                                                required: "Please confirm the password.",
                                                validate: {
                                                    matchesPreviousPassword: (value) => {
                                                        return password === value || "Passwords should match.";
                                                    }
                                                }
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField 
                                                    error={Boolean(error)}
                                                    helperText={(error && error.message)} 
                                                    disabled={!password || Boolean(errors["password"]) || isCheckingEmailIntegrity}
                                                    label="Confirm password" 
                                                    variant="outlined" 
                                                    type="password" 
                                                    autoComplete="confirm-password"
                                                    InputProps={{
                                                        endAdornment: confirmPassword && !error && (
                                                            <InputAdornment position="end">
                                                                <CheckCircle color="primary"/>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    required fullWidth {...field}/>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                        <Memberships memberships={memberships.current} isLoading={isLoadingMemberships} paddingBottom />
                        <CardActions className="align-right">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={onClickSubmit}
                                disabled={!isValid}
                                loading={isSubmitting}
                                startIcon={<PersonAdd />}
                            >
                                Create Account
                            </LoadingButton>
                        </CardActions>
                    </>
                )}
            </Card>
        </>
    )
}

export default CompleteRegistrationForm;