import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Card, CardContent, CircularProgress, Button, Typography, Stepper, Step, StepLabel, CardHeader, Grid, Divider, StepConnector, Skeleton } from '@mui/material';
import { BorderColor, CloudDone, CloudUpload, ExitToApp, OfflinePin } from '@mui/icons-material';
import { blue, green } from '@mui/material/colors';
import { makeStyles, withStyles } from "@mui/styles";
import { useEnrollmentInfo } from '../../providers/EnrollmentProvider';
import { useNotifications } from '../../providers/NotificationProvider';
import EnrollmentService from '../../services/EnrollmentService';
import _ from 'lodash';
import clsx from 'clsx';
import axios from 'axios';
import { useAuth } from '../../providers/AuthProvider';

const useStepperIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: ".75rem",
    },
    active: {
      backgroundColor: blue[500],
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundColor: green[500],
    },
});

const StepperConnector = withStyles({
    vertical: {
        marginLeft: 24,
        padding: "8px 0",
    },
    active: {
        '& $lineVertical': {
            borderColor: '#4caf50',
        },
    },
    completed: {
        '& $lineVertical': {
            borderColor: '#4caf50',
        },
    },
    lineVertical: {
        borderColor: '#eaeaf0',
        borderWidth: 3,
    },
  })(StepConnector);

function StepIcon({ active, completed, icon, loading }) {
    const classes = useStepperIconStyles();
  
    const icons = {
        1: <CloudUpload />,
        2: <BorderColor />,
    }
  
    const completedIcons = {
        1: <CloudDone />,
        2: <OfflinePin />,
    }

    return (
        <div className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })}>
            {loading ? (
                <CircularProgress color="inherit" />
            ) : completed ? (
                completedIcons[icon]
            ) : (
                icons[icon]
            )}
        </div>
    );
}

// Prevents user from closing tab before finishing sign up process
function preventClosing(event) {
    event.preventDefault();
    return event.returnValue = '';
}

function SubmitAndSign({ navigateBack, navigateForward, save, saveAndNavigate }) {
    
    const { url } = useRouteMatch();
    const { isSignedIn } = useAuth();
    const { envelopeId } = useParams();
    
    const history = useHistory();
    const enrollmentInfo = useEnrollmentInfo();
    const showNotification = useNotifications();
    
    const [ step, setStep ] = useState(0);
    const [ signingCeremonyUrl, setSigningCeremonyUrl ] = useState();
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isRedirecting, setIsRedirecting ] = useState(false);
    const [ isEnrollmentValid, setIsEnrollmentValid ] = useState(true);
    const [ isCheckingSignature, setIsCheckingSignature ] = useState(false);
    const [ isSignatureValid, setIsSignatureValid ] = useState(false);

    useEffect(() => {
        // Prevents user leaving the page accidentally if enrollment
        // has been submitted and signed.
        if (step < 2) {
            window.addEventListener("beforeunload", preventClosing);
        } else {
            // Removes event listener when user reaches last step
            window.removeEventListener("beforeunload", preventClosing);
        }
    }, [step])

    const handleSignButton = () => {
        // Removes event listener before redirecting user to DocuSign
        window.removeEventListener("beforeunload", preventClosing);
        // Redirects user to DocuSign's website
        window.location.href = signingCeremonyUrl;
    }

    // Checks if the enrollment object is valid
    const isValidEnrollment = () => {
        // Defines the required attributes
        const attributesToValidate = ["localUnionInfo", "personalInfo", "agreementInfo", "additionalInfo"];
        
        for(let i = 0; i < attributesToValidate.length; i++) {
            // Checks if object key is a valid object
            if (_.isEmpty(enrollmentInfo[attributesToValidate[i]])) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        const source = axios.CancelToken.source();

        // Checks if there is an envelope ID set in the URL (redirected from DocuSign's page)
        if(envelopeId) {
            setIsCheckingSignature(true);
            
            EnrollmentService.checkEnrollmentSignature(envelopeId, source.token)
                .then(({ status, localUnion }) => {
                    // Updates the local union branding on client side
                    save({ localUnionInfo: { localUnion }})

                    if(status === "completed") {
                        setIsSignatureValid(true);
                        setIsCheckingSignature(false);
                    } else {
                        const callbackUrl = ((process.env.REACT_APP_BASENAME || "") + url).replace(`/${envelopeId}`, "");

                        // Recreate Ceremony URL
                        EnrollmentService.recreateSigningCeremony(envelopeId, callbackUrl, source.token)
                            .then(({ redirectUrl }) => {
                                setIsCheckingSignature(false);
                                setSigningCeremonyUrl(redirectUrl);
                            })
                            .catch(error => {
                                if (!axios.isCancel(error)) {
                                    console.error(error);
                                    showNotification({ message: error.message, severity: 'error' });
                                }
                            });
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        showNotification({ message: error.message, severity: 'error' });
                    }
                });
        }
        // eslint-disable-next-line
    }, [envelopeId])

    useEffect(() => {
        setStep(prevStep => (
            isSubmitting || !isEnrollmentValid ? (
                0 
            ) : isCheckingSignature || !isSignatureValid ? (
                1 
            ) : 2 )
        );
    }, [isSubmitting, isEnrollmentValid, isCheckingSignature, isSignatureValid])

    useEffect(() => {
        // If signature was completed and user is logged in
        if (!isCheckingSignature && isSignatureValid && isSignedIn) {
            setIsRedirecting(true);

            setTimeout(() => {
                // Redirect to Portal landing page
                history.push('/account/settings');
            }, 5000);
        }
    }, [isCheckingSignature, isSignatureValid, history, isSignedIn])

    useEffect(() => {
        const source = axios.CancelToken.source();

        // Only submits enrollmentInfo if envelopeId is not defined
        if(!envelopeId) {
            // Checks if the object is a valid enrollment object
            // This is to prevent the app from making requests with invalid data
            if(isValidEnrollment(enrollmentInfo)) {
                console.log("Submitting NME to Docusign...");
                setIsSubmitting(true);
                const callbackUrl = (process.env.REACT_APP_BASENAME || "") + url;

                // Tries to submit the enrollment data for signature
                EnrollmentService.submitEnrolllmentForSignature(enrollmentInfo, callbackUrl, source.token)
                    .then(({ envelopeId, redirectUrl }) => {
                        console.log("Envelope ID: ", envelopeId);
                        setIsEnrollmentValid(true);
                        setSigningCeremonyUrl(redirectUrl);
                        setIsSubmitting(false);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            // Logs error in browser's console
                            console.log("Failed to send document to Docusign", error);

                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                showNotification({ message: "Failed to submit enrollment, please try again", severity: 'error', duration: 20000 });

                                if (error.response.status === 400) {
                                    console.log("Error details:", error.response.data);
                                }
                            } else if (error.request) {
                                // The request was made but no response was received
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                showNotification({ message: "Unable to reach server, please try again", severity: 'error', duration: 20000 });
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                showNotification({ message: error.message, severity: 'error', duration: 20000 });
                            }
                            // Returns to previous step in case of error
                            history.goBack();
                        }
                    });
            } else {
                console.log("Invalid enrollment data!");
                setIsEnrollmentValid(false);
            }
        }
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [enrollmentInfo])

    return (
        <Card variant="outlined">
            <CardHeader title="Submit & Sign" />
            <CardContent>
                <Grid container justifyContent="space-evenly" spacing={5}>
                    <Grid item xs={12} sm={5} lg={4}>
                        <Stepper 
                            activeStep={step} 
                            orientation="vertical" 
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            connector={<StepperConnector />}
                        >
                            <Step>
                                <StepLabel StepIconComponent={StepIcon} StepIconProps={{ loading: isSubmitting }}>
                                    <Typography variant="h6">
                                        {isSubmitting ? "Submitting your enrollment..." : isEnrollmentValid ? "Enrollment Submitted" : "Failed to Submit Enrollment"}
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={StepIcon} StepIconProps={{ loading: isCheckingSignature }}>
                                    <Typography variant="h6">
                                        {isCheckingSignature ? "Checking for signature validity..." : isSignatureValid ? "Signed Successfully" : "Signature Pending"}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Divider orientation="vertical" sx={{ display: { xs: 'none', sm: 'block' } }} />
                        <Divider orientation="horizontal" sx={{ display: { xs: 'block', sm: 'none' } }} />
                    </Grid>
                    {step === 2 ? (
                        <Grid container spacing={2} direction="column" item xs={12} sm={5} lg={4} >
                            <Grid item>
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    You are all set!
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {isRedirecting ? (
                                        "You will be redirected in 5 seconds..."
                                    ) : (
                                        "You will receive a confirmation email soon."
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    color="primary"
                                    variant="outlined"
                                    onClick={navigateForward}
                                    endIcon={<ExitToApp />}
                                >
                                    Return to Homepage
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} direction="column" item xs={12} sm={5} lg={4} >
                            <Grid item>
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    {isSubmitting || isCheckingSignature ? <Skeleton /> : isEnrollmentValid ? (
                                        "Your enrollment document is ready to be signed!"
                                    ) : (
                                        "There was an error submitting your data, please try again."
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {isSubmitting || isCheckingSignature ? <Skeleton /> : isEnrollmentValid ? (
                                        "By clicking the button below you will be redirected to DocuSign's website."
                                    ) : (
                                        "If the problem persists, contact the IT support."
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {isCheckingSignature ? (
                                    <Skeleton height={70} />
                                ) : (
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        disabled={isSubmitting || !isEnrollmentValid}
                                        onClick={handleSignButton}
                                    >
                                        Click here to sign
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SubmitAndSign;