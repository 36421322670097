import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

/**
 * Provides a context for notifications to be shown on the screen.
 */

export const NotificationContext = createContext();

export const useNotifications = () => {
    return useContext(NotificationContext);
};

/**
 * The notification component (SnackBar)
 * @param onClose Notification close handler 
 * @param notification Notification configuration object 
 */
const CustomNotification = ({ onClose, notification: { open, message, vertical, horizontal, severity, duration } }) => (
    <Snackbar 
        open={open} 
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={duration} 
        onClose={onClose}
        ClickAwayListenerProps={{
            // Disables notif close on click away
            onClickAway: () => false
        }}
    >
        <Alert 
            onClose={onClose} 
            severity={severity} 
            elevation={3} 
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
)

function NotificationProvider({ children }) {
    const [ notification, setNotification ] = useState({
        open: false,
        message: 'Test!',
        vertical: 'top',
        horizontal: 'center',
        severity: 'info',
        duration: 5000,
    });

    const showNotification = (notificationData) => {
        setNotification((prevValue) => ({
            ...prevValue,
            ...notificationData,
            open: true,
        }));
    }

    const handleClose = () => {
        setNotification((prevValue) => ({
            ...prevValue,
            open: false,
        }));
    }

    return (
        <NotificationContext.Provider value={showNotification}>
            <CustomNotification notification={notification} onClose={handleClose} />
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationProvider;
