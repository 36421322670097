import _ from "lodash";

// Matches anything but double quotes
const anythingButDoubleQuotes = new RegExp(/^$|^[^"]+$/i);
// Matches any strings with an @ in between followed by a . and some text
const emailRegex = new RegExp(/^$|^\S+@\S+\.\S+$/i);
// Matches only numbers in string
const numberOnlyRegex = new RegExp(/^\d*$/);
// Regex for the password requirements
const atLeastTwelveCharactersRegex = new RegExp(/.{12,}/);
const atLeastOneLetterRegex = new RegExp(/[a-zA-Z]+/);
const atLeastOneNumberRegex = new RegExp(/\d+/);
const atLeastOneSymbolRegex = new RegExp(/[-!$%^#@&*()_+|~=`{}[\]:";'<>?,./]+/);

// Aggregate the password regex for exporting
const passwordRegex = {
    atLeastTwelveCharactersRegex,
    atLeastOneLetterRegex,
    atLeastOneNumberRegex,
    atLeastOneSymbolRegex
}

// Used for names as double quotes are apparently a problem when creating UCA's in Dwolla
const doesNotContainDoubleQuotes = (text) => (
    anythingButDoubleQuotes.test(text) || `Please do not use double quotes (").`
);

const isValidYear = (year) => {
    return (numberOnlyRegex.test(year) && year?.length === 4) 
        || "Please type a valid year (YYYY)."
};

const isValidMonth = (month) => (
    (numberOnlyRegex.test(month) && _.inRange(month, 1, 12)) 
        || "Please type a number between 01 and 12."
);

const isValidExpirationYear = (year) => (
    (parseInt(year)) >= (new Date().getFullYear())
        || "Please type a year greater than the current year."
);

const isValidEmailAddress = (email) => (
    emailRegex.test(email) || "Please type a valid email address."
);

const isValidPhoneNumber = (phone) => (
    // TODO: Create phone number regex
    numberOnlyRegex.test(phone) || "Please type a valid phone number (only numbers)."
);

const isValidPassword = (password) => (
    (atLeastTwelveCharactersRegex.test(password) && atLeastOneLetterRegex.test(password) && atLeastOneNumberRegex.test(password) && atLeastOneSymbolRegex.test(password))
        || "Password needs to match all requirements."
);

const isNumbersOnly = (numbers) => (
    numberOnlyRegex.test(numbers) || "Please type numbers only."
);

export {
    doesNotContainDoubleQuotes,
    isValidMonth,
    isValidYear,
    isValidEmailAddress,
    isValidExpirationYear,
    isValidPassword,
    isValidPhoneNumber,
    isNumbersOnly,
    passwordRegex
};
