import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string.
// REF: https://reactrouter.com/web/example/query-parameters
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => {
        let urlParams = {};
    
        for(const [key, value] of new URLSearchParams(search).entries()) {
            urlParams = {
                ...urlParams,
                [key]: value,
            }
        }
    
        return urlParams;
    }, [search])
}

export default useQuery;