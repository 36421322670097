import { Link } from "@mui/material";

function FEAPrivacyPolicyLink({ children, underline }) {
    return (
        <Link 
            underline={underline || "always"}
            target="_blank"
            rel="noreferrer"
            href="https://feaweb.org/privacy-policy/"
        >
            {children || "Privacy Policy"}
        </Link>
    )
}

export default FEAPrivacyPolicyLink;