import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Info, Save } from "@mui/icons-material";
import { Card, CardActions, CardContent, CardHeader, Button, Grid, FormLabel, Paper, TextField, Alert, AlertTitle, Autocomplete, Typography, Skeleton } from '@mui/material';
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import ConfirmActionButton from "../../components/ConfirmActionButton";
import { YearInput } from "../../components/FormattedInputs";
import { isValidYear } from "../../util/Validators";
import EnrollmentService from "../../services/EnrollmentService";
import axios from "axios";

function AdditionalInformation({ navigateBack, navigateForward, save, saveAndNavigate }) {
    const { localUnionInfo: { localUnion }, additionalInfo } = useEnrollmentInfo();
    const { control, formState: { isValid, isSubmitting, isValidating }, handleSubmit, reset, trigger } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });

    const [ isLoading, setIsLoading ] = useState(false);
    const [ additionalOptions, setAdditionalOptions ] = useState();
    const submitForm = handleSubmit(save);
    
    const handleClickSubmitAndSign = (openDialog) => trigger()
        .then(() => {
            if (isValid) {
                submitForm()
                    .then(openDialog);
            }
        }
    );

    const loadAdditionalOptions = (cancelToken) => {
        setIsLoading(true);

        return EnrollmentService.loadAdditionalNMEOptionsByLocalUnionId(localUnion.id, cancelToken)
            .then(setAdditionalOptions)
            .then(() => setIsLoading(false))
    }

    useEffect(() => {
        if (additionalInfo && (additionalInfo.recruiterName || additionalInfo.yearEnteredProfession || additionalInfo.comments)) {
            reset({ additionalInfo })
        }
        // eslint-disable-next-line
    }, [reset, additionalInfo])

    useEffect(() => {
        const source = axios.CancelToken.source();

        loadAdditionalOptions(source.token);
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Card variant="outlined">
            <CardHeader title="Additional Information" />
            <CardContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant="outlined" className={isLoading || !additionalOptions || additionalOptions.length === 0 ? "disabled" : null}>
                                <CardHeader subheader="Options for this Local Union" />
                                {isLoading ? (
                                    <CardContent style={{ paddingTop: "0", paddingBottom: "8px" }}>
                                        <Skeleton width="25%" height={20} />
                                        <Skeleton width="50%" height={50} />
                                    </CardContent>
                                ) : additionalOptions && additionalOptions.length > 0 ? (
                                    <CardContent style={{ paddingTop: "0"}}>
                                        <Grid container spacing={2}>
                                            {additionalOptions.map(({ listId, label, dataLabel, options }) => (
                                                <Grid key={listId} item xs={12}>
                                                    <Controller
                                                        name={`additionalInfo.options.${dataLabel}.listId`}
                                                        control={control}
                                                        defaultValue={listId}
                                                        render={({ field }) => (
                                                            <input type="hidden" {...field} />
                                                        )}
                                                    />
                                                    <Controller
                                                        name={`additionalInfo.options.${dataLabel}.selectedOption`}
                                                        control={control}
                                                        defaultValue={null}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <Autocomplete
                                                                {...field} 
                                                                autoSelect openOnFocus
                                                                options={options}
                                                                getOptionLabel={option => option?.name}
                                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                                renderInput={(params) => (
                                                                    <TextField 
                                                                        {...params} 
                                                                        error={Boolean(error)} 
                                                                        label={label} 
                                                                        variant="outlined" 
                                                                        fullWidth />
                                                                )}
                                                                onChange={(_, data) => field.onChange(data)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                ) : (
                                    <CardContent style={{ paddingTop: "0", paddingBottom: "8px", display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                        <Info />
                                        <Typography variant="subtitle2">
                                            No additional options available.
                                        </Typography>
                                    </CardContent>
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant="outlined" style={{ padding: "1rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormLabel component="legend">Miscellaneous</FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="additionalInfo.recruiterName"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    label="Name of Recruiter"
                                                    variant="outlined"
                                                    error={Boolean(error)}
                                                    fullWidth {...field} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="additionalInfo.yearEnteredProfession"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ 
                                                validate: {
                                                    validateYear: (maskedYear) => {
                                                        // Clears the placeholder mask (____) 
                                                        const yearNumber = maskedYear.replaceAll("_", "")
                                                        // If there are any numbers, validate the string, if not, return true (empty is considered valid)
                                                        return yearNumber && yearNumber.length > 0 ? isValidYear(yearNumber) : true
                                                    }
                                                }
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <YearInput {...field}
                                                    inputProps={{
                                                        label: "Year entered the profession",
                                                        helperText: (error && error.message) || "Ex: 2020 (numbers only)",
                                                        variant: "outlined",
                                                        error: Boolean(error),
                                                        fullWidth: true
                                                    }}>
                                                </YearInput>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="additionalInfo.comments"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField
                                                    minRows={4}
                                                    label="Comments"
                                                    variant="outlined"
                                                    helperText="Please share any comments about why you joined your union."
                                                    multiline fullWidth {...field} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <CardActions className="align-right">
                <Button onClick={navigateBack}>Back</Button>
                <ConfirmActionButton
                    cancelText="Cancel"
                    confirmText="Submit"
                    confirmIcon={<Save />}
                    dialogTitle="Confirm enrollment submission"
                    dialogContent={
                        <Alert severity="warning" variant="outlined">
                            <AlertTitle><b>Please read carefully</b></AlertTitle>
                            By clicking the "Submit" button below you agree that you provided truthful information to the best of your ability and that in order to change this information you will need to contact your local union.
                        </Alert>
                    }
                    onDialogWillOpen={handleClickSubmitAndSign}
                    onConfirm={navigateForward}
                    disabled={isLoading}
                    loading={isSubmitting || isValidating}
                >
                    Submit &amp; Sign
                </ConfirmActionButton>
            </CardActions>
        </Card>
    )
}

export default AdditionalInformation;