import HttpService from "./HttpService";

const DwollaService = {
    createCustomer: (firstName, lastName, email, cancelToken) => {
        return HttpService.post(
            `/api/dwolla/customers`, 
            {
                firstName,
                lastName,
                email,
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    loadCustomer: (customerId, cancelToken) => {
        return HttpService.get(
            `/api/dwolla/customers/${customerId}`,
            {
                cancelToken 
            })
            .then(response => response.data)
    },
    loadCustomerByNmeId: (nmeId, cancelToken) => {
        return HttpService.get(
            `/api/dwolla/customers?nmeId=${nmeId}`,
            { 
                cancelToken
            })
            .then(response => response.data)
    },
    loadCustomerByMemberId: (memberId, cancelToken) => {
        return HttpService.get(
            `/api/dwolla/customers?memberId=${memberId}`,
            { 
                cancelToken
            })
            .then(response => response.data)
    },
    loadCustomerFundingSources: (customerId, cancelToken) => {
        return HttpService.get(
            `/api/dwolla/customers/${customerId}/funding-sources`, 
            { 
                cancelToken
            })
            .then(response => response.data)
    },
    createAndSetFundingSourceWithPlaidPublicToken: (customerId, publicToken, accountId, accountName, cancelToken) => {
        return HttpService.patch(
            `/api/dwolla/customers/${customerId}/funding-source`,
            {
                publicToken, 
                accountId, 
                accountName
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    createIAVToken: (customerId, cancelToken) => {
        return HttpService.post(
            `/api/dwolla/customers/${customerId}/iav-token`, 
            null,
            {
                cancelToken
            })
            .then(response => response.data)
    },
    createPlaidLinkToken: (customerId, cancelToken) => {
        return HttpService.post(
            `/api/dwolla/customers/${customerId}/plaid-token`, 
            null,
            {
                cancelToken
            })
            .then(response => response.data)
    },
    savePlaidAccountVerificationInfo: (customerId, publicToken, id, name, status, cancelToken) => {
        return HttpService.patch(
            `/api/dwolla/customers/${customerId}/plaid-token`,
            {
                publicToken,
                account: {
                    id,
                    name,
                    status
                }
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    removePlaidAccountVerificationInfo: (customerId, cancelToken) => {
        return HttpService.delete(
            `/api/dwolla/customers/${customerId}/plaid-token`, 
            {
                cancelToken
            })
            .then(response => response.data)
    }
}

export default DwollaService;