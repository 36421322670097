import HttpService from "./HttpService";

const MemberService = {
    loadMemberships: (userId, cancelToken) => {
        return HttpService.get(
            `/api/portal/users/${userId}/memberships`, 
            { 
                cancelToken
            })
            .then(response => response.data)
    },
    getMemberACHStatus: (userId, cancelToken) => {
        return HttpService.get(
            `/api/portal/users/${userId}/ach`,
            {
                cancelToken
            })
            .then(response => response.data);
    },
    createMemberACH: (userId, cancelToken) => {
        return HttpService.post(
            `/api/portal/users/${userId}/ach`,
            null,
            { 
                cancelToken
            })
            .then(response => response.data);
    },
    checkIfEmailIsInUse: (email, dateOfBirth, firstName, lastName, cancelToken) => {
        return HttpService.post(
            "/api/portal/members/find",
            {
                member: {
                    email,
                    dateOfBirth,
                    firstName,
                    lastName
                }
            },
            {
                cancelToken
            }
        ).then(response => response.data);
    },
    checkPrimaryEmailIntegrity: (guid, email, cancelToken) => {
        return HttpService.post(
            `/api/portal/registration/${guid}/checkEmail`,
            {
                email
            },
            {
                cancelToken
            }
        ).then(response => response.data);
    },
    getMostRecentNewMemberEnrollmentInfo: (userId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/15122",
            {
                "parameters": [
                    {
                        "name": "EntityID",
                        "value": userId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "EntityTypeID",
                        "value": 3,
                        "dataType": "int",
                        "direction": "in"
                    },
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(result => result[0])
            .then(({ EntityID, EntityTypeID, PaymentAgreementText }) => (
                {
                    entityId: EntityID,
                    entityTypeId: EntityTypeID,
                    paymentAgreementText: PaymentAgreementText
                }
            ))
    },    
    sendRegistrationEmailIfMemberExists: (firstName, lastName, dateOfBirth, cancelToken) => {
        return HttpService.post(
            "/api/portal/registration",
            {
                user: {
                    firstName,
                    lastName,
                    dateOfBirth
                }
            },
            {
                cancelToken
            }
        ).then(response => response.data);
    },
}

export default MemberService;