import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, Card, CardActions, CardContent, CardHeader, Checkbox, Button, Paper, Grid, Typography, FormControlLabel, TextField } from '@mui/material';
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import LoadingButton from "../../components/LoadingButton";
import EnrollmentService from "../../services/EnrollmentService";
import axios from "axios";

function MembershipAgreement({ navigateBack, navigateForward, save, saveAndNavigate }) {
    
    const { localUnionInfo: { job, localUnion }, agreementInfo } = useEnrollmentInfo();
    const { control, formState: { isValid, isSubmitting, isValidating }, handleSubmit, reset, setValue, trigger, watch } = useForm({
        mode: "onChange"
    });

    const duesInfo = useRef();

    const [ paymentSchedules, setPaymentSchedules ] = useState([]);
    const [ isLoadingDuesInfo, setIsLoadingDuesInfo ] = useState(false);
    const [ isLoadingPaymentSchedules, setIsLoadingPaymentSchedules ] = useState(false);
    const [ isLoadingDefaultPaymentSchedule, setIsLoadingDefaultPaymentSchedule ] = useState(false);

    const agreedToTerms = watch('agreementInfo.agreedToTerms');
    const paymentSchedule = watch('agreementInfo.paymentSchedule');

    const submitForm = handleSubmit(saveAndNavigate);

    const next = () => trigger()
        .then(() => {
            if (isValid) {
                submitForm();
            }
        }
    );

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        if (localUnion && job) {
            setIsLoadingPaymentSchedules(true);

            EnrollmentService.loadPaymentSchedulesByLocalAndJobId(localUnion?.id, job?.id, 1, source.token)
                .then((schedules) => {
                    if (active) {
                        setPaymentSchedules(schedules);

                        if (schedules.length > 0) {
                            if (schedules.length == 1) {
                                // Resets the selected payment schedule value to the only option available
                                setValue("agreementInfo.paymentSchedule", schedules[0]);
                            }
                            // Queries suggested default payment schedule
                            setIsLoadingDefaultPaymentSchedule(true);
                            // Even if there is only one payment schedule available, we need to load the defaults because that's where we identify if the membership type is continuous
                            EnrollmentService.loadDefaultPaymentScheduleByLocalIdAndJobId(localUnion?.id, job?.id, source.token)
                            .then((defaultPaymentSchedule) => {
                                if (active) {
                                    if (schedules.length > 1)
                                    {
                                        setValue("agreementInfo.paymentSchedule", defaultPaymentSchedule);
                                    }
                                }
                            })
                            .catch((error) => {
                                if (!axios.isCancel(error)) {
                                    console.log(error);
                                }
                            })
                            .finally(() => {
                                if (active) {
                                    setIsLoadingDefaultPaymentSchedule(false)
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                })
                .finally(() => {
                    if (active) {
                        setIsLoadingPaymentSchedules(false)
                    }
                });
        }
        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localUnion, job])

    
    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        // Only load dues payment information if there's a job and payment schedule selected
        if (localUnion && job?.id && paymentSchedule?.id) {
            setIsLoadingDuesInfo(true);

            EnrollmentService.loadOffsetDaysByPaymentScheduleId(paymentSchedule?.id, source.token)
                .then((cutoffDays) => {
                    if (active) {
                        let effectiveDate = new Date();
                        effectiveDate.setDate(effectiveDate.getDate() + cutoffDays);
                        
                        EnrollmentService.loadDuesPaymentsInfoByLocalIdAndPaymentScheduleId(paymentSchedule?.id, job?.id, 0, effectiveDate, source.token)
                            .then((info) => {
                                if (active) {
                                    duesInfo.current = info;
                                    // Sets payment method in a hidden field (used to fill the Docusign document)
                                    setValue("agreementInfo.paymentMethod.name", info.paymentMethod.trim())
                                    // Sets the agreement text for signature
                                    setValue("agreementInfo.membershipAgreementText", info.membershipAgreementText.trim())
                                    setValue("agreementInfo.membershipAgreementFormatted", info.membershipAgreementFormatted.trim())
                                    setValue("agreementInfo.paymentAgreementText", info.paymentAgreementText.trim())
                                    setValue("agreementInfo.paymentAgreementFormatted", info.paymentAgreementFormatted.trim())
                                    setValue("agreementInfo.paymentInfoText", info.paymentInfoText.trim())
                                    setValue("agreementInfo.paymentInfoFormatted", info.paymentInfoFormatted.trim())
                                }
                            })
                            .catch((error) => {
                                if (!axios.isCancel(error)) {
                                    console.log(error);
                                }
                            })
                            .finally(() => {
                                if (active) {
                                    setIsLoadingDuesInfo(false);
                                }
                            });
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                    if (active) {
                        setIsLoadingDuesInfo(false);
                    }
                });
        }
        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localUnion, paymentSchedule, job])

    useEffect(() => {
        // Tests if the object is not an empty object
        if (agreementInfo && agreementInfo.paymentSchedule) {
            reset({ agreementInfo });
        }
    }, [reset, agreementInfo, job])

    return (
        <Card variant="outlined">
            <CardHeader title="Membership Agreement" />
            <CardContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item container xs={12} className="inline-form">
                            <Grid item>
                                <Typography variant="body1">I would like to sign up for payments using the following payment schedule</Typography>
                            </Grid>
                            <Grid item xs={10} sm={5} lg={4} xg={3}>
                                <Controller
                                    name="agreementInfo.paymentMethod.name"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input type="hidden" {...field} />
                                    )}
                                />
                                <Controller
                                    name="agreementInfo.paymentSchedule"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoHighlight openOnFocus
                                            disableClearable
                                            size="small"
                                            options={paymentSchedules}
                                            loading={isLoadingPaymentSchedules || isLoadingDefaultPaymentSchedule}
                                            getOptionLabel={option => option?.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    placeholder="Payment Method"
                                                    variant="standard"
                                                    required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">Read the following agreement <strong>carefully</strong>:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant="outlined" style={{ padding: "1em"}}>
                                <Typography component="div" variant="body1">
                                    {duesInfo?.current?.paymentAgreementFormatted ? (
                                        <>
                                        {/* Note that the SQL stored procedure that generates the formatted text does safe HTML encoding */}
                                        <div dangerouslySetInnerHTML={{ __html: duesInfo?.current?.membershipAgreementFormatted }} />
                                        </>
                                    ) : 
                                    (
                                        <>
                                        Please fill in "Who are you?" and select a payment schedule for the payment agreement.
                                        </>
                                    )}
                                </Typography>
                            </Paper>
                        </Grid>
                        {
                            duesInfo.current && duesInfo.current.paymentMethod?.trim() === "Bank ACH" ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Payment Authorization
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper variant="outlined" style={{ padding: "1em" }}>
                                        <Typography component="div" variant="body1">
                                            {duesInfo?.current?.paymentAgreementFormatted ? (
                                            <>
                                            {/* Note that the SQL stored procedure that generates the formatted text does safe HTML encoding */}
                                            <div dangerouslySetInnerHTML={{ __html: duesInfo?.current?.paymentAgreementFormatted }} />
                                            </>
                                            ) : 
                                            (
                                                <>
                                                Please fill in "Who are you?" and select a payment schedule for the payment agreement.
                                                </>
                                            )}                                            
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </>
                            ) : null
                        }
                        <Grid item xs={12}>
                            <Controller
                                name="agreementInfo.agreedToTerms"
                                control={control}
                                defaultValue={false}
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={value} onChange={onChange} disabled={!paymentSchedule} required />}
                                        label={duesInfo.current && duesInfo.current.paymentMethod?.trim() === "Bank ACH" ? (
                                            "I have read and agree to the terms presented in the Membership Agreement and Payment Authorization sections above"
                                        ) : (
                                            "I have read and agree to the terms presented above"
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <CardActions className="align-right">
                <Button onClick={navigateBack}>Back</Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={next}
                    disabled={!agreedToTerms || isLoadingDuesInfo || isLoadingDefaultPaymentSchedule}
                    loading={isSubmitting || isValidating}
                >
                    Next
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default MembershipAgreement;