import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Alert, AlertTitle, Box, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { useAuth } from '../../providers/AuthProvider';
import LoadingButton from "../../components/LoadingButton";
import ForgotPasswordDialog from "./ForgotPasswordDialog";

function SignInForm({ presetEmailAddress, onCancel, onAuthenticate }) {
    
    const { control, handleSubmit, setValue, watch } = useForm();
    const { signin, isSignedIn } = useAuth();

    const history = useHistory();
    const location = useLocation();
    
    const attempts = useRef(0);
    const errorMessage = useRef("");
    
    const [isSigningIn, setIsSigningIn] = useState(false);
    
    const { path, url } = useRouteMatch();

    const username = watch("email");

    const { from } = location.state || { from: { pathname: "/home" } };

    const handleLogin = ({ email, password }) => {
        // Increment login attempt counter
        attempts.current = attempts.current + 1;

        // Triggers loading state
        setIsSigningIn(true);

        if (attempts.current > 5) {
            errorMessage.current = "Too many login attempts!";
            setIsSigningIn(false);
        } else {
            console.log("Logging user in...");
            // Does login routine
            signin(email, password, Boolean(presetEmailAddress))
                .then((response) => {
                    if (presetEmailAddress && onAuthenticate) {
                        onAuthenticate(response);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage.current = "Invalid email and/or password";
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        errorMessage.current = "Unable to reach server, please try again";
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        errorMessage.current = error.message;
                    }
                    setIsSigningIn(false);
                });
        }
    }

    const handleOpenForgotPassword = () => {
        history.push(`${url}/forgotPassword`);
    }
    
    const handleCloseForgotPassword = () => {
        // Clears out the error message
        errorMessage.current = "";
        // Returns to login screen
        history.push(url);
    }

    useEffect(() => {
        if (presetEmailAddress) {
            setValue("email", presetEmailAddress);
        }
        // eslint-disable-next-line
    }, [presetEmailAddress])
    
    useEffect(() => {
        // Checks if user is logged in already. 
        // If so, redirects him/her to /home.
        if(isSignedIn && !presetEmailAddress) {
            history.replace(from);
        }
        // eslint-disable-next-line
    }, [isSignedIn]);

    return (
        <Paper className="sign-in">
            <Box className="login-form">
                <Typography variant="h5" gutterBottom={!presetEmailAddress}>
                    Sign in to your account
                </Typography>
                {presetEmailAddress && (
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        It looks like you already have an account, please log in.
                    </Typography>
                )}
                <form onSubmit={handleSubmit(handleLogin)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        autoComplete="signin username"
                                        disabled={Boolean(presetEmailAddress)}
                                        required fullWidth autoFocus {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField label="Password" variant="outlined" type="password" autoComplete="signin current-password" required fullWidth {...field} />
                                )}
                            />
                        </Grid>
                        {errorMessage.current.length > 0 && !isSigningIn && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>{errorMessage.current}</AlertTitle>
                                    If you are having trouble signing in, please try resetting your password using the "I forgot my password" link below. If you continue to have trouble, please <b>contact your local union office</b>. 
                                    <br/>
                                    You can find the most recent contact information by <Link underline="always" href="https://feaweb.org/about-fea/local-unions/" target="_blank">clicking here</Link> and finding your local.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="contained"
                                loading={isSigningIn}
                                disabled={attempts.current > 5}
                            >
                                Sign In
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} className="space-between">
                            {onCancel && (
                                <Link underline="hover" onClick={onCancel}>Cancel</Link>
                            )}
                            <Link underline="hover" onClick={handleOpenForgotPassword}>I forgot my password</Link>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Route exact path={`${path}/forgotPassword`}>
                <ForgotPasswordDialog username={username} onClose={handleCloseForgotPassword} />
            </Route>
        </Paper>
    );
}

export default SignInForm;