import React from "react";
import LocalContactInfo from "../LocalContactInfo";
import LocalLogo from "../LocalLogo";

function LocalUnionHeader({ pageTitle, localUnion }) {
    
    const title = pageTitle || "Local Union/Chapter Contact";

    return localUnion ? (
        <header className="app-header">
            <LocalLogo localUnionId={localUnion.id}/>
            <aside>
                <h2>{title}</h2>
                <h4>{localUnion.name}</h4>
                <LocalContactInfo localUnionId={localUnion.id} />
            </aside>
        </header>
    ) : null
}

export default LocalUnionHeader;