import React, { useState } from "react";
import { Button, Dialog, Tooltip } from "@mui/material";
import ChangePasswordForm from "./Form";
import BlurredBackdrop from "../BlurredBackdrop";

function ChangePasswordDialog({ children, tooltip, ...buttonProps }) {

    const [ open, setOpen ] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const onPasswordChanged = () => {
        // Delays on success call to show closing effect
        setTimeout(() => {
            handleClose();
        }, 200);
    }

    return (
        <React.Fragment>
            <Tooltip title={tooltip || "Change your account password"} placement="bottom" arrow>
                <Button
                    onClick={() => setOpen(true)}
                    {...buttonProps}
                >
                    {children || "Change Password"}
                </Button>
            </Tooltip>
            <Dialog 
                open={open}
                BackdropComponent={BlurredBackdrop}
                onClose={handleClose} 
                scroll="body"
                maxWidth="xs"
                fullWidth
                >
                <ChangePasswordForm onCancel={handleClose} onSuccess={onPasswordChanged} subheader="Let's create a new password" />
            </Dialog>
        </React.Fragment>
    )
}

export default ChangePasswordDialog;