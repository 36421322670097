import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Link, Slide, Typography } from "@mui/material";
import { Close, OpenInNew } from "@mui/icons-material";
import axios from "axios";
import { useMembership } from "../../views/SettingsView/Memberships";
import EnrollmentService from "../../services/EnrollmentService";
import { useRef } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function MembershipDetails() {

    const { membership, localUnion } = useMembership();

    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const membershipInfo = useRef();
    
    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (open && membership && membership?.id) {
            setLoading(true);

            EnrollmentService.loadDuesPaymentsInfoByMembershipId(membership.id, source.token)
                .then(({ paymentMethod, amount, dates }) => {
                    if (active) {
                        membershipInfo.current = {
                            paymentMethod: paymentMethod,
                            paymentAmount: amount,
                            scheduledPayments: dates
                        };
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                })
                .finally(() => {
                    if (active) {
                        setLoading(false);
                    }
                });
        }
        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [ membership, open ])

    return (
        <>
            <Link onClick={handleClickOpen}>
                <OpenInNew fontSize="inherit" /> Payment Details
            </Link>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                maxWidth='xs'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Payment Details
                    {!loading && (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent id="alert-dialog-description">
                    {loading ? (
                        <Box sx={{ height: "20px" }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={'auto'}>
                                <Typography variant="body1" fontWeight="bold">
                                    Local Chapter
                                </Typography>
                                <Typography variant="overline" color="GrayText">
                                    {localUnion?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Typography variant="body1" fontWeight="bold">
                                    Status
                                </Typography>
                                <Typography variant="overline" color="GrayText">
                                    {membership?.status}
                                </Typography>
                            </Grid>
                            {membershipInfo.current ? (
                                <>
                                    <Grid item xs={'auto'}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Payment Method
                                        </Typography>
                                        <Typography variant="overline" color="GrayText">
                                            {membershipInfo.current.paymentMethod}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Payment Amount
                                        </Typography>
                                        <Typography variant="overline" color="GrayText">
                                            ${Number(membershipInfo.current.paymentAmount).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" fontWeight="bold">
                                            {membershipInfo.current.scheduledPayments === '' ? '' : 'Scheduled Payment Dates'}
                                        </Typography>
                                        <Typography variant="overline" color="GrayText">
                                            {membershipInfo.current.scheduledPayments}
                                        </Typography>
                                    </Grid> 
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>Unable to load payment information for this membership</AlertTitle>
                                        For further assistance please send an email to <strong><a target="_blank" rel="noreferrer" href="mailto:centralmembership@floridaea.org" style={{ textDecoration: "none", color: "inherit" }}>centralmembership@floridaea.org</a></strong>.
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MembershipDetails;