import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        flexDirection: "column",
        gap: "1rem",
    },
    text: {
        marginLeft: "1rem",
    },
}));

function SignOut() {
    const { signout } = useAuth();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Logs user out and redirects browser to home page after 1 second.
        signout()
            .then(() => {
                history.push('/');
            });
    });

    return (
        <Backdrop open className={classes.backdrop}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" className={classes.text}>
                Signing out...
            </Typography>
        </Backdrop>
    )
}

export default SignOut;