import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { VpnKeyTwoTone } from "@mui/icons-material";
import { useAuth } from "../../../providers/AuthProvider";
import ChangePasswordDialog from "../../../components/ChangePassword/Dialog";
import ChangePicture from "../ChangePicture";

import "./Profile.css";

function ProfileForm() {
    const { user } = useAuth();

    return (
        <Card>
            <CardHeader title="Profile" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="profile">
                        <ChangePicture/>
                        <div className="profile-name">
                            <Typography variant="h5">
                                {`${user.firstName} ${user.lastName}`}
                            </Typography>
                            {user.address && (
                                <Typography variant="body1" color="textSecondary">
                                    {`${user.address.city}, ${user.address.state}`}
                                </Typography>
                            )}
                            <ChangePasswordDialog 
                                variant="outlined" 
                                color="primary" 
                                size="small"
                                startIcon={<VpnKeyTwoTone />} />
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ProfileForm;