import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import CompleteRegistrationForm from "./CompleteRegistrationForm";
import SignUp from "./SignUp";

function RegistrationForm() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:guid`}>
                <Grid container spacing={2} alignItems="center" direction="column">
                    <Grid item xs={8}>
                        <CompleteRegistrationForm />
                    </Grid>
                </Grid>
            </Route>
            <Route path={`${path}`} exact component={SignUp}/>
        </Switch>
    )
}

export default RegistrationForm;