import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import LoadingButton from './LoadingButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmActionButton({ children, cancelText, confirmText, confirmIcon, color, dialogTitle, dialogContent, dialogMaxWidth, disabled, endIcon, loading, onCancel, onConfirm, onDialogWillOpen, startIcon, variant }) {
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        if(onDialogWillOpen) {
            // Passes setOpen function as a parameter to onDialogWillOpen
            // This way the function can make validations before opening the confirmation dialog.
            onDialogWillOpen(() => setOpen(true));
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);

        if(onCancel) {
            onCancel();
        }
    };

    const handleConfirm = () => {
        setOpen(false);
        // Delays confirm action to show dialog's closing animation
        setTimeout(() => {
            onConfirm();
        }, 200)
    };

    return (
        <React.Fragment>
            <LoadingButton
                variant={variant || "contained"} 
                color={color || "primary"} 
                onClick={handleClickOpen}
                loading={loading}
                disabled={disabled}
                startIcon={startIcon}
                endIcon={endIcon}
            >
                {children}
            </LoadingButton>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                maxWidth={dialogMaxWidth || 'xs'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        {dialogContent}
                    </DialogContent>
                <DialogActions className="space-between">
                    <Button onClick={handleClose}>
                        {cancelText}
                    </Button>
                    <Button 
                        autoFocus
                        color="primary" 
                        variant="contained" 
                        endIcon={confirmIcon}
                        onClick={handleConfirm} 
                    >
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ConfirmActionButton;