import { Redirect, Route } from "react-router";
import { AuthContext } from "../providers/AuthProvider";

// A wrapper for <Route> that redirects to the login
// screen if user is not yet authenticated.
function PrivateRoute({children, ...rest}) {
    return (
        <AuthContext.Consumer>
            {({ isSignedIn }) => (
                <Route {...rest} render={({ location }) => (
                    // Renders children components if and only if user is signed in
                    isSignedIn ? (
                        children
                    ) : (
                        <Redirect to={{
                            pathname: '/signin',
                            state: { from: location }
                        }}/>
                    )
                )}/>
            )}
        </AuthContext.Consumer>
    );
}

export default PrivateRoute;