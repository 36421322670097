import axios from "axios";
import ExpiryMap from "expiry-map";
import pMemoize from "p-memoize";
import LocalStorageManager from "../util/LocalStorageManager";

const baseURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? (
    // Development will always refer to the .env file (REACT_APP_API_URI)
    process.env.REACT_APP_API_URI
) : (
    // Production always uses API in the same origin
    window.location.origin.toString()
)

// Main Axios instance used to make API calls
const HttpService = axios.create({
    baseURL,
    maxRedirects: 0,
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest"
    },
});

const memoizeOptions = {
    // maxAge: 10000
    cache: new ExpiryMap(10000)
}

const getAppToken = pMemoize(async () => {
    try {
        const response = await axios.post(
            `${baseURL}/api/login/app`,
            {
                secret: process.env.REACT_APP_API_SECRET
            }
        );
        
        const { token } = response.data;
    
        LocalStorageManager.setAuthToken(token);
        // Ensures previous user data is cleared
        LocalStorageManager.clearLoggedInUser();

        return token;
    } catch (error) {
        console.log("Failed to get app token.");
        LocalStorageManager.clearLoggedInUserAndToken();
    }
}, memoizeOptions);

export const refreshToken = pMemoize(async () => {
    try {
        console.log("Refreshing auth token");

        const response = await axios.post(
            `${baseURL}/api/login/refresh`
        );
    
        const { token } = response.data;

        LocalStorageManager.setAuthToken(token);

        return token;
    } catch (error) {
        console.log("Failed to refresh token.");
        LocalStorageManager.clearLoggedInUserAndToken();
    }
}, memoizeOptions);

// Request interceptor
HttpService.interceptors.request.use(
    async (config) => {
        try {
            let accessToken = LocalStorageManager.getAuthToken();
            
            if (accessToken) {        
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            } else {
                const token = await getAppToken();

                if (token) {
                    console.log(`Adding new token '${token}' to Authorization header`);
        
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${token}`,
                    };
                }
            }
    
            return config;
        } catch(error) {
            Promise.reject(error)
        }
    },
    (error) => Promise.reject(error)
);

// Response interceptor
HttpService.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (!axios.isCancel(error)) {
            const config = error?.config;
            const isUnauthorized =  error?.response?.status === 401 || (process.env.NODE_ENV === 'development' && !error.response);

            if ( 
                !config?.sent &&
                isUnauthorized
            ) {
                config.sent = true;

                let accessToken;

                if (LocalStorageManager.getAuthToken() && error.response) {
                    accessToken = await refreshToken();
                } else {
                    accessToken = await getAppToken();
                }

                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${accessToken}`,
                    };
                }

                return axios(config);
            }
        }

        return Promise.reject(error);
    }
);

export default HttpService;