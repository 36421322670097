import React, { useEffect, useState } from 'react';
import { Avatar, Box, Skeleton } from '@mui/material';
import { Business } from '@mui/icons-material';
import EnrollmentService from '../services/EnrollmentService';
import axios from 'axios';

function LocalLogo({ darkMode = false, localUnionId, size = 90 }) {
    const [ isLoadingLogo, setIsLoadingLogo ] = useState(false);
    const [ localUnionLogo, setLocalUnionLogo ] = useState();

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        setIsLoadingLogo(true);
        EnrollmentService.loadLocalUnionLogo(localUnionId, source.token)
            .then((logo) => {
                if(active) {
                    setLocalUnionLogo(logo);
                }
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    setLocalUnionLogo(null);
                    console.log("Couldn't load logo for Local Union #" + localUnionId);
                }
            })
            .then(() => {
                if(active) {
                    setIsLoadingLogo(false);
                }
            });
            
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [localUnionId])
    
    return (
        <Box className={"logo-container local"}>
            {isLoadingLogo ? (
                <Skeleton variant="circular" width={size} height={size} style={darkMode ? { background: "rgba(255,255,255,.21)" } : null}/>
            ) : localUnionLogo ? (
                <img className="logo" alt="[local_logo]" src={localUnionLogo}/>
            ) : (
                <Avatar style={{ height: `${size}px`, width: `${size}px` }}>
                    <Business style={{ height: `${size - 30}px`, width: `${size - 30}px` }}/>
                </Avatar>
            )}
        </Box>
    )
}

export default LocalLogo;