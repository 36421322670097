import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import MoneyInput from "./MoneyInput";

const MaskedTextField = React.forwardRef(({ children, value, onChange, name, mask, inputProps, inputRef, ...rest }, ref) => (
    <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        {...rest}
    >
        {(childProps) => <TextField name={name} ref={ref} inputRef={inputRef} {...inputProps} {...childProps} />}
    </InputMask>
));

const PhoneInput = React.forwardRef((props, ref) => (
    <MaskedTextField mask="(999) 999-9999" ref={ref} {...props} />
));

const YearInput = React.forwardRef((props, ref) => (
    <MaskedTextField mask="9999" ref={ref} {...props} />
));

const ZipInput = React.forwardRef((props, ref) => (
    <MaskedTextField mask="99999" ref={ref} {...props} />
));

const CreditCardInput = React.forwardRef((props, ref) => (
    <MaskedTextField mask="9999 9999 9999 9999" ref={ref} {...props} />
));

export {
    CreditCardInput,
    MoneyInput,
    PhoneInput,
    YearInput,
    ZipInput
};