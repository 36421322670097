import React, { useEffect, useRef, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography } from "@mui/material";
import PaymentMethodAlert from "./PaymentMethodAlert";
import { useAuth } from "../../../providers/AuthProvider";
import { useNotifications } from "../../../providers/NotificationProvider";
import MemberService from "../../../services/MemberService";
import axios from "axios";
import { AccountBalanceWallet, CheckCircle, Info, Warning } from "@mui/icons-material";
import { blue, green, orange } from "@mui/material/colors";

// List IDs for the UCA status
export const UCAStatus = {
    AWAITING_SETUP: 63842,
    AWAITING_VERIFICATION: 64118,
    SETUP_COMPLETE: 63843
}
export const AccountVerificationStatus = {
    INITIATED: 64189,
    MICRO_DEPOSIT_INITIATED: 64190,
    AUTOMATED_MICRO_DEPOSIT_INITIATED: 64273,
    NEEDS_MORE_INFORMATION: 64322,
    TAKING_TOO_LONG: 64192,
    FAILED_TO_VERIFY: 64194,
    ABANDONED: 64193,
    COMPLETED: 64191,
    VERIFIED: 64215
}

function PaymentMethodForm() {

    const { user } = useAuth();
    const showNotification = useNotifications();

    const paymentMethod = useRef();
    const terms = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ termsLoading, setTermsLoading ] = useState(false);

    const loadACHStatus = (cancelToken) => {
        setLoading(true);
        MemberService.getMemberACHStatus(user.id, cancelToken)
            .then(({ customerId, fundingSourceName, status, verificationStatus }) => {
                paymentMethod.current = {
                    customerId,
                    fundingSourceName,
                    status: {
                        ...status,
                        // Transforms timestamp into Date object
                        lastUpdated: new Date(status.lastUpdated + "Z"),
                    },
                    verificationStatus: {
                        ...verificationStatus,
                        // Transforms timestamp into Date object
                        lastUpdated: new Date(verificationStatus.lastUpdated + "Z"),
                    }
                };
                setLoading(false);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    // If no ACH data found
                    if (error.response?.status === 404) {
                        paymentMethod.current = {
                            status: {
                                id: UCAStatus.AWAITING_SETUP,
                                name: "Awaiting Setup"
                            }
                        };
                    }
                    setLoading(false);
                }
            });
    }

    const loadPaymentTerms = (cancelToken) => {
        setTermsLoading(true);
        MemberService.getMostRecentNewMemberEnrollmentInfo(user.id, cancelToken)
            .then(({ entityId, entityTypeId, paymentAgreementText }) => {
                terms.current = paymentAgreementText.toString();
                setTermsLoading(false);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    // If no data found
                    if (error.response?.status === 404) {
                        terms.current = 'Payment terms were not found.'
                        };
                    }
                    setTermsLoading(false);
            });
    }

    const handlePaymentMethodSetupSuccess = () => {
        showNotification({ message: "Payment method set up successfully!", severity: "success" });
    }

    const handlePaymentMethodDialogClose = () => {
        // Reloads ACH status
        loadACHStatus();
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        // Loads ACH status 
        loadACHStatus(source.token);
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source();
        loadPaymentTerms(source.token);
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Card>
            <CardHeader title="Payment Method" />
            <CardContent sx={{ display: { xs: 'flex', sm: 'block'}, flexDirection: 'column-reverse', pt: { xs: '0' } }}>
                <Grid container spacing={4}>
                    <Grid item xs="auto" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {loading || termsLoading ? (
                            <Skeleton animation="wave" variant="circular" width={100} height={100} />
                        ) : (
                            <Avatar sx={{ height: 100, width: 100 }}>
                                <AccountBalanceWallet sx={{ height: "60%", width: "60%" }} />
                            </Avatar>
                        )}
                    </Grid>
                    <Grid item xs="auto">
                        {loading || termsLoading ? (
                        <>
                            <Skeleton animation="wave" variant="text" width={100} sx={{ mb: 0.8 }} />
                            <Skeleton animation="wave" variant="text" width={300} sx={{ mb: 0.8 }} />
                        </>
                        ) : (
                        <>
                            <Typography variant="body1" fontWeight="bold">
                                Customer ID
                            </Typography>
                            <Typography variant="overline" color="GrayText">
                                {paymentMethod.current?.customerId ? (
                                    `XXXXXXXX-XXXX-XXXX-XXXX-XXXXXX${paymentMethod.current?.customerId.substring(paymentMethod.current?.customerId.length - 6)}`
                                ) : (
                                    'NONE'
                                )}
                            </Typography>
                        </>
                        )}
                        <Grid container columnSpacing={6}>
                            <Grid item xs="auto">
                                {loading || termsLoading ? (
                                <>
                                    <Skeleton animation="wave" variant="text" width={120} sx={{ mb: 0.8 }} />
                                    <Skeleton animation="wave" variant="text" width={100} />
                                </>
                                ) : (
                                <>
                                    <Typography variant="body1" fontWeight="bold">
                                        Funding Source
                                    </Typography>
                                    <Typography variant="overline" color="GrayText">
                                        {paymentMethod.current?.fundingSourceName ? (
                                            paymentMethod.current?.fundingSourceName
                                        ) : (
                                            'NONE'
                                        )}
                                    </Typography>
                                </>
                                )}
                            </Grid>
                            <Grid item xs="auto">
                                {loading || termsLoading ? (
                                    <Skeleton animation="wave" variant="text" width={60} sx={{ mb: 0.4 }} />
                                ) : (
                                    <Typography variant="body1" fontWeight="bold">
                                        Status
                                    </Typography>
                                )}
                                <Grid container columnSpacing={1} alignItems="center">
                                    <Grid item xs="auto">
                                        {loading || termsLoading ? (
                                            <Skeleton animation="wave" variant="circular" width={15} height={15} />
                                        ) : paymentMethod.current?.status.id === UCAStatus.SETUP_COMPLETE ? (
                                            <CheckCircle htmlColor={green[500]} sx={{ height: 18, width: 18 }} />
                                        ) : paymentMethod.current?.status.id === UCAStatus.AWAITING_VERIFICATION || paymentMethod.current?.status.id === UCAStatus.AWAITING_COMPLETION ? (
                                            <Info htmlColor={blue[500]} sx={{ height: 18, width: 18 }} />
                                        ) : (
                                            <Warning htmlColor={orange[400]} sx={{ height: 18, width: 18 }} />
                                        )}
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography variant="overline" color="GrayText">
                                            {loading || termsLoading ? (
                                                <Skeleton animation="wave" variant="text" width={60} height={25} />
                                            ) : (
                                                paymentMethod.current?.status.name
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ my: 1.15, borderBottomWidth: '0' }} />
                {loading || termsLoading ? (
                    <Skeleton animation="wave" variant="rounded" width="100%" height={100} />
                ) : (
                    <PaymentMethodAlert 
                        customerId={paymentMethod.current?.customerId}
                        status={paymentMethod.current?.status}
                        terms={terms}
                        verificationStatus={paymentMethod.current?.verificationStatus}
                        onClose={handlePaymentMethodDialogClose}
                        onSuccess={handlePaymentMethodSetupSuccess} />
                )}
            </CardContent>
        </Card>
    );
}

export default PaymentMethodForm;