import React, { createContext, useContext } from "react";
import { Box, LinearProgress, Link, Typography } from "@mui/material";
import LocalUnionCard from "../../../components/LocalUnionCard";

import "./Memberships.css";

export const MembershipContext = createContext();

// Hook for child components to get the membership data
export const useMembership = () => {
    return useContext(MembershipContext);
};

function Memberships({ memberships = [], hasPendingNMEs = true, isLoading = false, hideTitle = false, paddingBottom = false }) {
    return (
        <Box className="local-list" sx={paddingBottom ? { padding: "24px 32px 40px 32px" } : {}}>
            {!hideTitle && (
                <Typography variant="h5">
                    Memberships
                </Typography>
            )}
            {isLoading ? (
                <Box sx={{ height: "20px" }}>
                    <LinearProgress />
                </Box>
            ) : memberships?.length > 0 ? (
                <Box className="local-list-scroller">
                    {memberships.map((membership) => (
                        <MembershipContext.Provider key={membership.id} value={membership}>
                            <LocalUnionCard />
                        </MembershipContext.Provider>
                    ))}
                </Box>
            ) : hasPendingNMEs ? (
                <Typography variant="body2" color="GrayText">
                    You are not an active member in the system yet.
                </Typography>
            ) : (
                <>
                    <Typography variant="body2" color="GrayText" fontWeight="bold" gutterBottom>
                        You have no active memberships or enrollment applications pending.
                    </Typography>
                    <Typography variant="body2" color="GrayText">
                        Please use the <Link href="/portal/fasttrack">FEA FastTrack Membership Enrollment Form</Link> to apply for membership to your local union or go to the <Link href="https://feaweb.org/about-fea/local-unions/">Florida Education Association website</Link> to contact your local union representative.
                    </Typography>
                </>
            )}
        </Box>
    );
}

export default Memberships;