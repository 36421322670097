import React from "react";
import { NumericFormat  } from "react-number-format";

const MoneyInput = React.forwardRef(({ inputRef, onChange, ...props }, ref) => {
  
    return (
        <NumericFormat 
            {...props}
            thousandSeparator
            getInputRef={inputRef}
            allowNegative={false}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
        />
    );
})

export default MoneyInput;