import { memo } from "react";
import { Chip, Grid } from "@mui/material";
import { CheckCircleSharp, ErrorOutline } from "@mui/icons-material";
import { passwordRegex } from "../../util/Validators";

const PasswordRequirement = memo(({label, fulfilled, defaultChipColor = "default"}) => (
    <Chip 
        icon={fulfilled ? <CheckCircleSharp /> : <ErrorOutline />} 
        color={fulfilled ? "primary" : defaultChipColor} 
        variant="outlined" 
        label={label} />
));

function PasswordRequirements({ hide, password }) {
        
    const { atLeastTwelveCharactersRegex, atLeastOneLetterRegex, atLeastOneNumberRegex, atLeastOneSymbolRegex } = passwordRegex;

    const hasAtLeastTwelveCharacters = atLeastTwelveCharactersRegex.test(password);
    const hasAtLeastOneLetter = atLeastOneLetterRegex.test(password);
    const hasAtLeastOneNumber = atLeastOneNumberRegex.test(password);
    const hasAtLeastOneSymbol = atLeastOneSymbolRegex.test(password);

    const meetsAllRequirements = hasAtLeastTwelveCharacters && hasAtLeastOneLetter && hasAtLeastOneNumber && hasAtLeastOneSymbol;

    // The color the chips default to
    const defaultChipColor = hide ? "error" : "default";

    // Only hides the grid if the `hide` property is set `true`
    const shouldHideGrid = hide && (
        // And if there is a password, it has to meet all requirements
        (password && password.length > 0 && meetsAllRequirements)
        || 
        // And if not, the password has to be empty
        (password?.length === 0)
    );

    return (
        <Grid container spacing={1} item xs={12} className={`hideable-grid ${shouldHideGrid ? 'hide' : 'show'}`}>
            <Grid item xs={6}>
                <PasswordRequirement label="12 characters" fulfilled={hasAtLeastTwelveCharacters} defaultChipColor={defaultChipColor}/>
            </Grid>
            <Grid item xs={6}>
                <PasswordRequirement label="One letter" fulfilled={hasAtLeastOneLetter} defaultChipColor={defaultChipColor}/>
            </Grid>
            <Grid item xs={6}>
                <PasswordRequirement label="One number" fulfilled={hasAtLeastOneNumber} defaultChipColor={defaultChipColor}/>
            </Grid>
            <Grid item xs={6}>
                <PasswordRequirement label="One symbol" fulfilled={hasAtLeastOneSymbol} defaultChipColor={defaultChipColor}/>
            </Grid>
        </Grid>
    );
}

export default memo(PasswordRequirements);