import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Autocomplete, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { NoteAddRounded } from "@mui/icons-material";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import LoadingButton from "../../components/LoadingButton";
import EnrollmentService from "../../services/EnrollmentService";
import { useNotifications } from "../../providers/NotificationProvider";

function LocalUnionInformation({ navigateBack, navigateForward, save, saveAndNavigate }) {
    const showNotification = useNotifications();
    const { preSelectedLocalUnionId } = useParams();
    const { localUnionInfo } = useEnrollmentInfo();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        mode: "onTouched"
    });
    
    const [ localUnions, setLocalUnions ] = useState([]);    
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isLoadingLocalUnions, setIsLoadingLocalUnions ] = useState(false);

    const selectedLocalUnion = watch('localUnionInfo.localUnion');

    // Checks if preSelectedLocalUnionId is present in the URL
    const localPreSelected = preSelectedLocalUnionId !== undefined && preSelectedLocalUnionId > 0;
    
    const submitForm = handleSubmit(saveAndNavigate);

    const handleStartEnrollment = () => {
        setIsSubmitting(true);
        // Loads the contact information for the selected local
        EnrollmentService.loadLocalUnionContactInfo(selectedLocalUnion.id)
            .then(({ address, phone }) => {
                // Sets the employer and phone for the selected local union
                const localUnion = {
                    ...selectedLocalUnion,
                    address,
                    phone,
                }
                setValue("localUnionInfo.localUnion", localUnion);
                EnrollmentService.loadEmployerByLocalUnionId(selectedLocalUnion.id)
                .then(employer => {
                    // Sets the employer for the selected local union
                    setValue("localUnionInfo.employer", employer);
                    // Submits information and moves on to next step
                    submitForm();
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        //It's valid to not have an employer.
                        // Sets the employer for the selected local union to null
                        setValue("localUnionInfo.employer", null);
                        // Submits information and moves on to next step
                        submitForm();
                    }
                });            
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    setIsSubmitting(false);
                    showNotification({ message: "Failed to load selected local union. Please try again.", severity: 'error' });
                }
            })

    }

    const onLoadLocalUnions = (localUnions) => {
        setLocalUnions(localUnions);
        // Checks if currently local union is not one of the options available
        if(!selectedLocalUnion || localUnions.findIndex((l) => l.id === selectedLocalUnion.id) === -1) {
            // Defaults selected local union to null
            let selectedLocalUnion = null;
            // Automatically selects local union if there is only one option
            if(localUnions.length === 1) {
                selectedLocalUnion = localUnions[0];
            }
            // Resets the selected local union value
            setValue("localUnionInfo.localUnion", selectedLocalUnion);
        }
    }
    
    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (active) {
            if (localPreSelected) {
                navigateForward();
            } else {
                setIsLoadingLocalUnions(true);
                
                EnrollmentService.loadActiveLocalUnionsForFastTrack(source.token)
                    .then(localUnions => {
                        if(active) {
                            onLoadLocalUnions(localUnions);
                        }
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            showNotification({ message: error.message, severity: 'error' });
                        }
                    })
                    .then(() => {
                        if(active) {
                            setIsLoadingLocalUnions(false);
                        }
                    });
            } 
        }

        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localPreSelected])

    useEffect(() => {
        if (localUnionInfo && (localUnionInfo.localUnion)) {
            reset({ localUnionInfo });
        }
    }, [reset, localUnionInfo])

    return localPreSelected ? (
            null
        ) : (
        <Dialog
            open fullWidth
        >
            <DialogTitle>
                Enrollment Application
                <br/>
                <Typography variant="subtitle2" color="textSecondary" component="div">
                    Choose your local union to begin
                </Typography>
            </DialogTitle>
            <DialogContent dividers> 
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleStartEnrollment();
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="localUnionInfo.localUnion"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        autoSelect autoHighlight openOnFocus
                                        options={localUnions}
                                        loading={isLoadingLocalUnions}
                                        getOptionLabel={localUnion => localUnion.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(error)}
                                                label="Local Union"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {isLoadingLocalUnions ? <CircularProgress size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                fullWidth required/>
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                        onChange={(_, data) => field.onChange(data)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handleStartEnrollment}
                    disabled={!selectedLocalUnion}
                    loading={isSubmitting}
                    endIcon={<NoteAddRounded />}
                    >
                    Start Enrollment
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default LocalUnionInformation;