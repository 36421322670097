import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Card, CardActions, CardContent, CardHeader, Grid, Link, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import LoadingButton from "../../../../components/LoadingButton";
import MemberService from "../../../../services/MemberService";

function SignUpForm({ onComplete }) {

    const history = useHistory();  

    const { control, handleSubmit, trigger } = useForm();

    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const registrationResult = useRef();

    const handleFindMember = ({ firstName, lastName, dateOfBirth }) => {
        setIsSubmitting(true);

        MemberService.sendRegistrationEmailIfMemberExists(firstName, lastName, dateOfBirth)
            .then((message) => {
                registrationResult.current = "found";
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 404:
                            registrationResult.current = "notfound";
                            break;
                        case 409:
                            registrationResult.current = "conflict";
                            break;
                        default:
                            registrationResult.current = "error";
                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            })
            .then(() => {
                setIsSubmitting(false);
                onComplete(registrationResult.current);
            })
    }
    
    const submitForm = handleSubmit(handleFindMember);

    const onClickSubmit = () => (
        trigger()
            .then((isValid) => {
                if (isValid) {
                    submitForm();
                }
            })
    )

    const handleClickCancel = () => {
        history.push(`/signin`);
    }

    return (
        <Card>
            <CardHeader title="Create Portal Account" subheader="Let's find your membership details" />
            <CardContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        error={Boolean(error)}
                                        disabled={isSubmitting}
                                        required fullWidth autoFocus {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        error={Boolean(error)}
                                        disabled={isSubmitting}
                                        required fullWidth {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="dateOfBirth"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        label="Date of Birth"
                                        type="date"
                                        variant="outlined"
                                        error={Boolean(error)}
                                        disabled={isSubmitting}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth required {...field} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <CardActions className="space-between">
                <Link underline="hover" onClick={handleClickCancel}>Cancel</Link>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onClickSubmit}
                    loading={isSubmitting}
                    startIcon={<AccountCircle />}
                >
                    Find my details
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default React.memo(SignUpForm);