import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, Card, CardActions, CardContent, CardHeader, Button, TextField, Grid, FormControlLabel, Checkbox, InputAdornment, CircularProgress, Dialog, Typography, Link, Box } from '@mui/material';
import LoadingButton from "../../components/LoadingButton";
import { doesNotContainDoubleQuotes, isValidEmailAddress } from "../../util/Validators";
import { states } from "../../resources";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import { useNotifications } from "../../providers/NotificationProvider";
import { PhoneInput, ZipInput } from "../../components/FormattedInputs";
import EnrollmentService, { blueprintTypes } from "../../services/EnrollmentService";
import SmartyStreetsAutocomplete from "../../components/SmartyStreetsAutocomplete";
import MemberService from "../../services/MemberService";
import axios from "axios";
import SignInForm from "../LoginView/SignInForm";
import { OpenInNew, PersonPin } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: '.5rem',
    },
    icon: {
        fontSize: "3rem !important",
    },
}));

function PersonalInformation({ navigateBack, navigateForward, save, saveAndNavigate }) {
    
    const classes = useStyles();
    const { personalInfo } = useEnrollmentInfo();
    const showNotification = useNotifications();
    const { control, formState: { errors }, handleSubmit, trigger, reset, setFocus, setValue, getValues, watch } = useForm({
        mode: "onChange",
    });
    
    const emailIsAvailable = useRef(true);

    const [ races, setRaces ] = useState([]);
    const [ genders, setGenders ] = useState([]);
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ isLoggingIn, setIsLoggingIn ] = useState(false);
    const [ isLoggingOut, setIsLoggingOut ] = useState(false);
    const [ isCheckingIfAccountExists, setIsCheckingIfAccountExists ] = useState(false);
    const [ isLoadingOptions, setIsLoadingOptions ] = useState(false);

    const email = watch("personalInfo.personalEmail");
    const cellPhone = watch("personalInfo.cellPhone");
    const homePhone = watch("personalInfo.homePhone");
    const userLoggedIn = watch("personalInfo.userLoggedIn");
    const individual = watch("personalInfo.individual");

    const hasProvidedPhoneNumber = Boolean((cellPhone && cellPhone.length > 0) || (homePhone && homePhone.length > 0));

    const submitForm = handleSubmit(saveAndNavigate);

    const resetLoginFields = () => {
        // Clears email, individual id, and login flag
        setValue("personalInfo.personalEmail", "");
        setValue("personalInfo.userLoggedIn", false);
        setValue("personalInfo.individual", "");
    }

    const onCancelLogIn = () => {
        setIsLoggingIn(false);
        resetLoginFields();
    }

    const onAuthenticate = ({ user }) => {
        setValue("personalInfo.userLoggedIn", true);
        setValue("personalInfo.individual", {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`
        });

        const attributesToUpdate = [ "firstName", "lastName", "nickname" ];

        // Autofills information on the form if fields are still empty
        for (const attrName of attributesToUpdate) {
            const formValue = getValues(`personalInfo.${attrName}`);
            
            if (!formValue || formValue.length === 0) {
                setValue(`personalInfo.${attrName}`, user[attrName]);
            }
        }

        setIsLoggingIn(false);
    }

    const onClickNotYou = () => {
        setIsLoggingOut(true);

        resetLoginFields();
        
        showNotification({ message: "You signed out. Please sign in with your correct account or create a new account.", severity: 'info', duration: 5000 });
        
        setIsLoggingOut(false);
    }

    const next = () => {
        setSubmitting(true);

        trigger()
            .then((isValid) => {
                if (isValid) {
                    if (userLoggedIn){
                        submitForm();
                    } else {
                        emailIntegrityCheck()
                            .then(() => {
                                submitForm();
                            })
                            .catch(async () => {
                                emailIsAvailable.current = false;
                                setSubmitting(false);
                                //force repaint of control so the message shows below the control
                                await trigger(["personalInfo.personalEmail"]);
                            });
                    }
                } else {
                    setSubmitting(false);
                    
                    showNotification({ message: "Please fix the highlighted errors and try again", severity: 'error' });
                    // Finds the first field with an error
                    const firstError = Object.values(Object.values(errors)[0])[0];

                    if (firstError && firstError.ref) {
                        // Sets focus on field with error 
                        firstError.ref.focus();
                    }
                }
            });
    };

    const emailIntegrityCheck = () => {
        const email = getValues('personalInfo.personalEmail');
        const dateOfBirth = getValues('personalInfo.dateOfBirth');
        const firstName = getValues('personalInfo.firstName');
        const lastName = getValues('personalInfo.lastName');

        return MemberService.checkIfEmailIsInUse(email, dateOfBirth, firstName, lastName);
    }

    const emailIsAvailableValidator = () => (
        emailIsAvailable.current || "You cannot use this email address, it is already in use by another account."
    )

    const addressAutocomplete = (address) => {
        if (address) {
            const { city, state, zipcode } = address;
            // Resets the address information
            setValue("personalInfo.city", city);
            setValue("personalInfo.state", state);
            setValue("personalInfo.zipCode", zipcode);
            // Sets focus on phone field (the first field after address stuff)
            setFocus("personalInfo.personalEmail", true);
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        // Triggers loading state
        setIsLoadingOptions(true);

        Promise.all([
            EnrollmentService.loadEnrollmentMetadata(blueprintTypes.GENDER, source.token)
                .then(setGenders),
            EnrollmentService.loadEnrollmentMetadata(blueprintTypes.RACE, source.token)
                .then(setRaces)
        ])
            .then(() => setIsLoadingOptions(false))
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    showNotification({ message: error.message, severity: 'error' });
                }
            });

        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Tests if the object is not an empty object
        if (personalInfo && personalInfo.firstName) {
            reset({ personalInfo });
        }
    }, [reset, personalInfo])

    return (
        <>
            <Card variant="outlined">
                <CardHeader title="Personal Information" />
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controller
                                    name="personalInfo.firstName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: true,
                                        validate: {
                                            doesNotContainDoubleQuotes
                                        }
                                    }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField 
                                            {...field}
                                            inputRef={ref}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            label="First Name"
                                            variant="outlined"
                                            required fullWidth autoFocus />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                <Controller
                                    name="personalInfo.middleName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        validate: {
                                            doesNotContainDoubleQuotes
                                        }
                                    }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField 
                                            {...field}
                                            inputRef={ref}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            label="Middle Name"
                                            variant="outlined"
                                            fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controller
                                    name="personalInfo.lastName"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: true,
                                        validate: {
                                            doesNotContainDoubleQuotes
                                        }
                                    }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField 
                                            {...field}
                                            inputRef={ref}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            label="Last Name"
                                            variant="outlined"
                                            required fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Controller
                                    name="personalInfo.nickname"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field: { ref, ...field} }) => (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            label="Preferred Nickname"
                                            variant="outlined"
                                            fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Controller
                                    name="personalInfo.address"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <SmartyStreetsAutocomplete
                                            {...field}
                                            label="Address"
                                            onSelect={addressAutocomplete}
                                            error={Boolean(error)}
                                            helperText={error && "Your mailing address has errors. Please check it for accuracy. We rely on the USPS address database to ensure we have the most accurate information possible."}
                                            required fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={2}>
                                <Controller
                                    name="personalInfo.city"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            label="City"
                                            variant="outlined"
                                            error={Boolean(error)}
                                            required fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={2}>
                                <Controller
                                    name="personalInfo.state"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            options={states}
                                            getOptionLabel={state => state}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    label="State"
                                                    variant="outlined"
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={2}>
                                <Controller
                                    name="personalInfo.zipCode"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <ZipInput {...field}
                                            inputRef={ref}
                                            inputProps={{
                                                label: "Zip Code",
                                                variant: "outlined",
                                                error: Boolean(error),
                                                required: true,
                                                fullWidth: true
                                            }}>
                                        </ZipInput>
                                    )}
                                />
                            </Grid>
                            <Controller
                                name="personalInfo.userLoggedIn"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <input type="hidden" {...field} />
                                )}
                            />
                            <Controller
                                name="personalInfo.individual"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <input type="hidden" {...field} />
                                )}
                            />
                            {userLoggedIn ? (
                                <Grid item xs={12} sm={6} lg={3} className={classes.container}>
                                    <PersonPin className={classes.icon} />
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                                            Welcome, {individual.name}!
                                        </Typography>
                                        <Link underline="always" onClick={onClickNotYou}>Not you?</Link>
                                    </Box>
                                </Grid>
                            ) : isLoggingIn ? (
                                <Grid item xs={12} sm={6} lg={3} className={classes.container}>
                                    <OpenInNew className={classes.icon} />
                                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                                        Logging you in...
                                    </Typography>
                                </Grid>
                            ) : isLoggingOut ? (
                                <Grid item xs={12} sm={6} lg={3} className={classes.container}>
                                    <CircularProgress className={classes.icon} />
                                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                                        Logging out...
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Controller
                                        name="personalInfo.personalEmail"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: true,
                                            validate: {
                                                isValidEmailAddress,
                                                emailIsAvailableValidator
                                            }
                                        }}
                                        render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
                                            <TextField 
                                                {...field}
                                                inputRef={ref}
                                                error={Boolean(error)}
                                                helperText={error && error.message}
                                                label="Personal Email"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: isCheckingIfAccountExists && (
                                                        <InputAdornment position="end">
                                                            <CircularProgress size={20} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => {
                                                    // Resets validation flag
                                                    emailIsAvailable.current = true;
                                                    onChange(e);
                                                }}
                                                onBlur={() => {
                                                    if (!error && email) {
                                                        setIsCheckingIfAccountExists(true);

                                                        EnrollmentService.checkIfAccountIsInUse(email)
                                                            .catch(() => {
                                                                emailIsAvailable.current = false;
                                                                setIsLoggingIn(true);
                                                            })
                                                            .finally(() => {
                                                                setIsCheckingIfAccountExists(false);
                                                            });
                                                    }
                                                }}
                                                required fullWidth />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={3} lg={3}>
                                <Controller
                                    name="personalInfo.cellPhone"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                        <PhoneInput {...field}
                                            inputRef={ref}
                                            inputProps={{
                                                label: "Cell Phone",
                                                variant: "outlined",
                                                error: Boolean(error),
                                                fullWidth: true,
                                                required: true
                                            }}>
                                        </PhoneInput>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3}>
                                <Controller
                                    name="personalInfo.homePhone"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                        <PhoneInput {...field}
                                            inputRef={ref}
                                            inputProps={{
                                                label: "Home Phone",
                                                variant: "outlined",
                                                fullWidth: true
                                            }}>
                                        </PhoneInput>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Controller
                                    name="personalInfo.race"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            options={races}
                                            loading={isLoadingOptions}
                                            getOptionLabel={race => race?.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    label="Race"
                                                    variant="outlined"
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3}>
                                <Controller
                                    name="personalInfo.gender"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...field}
                                            autoSelect autoHighlight openOnFocus
                                            options={genders}
                                            loading={isLoadingOptions}
                                            getOptionLabel={gender => gender?.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(error)}
                                                    label="Gender"
                                                    variant="outlined"
                                                    fullWidth required />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5} lg={3}>
                                <Controller
                                    name="personalInfo.dateOfBirth"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field}, fieldState: { error } }) => (
                                        <TextField
                                            {...field} 
                                            inputRef={ref}
                                            label="Date of birth"
                                            type="date"
                                            variant="outlined"
                                            error={Boolean(error)}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth required />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="personalInfo.agreedToBeTexted"
                                    control={control}
                                    defaultValue={true}
                                    rules={{ required: false }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            disabled={!hasProvidedPhoneNumber}
                                            control={<Checkbox color="primary" checked={value} onChange={onChange} required />}
                                            label="By providing my phone number, I understand that my local association, the Florida Education Association, the National Education Association, and the American Federation of Teachers may use automated calling techniques and/or text message me on a regular basis. These entities will never charge me for text message alerts. Carrier message and data rates may apply to such alerts."
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
                <CardActions className="align-right">
                    <Button onClick={navigateBack} disabled={isCheckingIfAccountExists || isLoggingIn}>Back</Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={next}
                        disabled={isCheckingIfAccountExists || isLoggingIn}
                        loading={isSubmitting}
                    >
                        Next
                    </LoadingButton>
                </CardActions>
            </Card>
            <Dialog open={isLoggingIn}>
                <SignInForm presetEmailAddress={email} onCancel={onCancelLogIn} onAuthenticate={onAuthenticate}/>
            </Dialog>
        </>
    )
}

export default PersonalInformation;