import HttpService from "./HttpService";

const AuthService = {
    checkToken: (token, includeUserData, cancelToken) => {
        return HttpService.post(
            `/api/authenticate/portal?includeData=${Boolean(includeUserData)}`,
            {
                token
            },
            {
                cancelToken,
            }
        ).then(response => response.data);
    },
    signIn: (username, password, inline, cancelToken) => {
        // Does sign in routine/call authentication endpoint
        return HttpService.post(
            `/api/login/portal?inline=${Boolean(inline)}`,
            {
                username,
                password
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    }, 
    signOut: () => new Promise((resolve) => {
        setTimeout(() => {
            // Does sign out routine
            resolve();
        }, 500);
    }),
    updateProfile: (user, cancelToken) => {
        return HttpService.patch(
            `/api/entitytypes/3/entities/${user.id}`,
            {
                "EntityTypeID": 3,
                "EntityID": user.id,
                "FirstName": user.firstName,
                "MiddleName": user.middleName,
                "LastName": user.lastName
            },
            {
                cancelToken,
            }
        );
    },
    updatePassword: (userId, currentPassword, newPassword, cancelToken) => {
        // Does sign in routine/call authentication endpoint
        return HttpService.patch(
            `/api/portal/users/${userId}/changePassword`,
            {
                currentPassword,
                newPassword,
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    sendAccountRecoveryEmail: (email, cancelToken) => {
        return HttpService.post(
            "/api/portal/recovery", 
            {
                email
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    validateAccountRecoveryLink: (guid, cancelToken) => {
        return HttpService.get(
            `/api/portal/recovery/${guid}`,
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    recoverAccountWithLink: (guid, newPassword, cancelToken) => {
        return HttpService.post(
            `/api/portal/recovery/${guid}`,
            { 
                newPassword
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    createPortalAccount: (guid, email, password, overridePrimaryEmailAddress, cancelToken) => {
        return HttpService.post(
            `/api/portal/registration/${guid}`,
            {
                user: {
                    email,
                    password,
                    overridePrimaryEmailAddress,
                }
            },
            {
                cancelToken
            }
        ).then(response => response.data)
    },
    loadRegistrationLink: (guid, cancelToken) => {
        return HttpService.get(
            `/api/portal/registration/${guid}`,
            {
                cancelToken
            }
        ).then(response => response.data);
    },
}

export default AuthService;
