import _ from "lodash";
import HttpService from "./HttpService";
import { stripHtml } from "../util/Text";

export const blueprintTypes = {
    ACCOUNT_TYPE: 27651,
    AREAS_OF_INTEREST: 27647,
    ENROLLEE_TYPE: 27702,
    EMPLOYEE_TYPE: 27964,
    GENDER: 110,
    PAYMENT_METHOD: 27652,
    RACE: 26307,
    REGISTERED_VOTER: 27650,
    SHIRT_SIZE: 27648,
    SHIRT_COLOR: 27649,
}

export const entityTypes = {
    MEMBERSHIP: 5318
}

const EnrollmentService = {
    checkEnrollmentPaymentMethod: (envelopeId, cancelToken) => {
        return HttpService.get(
            `/api/portal/newmemberenrollment/checksignature/${envelopeId}`,
            {
                cancelToken
            })
            .then(response => response.data)
    },
    checkEnrollmentSignature: (envelopeId, cancelToken) => {
        return HttpService.get(
            `/api/portal/newmemberenrollment/checksignature/${envelopeId}`, 
            { 
                cancelToken
            })
            .then(response => response.data)
    },
    checkIfAccountIsInUse: (email, cancelToken) => {
        return HttpService.post(
            "/api/portal/account/find",
            {
                account: {
                    email
                }
            },
            {
                cancelToken
            }
        ).then(response => response.data);
    },
    loadCounties: (cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5270/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DisplayName": {
                            "fieldType": "info",
                            "fieldName": "DisplayName",
                            "sortOrder": "asc"
                        },
                        "FIPSCode": {
                            "fieldType": "info",
                            "fieldName": "FIPSCode"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5270,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "Relationship",
                            "entityTypeId": 5270,
                            "operator": "EqualTo",
                            "fieldName": "State",
                            "relatedEntityFieldName": "EntityID",
                            "parameterName": "State_0",
                            "defaultParameterValue": 12,
                            "includeRemoved": false
                        }
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(counties => {
                return counties.map(({ EntityID, DisplayName, FIPSCode }) => (
                    {
                        id: EntityID,
                        name: DisplayName,
                        fipsCode: FIPSCode
                    }
                ))
            })
    },
    loadAdditionalNMEOptionsByLocalUnionId: (localUnionId, cancelToken) => {
        return HttpService.get(
                `/api/portal/localunions/${localUnionId}/additionalOptions`,
                {
                    cancelToken
                }
            )
            .then(response => response.data)
    },
    loadEmployersByCountyId: (countyId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/4500",
            { 
                "parameters": [
                    {
                        "name": "CountyId",
                        "value": countyId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => {
                return employers.map(({ EntityID, DisplayName }) => (
                    {
                        id: EntityID,
                        name: DisplayName
                    }
                ))
            })
            .then(employers => _.sortBy(employers, ['name']))
    },
    loadEmployersByJobId: (jobId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14553",
            {
                "parameters": [
                    {
                        "name": "EnrolleeTypeEntityID",
                        "value": jobId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => {
                return employers.map(({ id, name }) => (
                    {
                        id,
                        name
                    }
                ))
            })
            .then(employers => _.sortBy(employers, ['name']))
    },
    loadEmployerByLocalUnionId: (localUnionId, cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5118/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DisplayName": {
                            "fieldType": "info",
                            "fieldName": "DisplayName",
                            "sortOrder": "asc"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5118,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "Relationship",
                            "entityTypeId": 5118,
                            "operator": "EqualTo",
                            "fieldName": "LocalsChapters",
                            "relatedEntityFieldName": "EntityID",
                            "parameterName": "LocalsChapters_0",
                            "defaultParameterValue": localUnionId,
                            "includeRemoved": false
                        }
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => employers[0])
            .then(({ EntityID, DisplayName }) => (
                {
                    id: EntityID,
                    name: DisplayName
                }
            ))
    },
    loadEnrolleeTypesByLocalId: (localUnionId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14552",
            {
                "parameters": [
                    {
                        "name": "LocalChapterID",
                        "value": localUnionId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => employers.map(({ id, name }) => (
                {
                    id,
                    name
                }
            )))
            .then(employers => _.sortBy(employers, ['name']))
    },
    loadDuesPaymentsInfoByMembershipId: (membershipId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14993",
            {
                "parameters": [
                    {
                        "name": "MembershipEntityID",
                        "value": membershipId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "MembershipEntityTypeID",
                        "value": entityTypes.MEMBERSHIP,
                        "dataType": "int",
                        "direction": "in"
                    },
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(result => result[0])
            .then(({ DateList, PaymentMethod, InstallmentAmount, FiscalYearEndDate }) => (
                {
                    dates: DateList,
                    paymentMethod: PaymentMethod,
                    amount: InstallmentAmount,
                    fiscalYearEndDate: FiscalYearEndDate
                }
            ))
    },
    loadDuesPaymentsInfoByLocalIdAndPaymentScheduleId: (paymentScheduleId, enrolleeTypeId, salary, effectiveDate, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14966",
            {
                "parameters": [
                    {
                        "name": "PaymentScheduleEntityID",
                        "value": paymentScheduleId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "PaymentScheduleEntityTypeID",
                        "value": 5811,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "EnrolleeTypeListID",
                        "value": enrolleeTypeId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "salary",
                        "value": salary,
                        "dataType": "decimal",
                        "direction": "in"
                    },
                    {
                        "name": "PaymentScheduleEffectiveDate",
                        "value": effectiveDate,
                        "dataType": "datetime",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(result => result[0])
            .then(({ DateList, PaymentMethod, PaymentFrequency, InstallmentAmount, RequiresSalary, FiscalYearEndDate, MembershipAgreementFormatted, MembershipAgreementText, PaymentAgreementFormatted, PaymentAgreementText, PaymentInfoFormatted, PaymentInfoText }) => (
                {
                    dates: DateList,
                    paymentMethod: PaymentMethod,
                    frequency: PaymentFrequency,
                    amount: InstallmentAmount,
                    requiresSalary: RequiresSalary,
                    fiscalYearEndDate: FiscalYearEndDate,
                    membershipAgreementFormatted: MembershipAgreementFormatted,
                    membershipAgreementText: MembershipAgreementText,
                    paymentAgreementFormatted: PaymentAgreementFormatted,
                    paymentAgreementText: PaymentAgreementText,
                    paymentInfoFormatted: PaymentInfoFormatted,
                    paymentInfoText: PaymentInfoText
                }
            ));
    },
    loadOffsetDaysByPaymentScheduleId: (paymentScheduleId, cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5811/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "CutoffDays": {
                            "fieldType": "info",
                            "fieldName": "CutoffDays",
                            "sortOrder": "asc"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5811,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "FieldComparison",
                            "entityTypeId": 5811,
                            "operator": "EqualTo",
                            "fieldName": "EntityID",
                            "parameterName": "EntityID_0",
                            "defaultParameterValue": paymentScheduleId
                        }
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(result => result[0])
            .then(({ CutoffDays }) => CutoffDays)
    },
    loadWorksitesByEmployerId: (employerId, cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5119/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DisplayName": {
                            "fieldType": "info",
                            "fieldName": "DisplayName",
                            "sortOrder": "asc"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5119,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "Relationship",
                            "entityTypeId": 5119,
                            "operator": "EqualTo",
                            "fieldName": "Employer",
                            "relatedEntityFieldName": "EntityID",
                            "parameterName": "Employer_0",
                            "defaultParameterValue": employerId,
                            "includeRemoved": false
                        },
                        {
                            "filterType": "ListMembership",
                            "entityTypeId": 5119,
                            "operator": "IsOnList",
                            "fieldName": "Status",
                            "parameterName": "Status_0",
                            "listId": 50638
                        }                        
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(worksites => worksites.map(({ EntityID, DisplayName }) => (
                {
                    id: EntityID,
                    name: DisplayName
                }
            )))
            .then(worksites => _.sortBy(worksites, ['name']))
    },
    loadWorksitesByCountyId: (countyId, cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5270/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DistrictCounty": {
                            "fieldType": "relationship",
                            "fieldName": "DistrictCounty",
                            "entityDataView": {
                                "fields": {
                                    "Employers": {
                                        "fieldType": "relationship",
                                        "fieldName": "Employers",
                                        "entityDataView": {
                                            "fields": {
                                                "Worksites": {
                                                    "fieldType": "relationship",
                                                    "fieldName": "Worksites",
                                                    "entityDataView": {
                                                        "fields": {
                                                            "DisplayName": {
                                                                "fieldType": "info",
                                                                "fieldName": "DisplayName"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "sortOrder": "none"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5270,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "FieldComparison",
                            "entityTypeId": 5270,
                            "operator": "EqualTo",
                            "fieldName": "EntityID",
                            "parameterName": "EntityID_0",
                            "defaultParameterValue": countyId
                        },
                        {
                            "filterType": "ListMembership",
                            "entityTypeId": 5119,
                            "operator": "IsOnList",
                            "fieldName": "Status",
                            "parameterName": "Status_0",
                            "listId": 50638
                        }                
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(counties => counties[0])
            .then(county => _.flatten(county.DistrictCounty.map(district => district.RelatedEntity.Employers)))
            .then(employers => _.flatten(employers.map(employer => employer.RelatedEntity.Worksites)))
            .then(worksites => worksites.map(({ RelatedEntity }) => (
                {
                    id: RelatedEntity.EntityID,
                    name: RelatedEntity.DisplayName
                }
            )))
            .then(worksites => _.sortBy(worksites, ['name']))
    },
    loadActiveLocalUnionsForFastTrack: (cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14968",
            {
                "parameters": [
                    {
                        "name": "FastTrack",
                        "value": 1,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(localUnions => _.sortBy(localUnions, ['name']))
    },
    loadLocalUnionsByEmployerId: (employerId, cancelToken) => {
        return HttpService.post(
            "/api/entitytypes/5292/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DisplayName": {
                            "fieldType": "info",
                            "fieldName": "DisplayName",
                            "sortOrder": "asc"
                        },
                        "Website": {
                            "fieldType": "info",
                            "fieldName": "Website"
                        },
                        "DuesDescription": {
                            "fieldType": "info",
                            "fieldName": "DuesDescription"
                        },
                        "LocChapFEAID": {
                            "fieldType": "info",
                            "fieldName": "LocChapFEAID"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5292,
                    "operator": "GroupAnd",
                    "subFilters": [
                        {
                            "filterType": "Relationship",
                            "entityTypeId": 5292,
                            "operator": "EqualTo",
                            "fieldName": "Employer",
                            "relatedEntityFieldName": "EntityID",
                            "parameterName": "Employer_0",
                            "defaultParameterValue": employerId,
                            "includeRemoved": false
                        }
                    ]
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(localUnions => localUnions.map(({ EntityID, DisplayName, Website, DuesDescription, LocChapFEAID }) => (
                {
                    id: EntityID,
                    feaId: LocChapFEAID,
                    name: DisplayName,
                    website: Website,
                    duesDescription: DuesDescription
                }
            )))
    },
    loadLocalUnionsByJobAndEmployerId: (jobId, employerId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14556",
            {
                "parameters": [
                    {
                        "name": "EnrolleeTypeEntityID",
                        "value": jobId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "EmployerEntityID",
                        "value": employerId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(localUnions => localUnions.map(({ id, feaId, name, website, duesDescription }) => (
                {
                    id,
                    feaId,
                    name,
                    website,
                    duesDescription
                }
            )))
    },
    loadLocalUnionsByCountyId: (countyId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/4495",
            {
                "parameters": [
                    {
                        "name": "CountyEntityID",
                        "value": countyId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(localUnions => localUnions.map(({ id, feaId, name, website, duesDescription }) => (
                {
                    id,
                    feaId,
                    name,
                    website,
                    duesDescription
                }
            )))
            .then(localUnions => _.sortBy(localUnions, ['name']))
    },
    loadLocalUnionById: (localUnionId, mustHaveNMEDefaults, cancelToken) => {
        const filters = [
            {
                "filterType": "FieldComparison",
                "entityTypeId": 5292,
                "operator": "EqualTo",
                "fieldName": "EntityId",
                "parameterName": "EntityId_0",
                "defaultParameterValue": localUnionId
            }
        ];

        if (mustHaveNMEDefaults) {
            filters.push({
                "filterType": "Relationship",
                "entityTypeId": 5292,
                "operator": "HasRelationships",
                "fieldName": "NewMemberEnrollmentDefaultLocalChapter",
                "relatedEntityFieldName": "DisplayName",
                "parameterName": null,
                "defaultParameterValue": null,
                "includeRemoved": false
            });
        }

        return HttpService.post(
            "/api/entitytypes/5292/entities/queries",
            {
                "viewDescriptor": {
                    "fields": {
                        "DisplayName": {
                            "fieldType": "info",
                            "fieldName": "DisplayName",
                            "sortOrder": "asc"
                        },
                        "Active": {
                            "fieldType": "info",
                            "fieldName": "Active"
                        },
                        "EnrolledInCMS": {
                            "fieldType": "info",
                            "fieldName": "EnrolledInCMS"
                        },
                        "Website": {
                            "fieldType": "info",
                            "fieldName": "Website"
                        },
                        "DuesDescription": {
                            "fieldType": "info",
                            "fieldName": "DuesDescription"
                        },
                        "LocChapFEAID": {
                            "fieldType": "info",
                            "fieldName": "LocChapFEAID"
                        }
                    }
                },
                "filterDescriptor": {
                    "filterType": "GroupComparison",
                    "entityTypeId": 5292,
                    "operator": "GroupAnd",
                    "subFilters": filters
                },
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(localUnions => localUnions[0])
            .then(({ EntityID, DisplayName, Website, DuesDescription, LocChapFEAID, Active, EnrolledInCMS }) => (
                {
                    id: EntityID,
                    feaId: LocChapFEAID,
                    name: DisplayName,
                    website: Website,
                    duesDescription: DuesDescription,
                    active: Boolean(Active),
                    enrolledInCMS: Boolean(EnrolledInCMS),
                }
            ))
    },
    loadLocalUnionContactInfo: (localUnionId, cancelToken) => {
        return HttpService.post(
            `/api/entitytypes/5292/entities/${localUnionId}/views`,
            {
                "fields": {
                    "LocalChapter_PrimaryAddress_Primary": {
                        "fieldType": "relationship",
                        "fieldName": "LocalChapter_PrimaryAddress_Primary",
                        "includeRemoved": false,
                        "entityDataView": {
                            "fields": {
                                "FormattedAddress": {
                                    "fieldType": "info",
                                    "fieldName": "FormattedAddress"
                                }
                            }
                        }
                    },
                    "LocalChapter_All_PhoneNumbers": {
                        "fieldType": "relationship",
                        "fieldName": "LocalChapter_All_PhoneNumbers",
                        "includeRemoved": false,
                        "entityDataView": {
                            "fields": {
                                "FormattedNumber": {
                                    "fieldType": "info",
                                    "fieldName": "FormattedNumber"
                                },
                                "ItemOrder": {
                                    "fieldType": "info",
                                    "fieldName": "ItemOrder"
                                }
                            }
                        }
                    }
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(({ EntityID, LocalChapter_PrimaryAddress_Primary, LocalChapter_All_PhoneNumbers }) => {
                let sortedPhones = (LocalChapter_All_PhoneNumbers ?? []).map((phone) => {
                    return phone.RelatedEntity;
                }).sort((a, b) => {
                    if (!a.ItemOrder || !b.ItemOrder)
                        return 1;

                    if (a.ItemOrder < b.ItemOrder)
                        return -1;

                    if (a.ItemOrder === b.ItemOrder)
                        return 0;

                    return 1;
                });

                return ({
                    id: EntityID,
                    address: LocalChapter_PrimaryAddress_Primary.length && stripHtml(LocalChapter_PrimaryAddress_Primary[0].RelatedEntity.FormattedAddress),
                    phone: sortedPhones.length && stripHtml(sortedPhones[0].FormattedNumber),
                })
            })
    },
    loadLocalUnionLogo: (localUnionId, cancelToken) => {
        return HttpService.post(
            `/api/entitytypes/5292/entities/${localUnionId}/views`,
            {
                "fields": {
                    "Logo": {
                        "fieldType": "info",
                        "fieldName": "Logo"
                    }
                }
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(({ Logo }) => {
                if (Logo) {
                    const result = Logo.match(`src=\\"(data:image/[^;]+;base64[^"]+)\\"`)
                    return result[1];
                }
            })
    },
    loadEnrollmentMetadata: (blueprintId, cancelToken) => {
        return HttpService.get(
            `/api/system/entities/elementblueprints/${blueprintId}`, 
            {
                cancelToken
            })
            .then((response) => response.data)
            .then(({ PublishedConfiguration: { lists } }) => {
                return lists.filter(item => item.isActive).map(({ listId, friendlyName }) => ({
                    id: listId,
                    name: friendlyName
                }))
            })
            .then(list => _.sortBy(list, ['name']))
    },
    loadDefaultPaymentScheduleByLocalIdAndJobId: (localUnionId, jobId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14969",
            {
                "parameters": [
                    {
                        "name": "LocalChapterEntityID",
                        "value": localUnionId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "EnrollmentTypeID",
                        "value": jobId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(resultSet => resultSet[0])
            .then(defaultPaymentSchedule => ({
                id: defaultPaymentSchedule.EntityID,
                name: defaultPaymentSchedule.DisplayName,
                membershipType: defaultPaymentSchedule.MembershipType,
                continuous: defaultPaymentSchedule.SetMembershipToContinuous
            }))
    },
    loadPaymentSchedulesByLocalAndJobId: (localUnionId, jobId, includeFuture, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14963",
            {
                "parameters": [
                    {
                        "name": "LocalChapterID",
                        "value": localUnionId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "NewMemberEnrollmentEnrolleeTypeID",
                        "value": jobId || 0,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "IncludeFuture",
                        "value": includeFuture || 0,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => employers.map(({ id, name }) => ({
                id,
                name
            })))
            .then(employers => _.sortBy(employers, ['name']))
    },
    loadPaymentMethodsByLocalAndJobId: (localUnionId, jobId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14557",
            {
                "parameters": [
                    {
                        "name": "LocalChapterID",
                        "value": localUnionId,
                        "dataType": "int",
                        "direction": "in"
                    },
                    {
                        "name": "NewMemberEnrollmentEnrolleeTypeID",
                        "value": jobId || 0,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(employers => employers.map(({ id, name }) => (
                {
                    id,
                    name
                }
            )))
            .then(employers => _.sortBy(employers, ['name']))
    },
    loadFiscalYearEndDateByLocalId: (localUnionId, cancelToken) => {
        return HttpService.post(
            "/api/system/entities/filters/14888",
            {
                "parameters": [
                    {
                        "name": "LocalChapterEntityID",
                        "value": localUnionId,
                        "dataType": "int",
                        "direction": "in"
                    }
                ]
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(results => results[0]?.FiscalYearEndDate)
    },
    recreateSigningCeremony: (envelopeId, callback, cancelToken) => {
        return HttpService.get(
            `/api/portal/newmemberenrollment/sign/${envelopeId}`,
            {
                cancelToken,
                params: {
                    callback
                }
            })
            .then(response => response.data)
    },
    submitEnrolllment: (enrollmentInfo, cancelToken) => {
        return HttpService.post(
            "/api/portal/newmemberenrollment",
            enrollmentInfo,
            {
                cancelToken
            })
            .then(response => response.data)
            .then(({ entityId }) => entityId)
    },
    submitFastTrackEnrolllment: (enrollmentInfo, cancelToken) => {
        return HttpService.post(
            "/api/portal/fasttrackenrollment",
            enrollmentInfo,
            {
                cancelToken
            })
            .then(response => response.data)
            .then(({ entityId, redirectUrl }) => ({ entityId, redirectUrl }))
    },
    submitEnrolllmentForSignature: (enrollmentInfo, callbackUrl, cancelToken) => {
        return HttpService.post(
            "/api/portal/newmemberenrollment/sign",
            {
                enrollmentInfo,
                callbackUrl
            },
            {
                cancelToken
            })
            .then(response => response.data)
            .then(({ envelopeId, redirectUrl }) => ({ envelopeId, redirectUrl }))
    }
}

export default EnrollmentService;