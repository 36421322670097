import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Alert, AlertTitle, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import EnrollmentService from "../../services/EnrollmentService";
import { useNotifications } from "../../providers/NotificationProvider";
import _ from "lodash";

function Submit({ navigateBack, navigateForward, save, saveAndNavigate }) {
    const showNotification = useNotifications();
    const enrollmentInfo = useEnrollmentInfo();
    const history = useHistory();

    const [ message, setMessage ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    // Checks if the enrollment object is valid
    const isValidEnrollment = () => {
        // Defines the required attributes
        const attributesToValidate = ["localUnionInfo", "personalInfo", "accountInfo", "agreementInfo"];
        
        for(let i = 0; i < attributesToValidate.length; i++) {
            // Checks if object key is a valid object
            if (_.isEmpty(enrollmentInfo[attributesToValidate[i]])) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (active) {
            if (isValidEnrollment()) {
                console.log("Submitting Fast Track NME...");
                setMessage("Saving information...");
                setIsLoading(true);

                // Tries to submit the enrollment data
                EnrollmentService.submitFastTrackEnrolllment(enrollmentInfo, source.token)
                    .then(({ entityId, redirectUrl }) => {
                        // Redirects to portal landing page
                        history.push(redirectUrl);
                    })
                    .catch(error => {
                        setMessage("An error has occurred, please try again.");
                        setIsLoading(false);

                        if (!axios.isCancel(error)) {
                            // Logs error in browser's console
                            console.log("Failed to submit enrollment.", error);
                            
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                showNotification({ message: "Failed to submit enrollment, please try again", severity: 'error', duration: 20000 });

                                if (error.response.status === 400) {
                                    console.log("Error details:", error.response.data);
                                }
                            } else if (error.request) {
                                // The request was made but no response was received
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                showNotification({ message: "Unable to reach server, please try again", severity: 'error', duration: 20000 });
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                showNotification({ message: error.message, severity: 'error', duration: 20000 });
                            }
                        }
                    });
            } else {
                console.log("Invalid enrollment data!");
                setMessage("Invalid information, please try again.");
                setIsLoading(false);
            }
        }

        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [enrollmentInfo])

    return (
        <Dialog open fullWidth>
            <DialogTitle>
                Enrollment Application
            </DialogTitle>
            <DialogContent dividers> 
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                    {isLoading ? (
                        <>
                            <CircularProgress color="inherit"/>
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                {message}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Warning color="inherit" />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                {message}
                            </Typography>
                            <Alert severity="info">
                                <AlertTitle>Running into trouble?</AlertTitle>
                                For further assistance please send an email to <strong><a target="_blank" rel="noreferrer" href="mailto:centralmembership@floridaea.org" style={{ textDecoration: "none", color: "inherit" }}>centralmembership@floridaea.org</a></strong>.
                            </Alert>
                        </>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default Submit;