import { memo } from "react";
import { CheckCircle, KeyboardReturn, VerifiedUser, Warning } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const Result = memo(({ headerText, headerIcon, message, btnAction, btnText, btnIcon }) => (
        <Card>
            <CardHeader title={
                <div style={{
                    gap: "0.5rem",
                    display: "flex",
                    alignItems: "center"
                }}>
                    {headerIcon} {headerText}
                </div>
            } />
            <CardContent>
                {message}
            </CardContent>
            <CardActions className="align-right">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={btnAction}
                    startIcon={btnIcon}
                >
                    {btnText}
                </Button>
            </CardActions>
        </Card>
    )
);

const Found = ({ action }) => (
    <Result 
        headerText="Congratulations!"
        headerIcon={<CheckCircle />}
        message={
            <Typography variant="h6">
                You are almost done, <b>check your email</b> for the last steps to finish your account set up.
            </Typography>
        }
        btnAction={action}
        btnText="Go back" 
        btnIcon={<KeyboardReturn />}
        />
)

const NotFound = ({ action }) => (
    <Result 
        headerText="Membership not found"
        headerIcon={<Warning />}
        message={
            <Typography variant="h6">
                We were not able to locate your membership. If you would like to become a member today, <Link underline="always" href={`${process.env.PUBLIC_URL}/newEnrollment`}>click here</Link> to fill out a new member enrollment form.
            </Typography>
        }
        btnAction={action}
        btnText="Go back" 
        btnIcon={<KeyboardReturn />}
        />
)

const Conflict = ({ action }) => (
    <Result 
        headerText="It looks like you already have an account!"
        headerIcon={<VerifiedUser color="primary"/>}
        message={
            <Typography variant="h6">
                Our records show that you already have a member portal account. If you forgot your password, <Link underline="always" href={`${process.env.PUBLIC_URL}/signin/forgotPassword`}>click here</Link> to reset it.
            </Typography>
        }
        btnAction={action}
        btnText="Go back" 
        btnIcon={<KeyboardReturn />}
        />
)

const RegistrationResult = memo(({ result }) => {
    const history = useHistory();

    const goBack = () => history.replace('/signin');

    switch(result) {
        case "found":
            return (
                <Found action={goBack} />
            )
        case "notfound":
            return (
                <NotFound action={goBack} />
            )
        case "conflict":
            return (
                <Conflict action={goBack} />
            )
        default:
            return null;
    }
})

export default memo(RegistrationResult)