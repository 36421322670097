import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Box, Grid, useMediaQuery } from '@mui/material';
import SignInForm from './SignInForm';
import './LoginView.css'
import RegistrationForm from './RegistrationForm';
import PasswordResetForm from './PasswordResetForm';

function LoginView() {
    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box className="login-container" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Box className="left-panel" sx={{ display: { xs: 'flex', md: 'flex' } }}>
                <div className="logo"/>
                {!isMobileDevice && (
                <>
                    <hr/>
                    <div className="title">
                        <h4>Member<br/>Portal</h4>
                    </div>
                </>
                )}
            </Box> 
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={8} lg={6} xl={5} className="right-panel">
                    <Switch>
                        <Route path="/signin">
                            <SignInForm />
                        </Route>
                        <Route path="/registration">
                            <RegistrationForm />
                        </Route>
                        <Route path="/recovery">
                            <PasswordResetForm />
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
        </Box>
    );
}

export default LoginView;
