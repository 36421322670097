import React, { useEffect, useRef, useState } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { LocationOn, Phone } from "@mui/icons-material";
import axios from "axios";
import EnrollmentService from "../../services/EnrollmentService";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";

import "./LocalContactInfo.css";

function LocalContactInfo({ localUnionId, leftAlign = false, darkMode = false }) {
    
    const enrollmentInfo = useEnrollmentInfo();

    const contactInfo = useRef();
    const [ isLoadingInfo, setIsLoading ] = useState(true);

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        const localFromContextHasContactInfo = enrollmentInfo?.localUnionInfo?.localUnion?.id && enrollmentInfo?.localUnionInfo?.localUnion?.address && enrollmentInfo?.localUnionInfo?.localUnion?.phone;        
        
        if (localFromContextHasContactInfo) {
            if (!localUnionId || localUnionId === enrollmentInfo?.localUnionInfo?.localUnion?.id) {
                contactInfo.current = {
                    address: enrollmentInfo?.localUnionInfo?.localUnion?.address,
                    phone: enrollmentInfo?.localUnionInfo?.localUnion?.phone
                }

                setIsLoading(false);

                return;
            }
        }
        
        const localUnionIdToUse = localUnionId || enrollmentInfo?.localUnionInfo?.localUnion?.id;
        
        if (localUnionIdToUse) {
            setIsLoading(true);

            EnrollmentService.loadLocalUnionContactInfo(localUnionId, source.token)
                .then((info) => {
                    if(active) {
                        contactInfo.current = info;
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        contactInfo.current = null;
                        console.log("Couldn't load contact info for Local Union #" + localUnionId);
                    }
                })
                .then(() => {
                    if(active) {
                        setIsLoading(false);
                    }
                });
        }
                    
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [localUnionId, enrollmentInfo])
    
    return isLoadingInfo ? (
        <Grid container flexDirection="column">
            <Grid item container columnSpacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Skeleton animation="wave" variant="circular" width={15} height={15} />
                </Grid>
                <Grid item>
                    <Skeleton animation="wave" variant="text" width={80} height={25} />
                </Grid>
            </Grid>
            <Grid item container columnSpacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Skeleton animation="wave" variant="circular" width={15} height={15} />
                </Grid>
                <Grid item>
                    <Skeleton animation="wave" variant="text" width={150} height={25} />
                </Grid>
            </Grid>
        </Grid>
    ) : contactInfo.current ? (
        <div className={`local-info ${leftAlign ? "left-align" : ""}`}>
            {contactInfo.current.phone ? (
                <div className="contact-info">
                    <Phone fontSize="small" />
                    {String(contactInfo.current.phone) === "0" ? (
                        <Typography component="span" variant="subtitle2" color="textSecondary" style={darkMode ? { color: "white" } : null}>
                            Unknown
                        </Typography>
                    ) : (
                        <a href={`tel:${contactInfo.current.phone}`} style={{ textDecoration: "none" }}>
                            <Typography component="span" variant="subtitle2" color="textSecondary" style={darkMode ? { color: "white" } : null}>
                                {contactInfo.current.phone}
                            </Typography>
                        </a>
                    )}
                </div>
            ) : null}
            {contactInfo.current.address ? (
                <div className="contact-info">
                    <LocationOn fontSize="small" />
                    <Typography component="span" variant="subtitle2" color="textSecondary" style={darkMode ? { color: "white" } : null}>
                        {contactInfo.current.address}
                    </Typography>
                </div>
            ) : null}
        </div>
    ) : null
}

export default LocalContactInfo;