import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import styles from "./styles.module.css";

const ResizableSignatureCanvas = React.forwardRef(({ canvasProps, onClear, onEnd, ...props }, ref) => {

    const defaultHeight = 200;
    const signatureCanvasWrapper = useRef();
    const [ width, setWidth ] = useState(200);
    
    const observer = useMemo(() => new ResizeObserver((entries, _) => {
        // Get container element - it should be the first and only entry in the entries array.
        const canvasWrapper = entries[0].target;
        // Change width to fit container
        setWidth(canvasWrapper.clientWidth);
        // Fires onClear event
        if (onClear) {
            onClear();
        }
        // eslint-disable-next-line
    }), []);

    useEffect(() => {
        const canvasElement = signatureCanvasWrapper.current;

        if (canvasElement) {
            observer.observe(canvasElement);

            return function cleanup() {
                observer.unobserve(canvasElement);
            }
        }
    }, [observer, signatureCanvasWrapper])

    return (
        <Box ref={signatureCanvasWrapper} className={styles.sigWrapper}>
            <Tooltip title="Clear" placement="right">
                <IconButton aria-label="clear-signature" className={styles.clearButton} onClick={onClear}>
                    <RotateLeftIcon />
                </IconButton>
            </Tooltip>
            <ReactSignatureCanvas
                ref={ref}
                onEnd={onEnd}
                canvasProps={{height: defaultHeight, ...canvasProps, width}}
                {...props} />
        </Box>
    )
});

export default ResizableSignatureCanvas;