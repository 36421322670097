// These IDs are used when comparing user selections on the screen

export const EnrolleeTypes = {
    COLLEGE_STUDENT: 52185,
    EDUCATION_STAFF_PROFESSIONAL: 52181,
    HIGHER_EDUCATION_FACULTY: 52182,
    HIGHER_EDUCATION_GRADUATE_ASSISTANT: 52183,
    NOT_SURE: 52186,
    RETIREE: 52184,
    TEACHER: 52180
}

export const PaymentMethods = {
    ACH: 53022,
    CASH: 53023,
    CHECK: 53024,
    CREDIT_CARD: 53025,
    DEBIT_CARD: 53026,
    PAYROLL_DEDUCTION: 53027,
}
