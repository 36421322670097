import { Link } from "@mui/material";

function DwollaTermsOfServiceLink({ children, underline }) {
    return (
        <Link 
            underline={underline || "always"}
            target="_blank"
            rel="noreferrer"
            href="https://www.dwolla.com/legal/tos/"
        >
            {children || "Terms of Service"}
        </Link>
    )
}

export default DwollaTermsOfServiceLink;