const USER_KEY = "aw_user";
const TOKEN_KEY = "aw_token";

const getLocalStorage = () => {
    return sessionStorage
}

// Logged User
const getLoggedInUser = () => {
    const user = getLocalStorage().getItem(USER_KEY);
    return user !== 'undefined' ? JSON.parse(user) : null;
}
const setLoggedInUser = user => {
    getLocalStorage().setItem(USER_KEY, JSON.stringify(user));
}
const clearLoggedInUser = () => {
    getLocalStorage().removeItem(USER_KEY);
};
const clearAuthToken = () => {
    getLocalStorage().removeItem(TOKEN_KEY);
};
const clearLoggedInUserAndToken = () => {
    clearLoggedInUser();
    clearAuthToken();
};

// Auth Token (JWT)
const getAuthToken = () => {
    const token = getLocalStorage().getItem(TOKEN_KEY);
    return token !== 'undefined' ? token : undefined;
}
const setAuthToken = token => {
    getLocalStorage().setItem(TOKEN_KEY, token);
}

const LocalStorageManager = {
    getLoggedInUser,
    setLoggedInUser,
    clearAuthToken,
    clearLoggedInUser,
    clearLoggedInUserAndToken,
    getAuthToken,
    setAuthToken,
    LocalStorage: {
        getItem: getLocalStorage().getItem,
        setItem: getLocalStorage().setItem,
    },
}

export default LocalStorageManager;