import React, { useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import "./Menu.css";

const MenuLink = ({ label, to, onClick, activeOnlyWhenExact }) => {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });
  
    return (
        <Link underline="hover" className={match ? "active" : ""} to={to} onClick={onClick}>{label}</Link>
    );
}

function Menu({ className, options, onItemClicked }) {
    const classes = "menu " + className;
    const time = useRef(new Date());

    return (
        <nav className={classes}>
            <img className="logo" alt="[logo]" src="https://feaweb.org/images/apple-touch-icon.png"/>
            {/* <h5>Hello, {isSignedIn ? user.nickname : null}!</h5> */}
            <h5>
                Good&nbsp;
                {
                    time.current.getHours() < 12 ? (
                        "morning"
                    ) : time.current.getHours() < 18 ? (
                        "afternoon"
                    ) : (
                        "evening"
                    )
                }
                !
            </h5>
            <hr/>
            {options.map(({name, path, exact, items}) => {
                return items ? (
                    <React.Fragment key={name}>
                        <h4>{name}</h4>
                        <ul>
                            {items.map(link => 
                                <li key={link.name}>
                                    <MenuLink label={link.name} to={link.path} activeOnlyWhenExact={link.exact} onClick={onItemClicked} />
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                ) : (
                    <MenuLink key={name} label={name} to={path} activeOnlyWhenExact={exact} onClick={onItemClicked} />
                )
            })}
        </nav>
    )
}

export default Menu;