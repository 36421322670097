import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, Card, CardActions, CardContent, CardHeader, Button, Grid, TextField, Typography, Avatar } from '@mui/material';
import axios from "axios";
import { BusinessCenter } from "@mui/icons-material";
import LoadingButton from "../../components/LoadingButton";
import { PhoneInput } from "../../components/FormattedInputs";
import EnrollmentService, { blueprintTypes } from "../../services/EnrollmentService";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import { isValidEmailAddress } from "../../util/Validators";
import { useNotifications } from "../../providers/NotificationProvider";

const EmployerCard = ({ employerName, countyName }) => (
    <Card variant="outlined">
        <CardContent>
            <Grid container spacing={3}>
                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Avatar style={{ height: "80px", width: "80px" }}>
                        <BusinessCenter  style={{ height: "50px", width: "50px" }}/>
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Selected Employer
                    </Typography>
                    <Typography variant="h6">
                        {employerName}
                    </Typography>
                    <Typography variant="subtitle2">
                        {countyName}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

function EmployerInformation({ navigateBack, navigateForward, save, saveAndNavigate }) {
    const showNotification = useNotifications();
    const { employerInfo, localUnionInfo: { county, employer } } = useEnrollmentInfo();
    const { control, formState: { isValid, isSubmitting, isValidating }, handleSubmit, reset, trigger } = useForm({
        mode: "onTouched"
    });

    const [ isLoading, setIsLoading ] = useState(false);

    const [ worksites, setWorksites ] = useState([]);    
    const [ employeeTypes, setEmployeeTypes ] = useState([]);    
    
    const submitForm = handleSubmit(saveAndNavigate);

    const next = () => trigger()
        .then(() => {
            if (isValid) {
                submitForm();
            }
        }
    );
    
    useEffect(() => {
        const source = axios.CancelToken.source();

        if (employer) {
            setIsLoading(true);
            Promise.all([
                // Loads worksites for selected local
                EnrollmentService.loadWorksitesByEmployerId(employer.id, source.token)
                    .then(setWorksites),
                EnrollmentService.loadEnrollmentMetadata(blueprintTypes.EMPLOYEE_TYPE, source.token)
                    .then(setEmployeeTypes)
            ])
            .then(() => setIsLoading(false))
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    showNotification({ message: error.message, severity: 'error' });
                }
            });
        }
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [employer])

    useEffect(() => {
        // Tests if the object is not an empty object
        if(employerInfo && employerInfo.worksite) {
            reset({ employerInfo });
        }
    }, [reset, employerInfo])

    return (
        <Card variant="outlined">
            <CardHeader title="Employer Information"/>
            <CardContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EmployerCard employerName={employer?.name} countyName={county?.name} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.worksite"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field} 
                                        autoHighlight openOnFocus
                                        options={worksites}
                                        disabled={!employer}
                                        loading={isLoading}
                                        getOptionLabel={option => option?.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params} 
                                                error={Boolean(error)} 
                                                label="Worksite" 
                                                variant="outlined" 
                                                fullWidth required/>
                                        )}
                                        onChange={(_, data) => field.onChange(data)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.employeeID"
                                control={control}
                                defaultValue={""}
                                rules={{ required: false }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                        error={Boolean(error)} 
                                        label="Employee ID" 
                                        variant="outlined" 
                                        fullWidth {...field}/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.employeeType"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field} 
                                        autoHighlight openOnFocus
                                        options={employeeTypes}
                                        loading={isLoading}
                                        getOptionLabel={option => option?.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params} 
                                                error={Boolean(error)} 
                                                label="Employee Type" 
                                                helperText={<>Select <b>Unknown</b> if you are not sure what your Employee Type is</>}
                                                variant="outlined" 
                                                fullWidth required/>
                                        )}
                                        onChange={(_, data) => field.onChange(data)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.position"
                                control={control}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                        error={Boolean(error)} 
                                        label="Position" 
                                        variant="outlined" 
                                        helperText="Example: Math Teacher, Teacher 3rd, Bus Driver, Custodian, Graduate Assistant, Professor Physics"
                                        FormHelperTextProps={{ hidden: false }}
                                        fullWidth required  {...field}/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.workEmail"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    required: false,
                                    validate: {
                                        isValidEmailAddress
                                    }
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                        error={Boolean(error)} 
                                        helperText={error && error.message}
                                        label="Work Email" 
                                        variant="outlined" 
                                        fullWidth {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="employerInfo.workPhone"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <PhoneInput {...field}
                                        inputProps={{
                                            label: "Work Phone",
                                            variant: "outlined",
                                            fullWidth: true
                                        }}>
                                    </PhoneInput>
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <CardActions className="align-right">
                <Button onClick={navigateBack}>Back</Button>
                <LoadingButton 
                    variant="contained" 
                    color="primary" 
                    onClick={next}
                    loading={isSubmitting || isValidating}
                >
                    Next
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default EmployerInformation;