import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Alert, AlertTitle, Autocomplete, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, TextField } from "@mui/material";
import { NoteAddRounded } from "@mui/icons-material";
import { useEnrollmentInfo } from "../../providers/EnrollmentProvider";
import LoadingButton from "../../components/LoadingButton";
import EnrollmentService, { blueprintTypes } from "../../services/EnrollmentService";
import { EnrolleeTypes } from "./Entities";
import { useNotifications } from "../../providers/NotificationProvider";

function LocalUnionInformation({ navigateBack, navigateForward, save, saveAndNavigate }) {
    const showNotification = useNotifications();
    const { preSelectedLocalUnionId } = useParams();
    const { localUnionInfo } = useEnrollmentInfo();
    const { control, handleSubmit, reset, setValue, trigger, watch } = useForm({
        mode: "onTouched"
    });
    
    const [ jobs, setJobs ] = useState([]);
    const [ employers, setEmployers ] = useState([]);
    const [ localUnions, setLocalUnions ] = useState([]);
    
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isLoadingOptions, setIsLoadingOptions ] = useState(false);
    const [ isLoadingEmployers, setIsLoadingEmployers ] = useState(false);
    const [ isLoadingLocalUnions, setIsLoadingLocalUnions ] = useState(false);

    const selectedJob = watch('localUnionInfo.job');
    const selectedEmployer = watch('localUnionInfo.employer');
    const selectedLocalUnion = watch('localUnionInfo.localUnion');

    // Checks if preSelectedLocalUnionId is present in the URL
    const localPreSelected = preSelectedLocalUnionId !== undefined && preSelectedLocalUnionId > 0;
    
    const submitForm = handleSubmit(saveAndNavigate);

    const handleStartEnrollment = () => {
        setIsSubmitting(true);

        trigger()
            .then((isValid) => {
                if (isValid) {
                    // Loads the contact information for the selected local
                    EnrollmentService.loadLocalUnionContactInfo(selectedLocalUnion.id)
                        .then(({ address, phone }) => {
                            // Sets the employer and phone for the selected local union
                            const localUnion = {
                                ...selectedLocalUnion,
                                address,
                                phone,
                            }
                            setValue("localUnionInfo.localUnion", localUnion);
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                console.log("Failed to load local union's contact info", error)
                            }
                        })
                        .finally(() => {
                            // Submits information and moves on to next step
                            submitForm();
                        })
                } else {
                    setIsSubmitting(false);
                }
            }
        );
    }

    const onLoadLocalUnions = (localUnions) => {
        setLocalUnions(localUnions);
        // Checks if currently local union is not one of the options available
        if(!selectedLocalUnion || localUnions.findIndex((l) => l.id === selectedLocalUnion.id) === -1) {
            // Defaults selected local union to null
            let selectedLocalUnion = null;
            // Automatically selects local union if there is only one option
            if(localUnions.length === 1) {
                selectedLocalUnion = localUnions[0];
            }
            // Resets the selected local union value
            setValue("localUnionInfo.localUnion", selectedLocalUnion);
        }
    }
    
    useEffect(() => {
        const source = axios.CancelToken.source();
        // Triggers loading state
        setIsLoadingOptions(true);

        Promise.all([
            localPreSelected ? (
                // Loads enrollee types by local
                EnrollmentService.loadEnrolleeTypesByLocalId(preSelectedLocalUnionId, source.token)        
            ) : (
                // Loads all enrollee types
                EnrollmentService.loadEnrollmentMetadata(blueprintTypes.ENROLLEE_TYPE, source.token)
            )
        ])
        .then(([jobs]) => setJobs(jobs))
        .catch(error => {
            if (!axios.isCancel(error)) {
                console.error(error);
                showNotification({ message: error.message, severity: 'error' });
            }
        })
        .then(() => setIsLoadingOptions(false));
        
        return function cleanup() {
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localPreSelected])

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (selectedJob) { 
            if (selectedJob.id === EnrolleeTypes.NOT_SURE ||
                    selectedJob.id === EnrolleeTypes.COLLEGE_STUDENT ||
                        selectedJob.id === EnrolleeTypes.RETIREE) {
                // Resets the selected employer value
                setValue("localUnionInfo.employer", null);
                // Clears the list of employers
                setEmployers([]);
            } else {
                console.log("Loading employers by job")
                setIsLoadingEmployers(true);
                // Loads employers by selected job
                EnrollmentService.loadEmployersByJobId(selectedJob.id, source.token)
                    .then(employers => {
                        if(active) {
                            setEmployers(employers);
                            // Checks if currently selected employer is not one of the options available
                            if(!selectedEmployer || employers.findIndex((e) => e.id === selectedEmployer.id) === -1) {
                                // Defaults employer value to null
                                let employer = null;
                                // Automatically selects employer if there is only one option
                                if(employers.length === 1) {
                                    employer = employers[0];
                                } 
                                // Resets the selected employer value
                                setValue("localUnionInfo.employer", employer);
                            }
                        }
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            showNotification({ message: error.message, severity: 'error' });
                        }
                    })
                    .then(() => {
                        if(active) {
                            setIsLoadingEmployers(false);
                        }
                    });
            }
        }

        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel();
        }
        // eslint-disable-next-line
    }, [selectedJob])

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();
        
        if (active && 
                localPreSelected === false && 
                    selectedJob && selectedJob.id !== EnrolleeTypes.NOT_SURE) {
            console.log("Loading locals by job & employer (if selected)")
            setIsLoadingLocalUnions(true);
            EnrollmentService.loadLocalUnionsByJobAndEmployerId(selectedJob.id, (selectedEmployer ? selectedEmployer.id : 0), source.token)
                .then(localUnions => {
                    if(active) {
                        onLoadLocalUnions(localUnions);
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        showNotification({ message: error.message, severity: 'error' });
                    }
                })
                .then(() => {
                    if(active) {
                        setIsLoadingLocalUnions(false);
                    }
                });
        }

        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [localPreSelected, selectedJob, selectedEmployer])

    useEffect(() => {
        if (localUnionInfo && (localUnionInfo.job || localUnionInfo.localUnion)) {
            reset({ localUnionInfo });
        }
    }, [reset, localUnionInfo])

    return (
        <Card variant="outlined">
            <CardHeader title="Create New Enrollment" subheader={localPreSelected ? "Choose your role to begin" : "Choose your local union to begin"} />
            <CardContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="localUnionInfo.job"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        autoSelect autoHighlight openOnFocus
                                        options={jobs}
                                        loading={isLoadingOptions}
                                        getOptionLabel={option => option.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(error)}
                                                label="Who are you?"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {isLoadingOptions ? <CircularProgress size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                fullWidth required/>
                                        )}
                                        onChange={(_, data) => field.onChange(data)}
                                    />
                                )}
                            />
                        </Grid>
                        {selectedJob && selectedJob.id === EnrolleeTypes.NOT_SURE ? (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    <AlertTitle>Let's get you connected to your local union</AlertTitle>
                                    For further assistance please send an email to <strong><a target="_blank" rel="noreferrer" href="mailto:centralmembership@floridaea.org" style={{ textDecoration: "none", color: "inherit" }}>centralmembership@floridaea.org</a></strong>.
                                </Alert>
                            </Grid>
                        ) : !localPreSelected ? (
                            <React.Fragment>   
                                {// Only renders Employer field if Job is not Student or Retiree
                                (selectedJob && selectedJob.id !== EnrolleeTypes.COLLEGE_STUDENT && selectedJob.id !== EnrolleeTypes.RETIREE) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            name="localUnionInfo.employer"
                                            control={control}
                                            defaultValue={null}
                                            // Sets required to false because if enabled it prevents the form
                                            // from being valid when this component is not rendered.
                                            rules={{ required: false }}
                                            render={({ field, fieldState: { error } }) => (
                                                <Autocomplete
                                                    {...field}
                                                    autoSelect autoHighlight openOnFocus
                                                    disabled={!selectedJob}
                                                    options={employers}
                                                    loading={isLoadingEmployers}
                                                    getOptionLabel={employer => employer.name}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(error)}
                                                            label="Employer"
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {isLoadingEmployers ? <CircularProgress size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                            fullWidth required/>
                                                    )}
                                                    onChange={(_, data) => field.onChange(data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                {(selectedJob && (selectedJob.id === EnrolleeTypes.COLLEGE_STUDENT || selectedJob.id === EnrolleeTypes.RETIREE || selectedEmployer)) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            name="localUnionInfo.localUnion"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <Autocomplete
                                                    {...field}
                                                    autoSelect autoHighlight openOnFocus
                                                    options={localUnions}
                                                    loading={isLoadingLocalUnions}
                                                    getOptionLabel={localUnion => localUnion.name}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(error)}
                                                            label="Local Union"
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {isLoadingLocalUnions ? <CircularProgress size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                            fullWidth required/>
                                                    )}
                                                    onChange={(_, data) => field.onChange(data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            </React.Fragment>
                        ) : null }
                    </Grid>
                </form>
            </CardContent>
            <CardActions className="space-between">
                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handleStartEnrollment}
                    disabled={!selectedLocalUnion || (selectedJob && selectedJob.id === EnrolleeTypes.NOT_SURE)}
                    loading={isSubmitting}
                    endIcon={<NoteAddRounded />}
                    >
                    Start Enrollment
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default LocalUnionInformation;