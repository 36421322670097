import React, { useState } from "react";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// import { DropzoneArea } from "material-ui-dropzone";
import LoadingButton from "../../../components/LoadingButton";

import "./ChangePicture.css";

function ChangePicture({ onClose }) {
    // const [ selectedPicture, setSelectedPicture] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ open, setOpen ] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        
        setTimeout(() => {
            // TODO: Upload picture/change user picture
            setLoading(false);
            setOpen(false);
        }, 1000);
    }

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    return (
        <React.Fragment>
            {/* <Tooltip title="Change Picture" arrow> */}
                {/* <div className="profile-picture" onClick={() => setOpen(true)}> */}
                    <Avatar 
                        style={{ height: 100, width: 100 }}
                        className="avatar"
                        // src={bradPitt} 
                    />
                    {/* <PhotoCamera className="overlay" />
                </div> */}
            {/* </Tooltip> */}
            <Dialog open={open} maxWidth="xs">
                <DialogTitle>Change picture</DialogTitle>
                <DialogContent>
                    {/* <DropzoneArea
                        filesLimit={1}
                        showPreviews={true}
                        useChipsForPreview={true}
                        showPreviewsInDropzone={false}
                        acceptedFiles={['image/*']}
                        dropzoneClass="avatar-upload"
                        previewText="Selected file:"
                        showAlerts={['error']}
                        dropzoneText="Drag and drop an image or click here"
                        onChange={(files) => setSelectedPicture(files[0])}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose} 
                    >
                        Cancel
                    </Button>
                    <LoadingButton 
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit} 
                        // disabled={!selectedPicture}
                        loading={loading}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ChangePicture;