import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import NewPasswordForm from "./NewPasswordForm";

function PasswordResetForm() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:guid`}>
                <Grid container spacing={2} alignItems="center" direction="column">
                    <Grid item xs={8}>
                        <NewPasswordForm />
                    </Grid>
                </Grid>
            </Route>
            <Route path={`${path}`} exact>
                <Redirect to={{
                    pathname: '/signin'
                }}/>
            </Route>
        </Switch>
    )
}

export default PasswordResetForm;