import { Card, CardContent, Grid, useMediaQuery } from '@mui/material';
import Profile from './Profile';
import PaymentMethod from './PaymentMethod';
import Memberships from './Memberships';
import { useAuth } from "../../providers/AuthProvider";
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import MemberService from '../../services/MemberService';

function SettingsView({ title }) {

    const { user } = useAuth();

    const memberships = useRef([]);
    const [ hasPendingNMEs, setHasPendingNMEs ] = useState(false);
    const [ isLoadingMemberships, setIsLoadingMemberships ] = useState(false);

    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('md'));

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        setIsLoadingMemberships(true);

        MemberService.loadMemberships(user?.id, source.token)
            .then((result) => {
                if(active) {
                    memberships.current = result;
                    setHasPendingNMEs(true);
                }
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    // If no Membership data is found
                    if (error.response?.status === 404) {
                        // Hide payment method section
                        setHasPendingNMEs(false);
                        console.log("User doesn't have any active memberships or pending NMEs.");
                    } else {
                        console.log("Couldn't load user memberships.");
                    }
                }
            })
            .then(() => {
                if(active) {
                    setIsLoadingMemberships(false);
                }
            });
            
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [user?.id])

    return (
        <>
            {!isMobileDevice && (
                <header className="app-header">
                    <h2>{title}</h2>
                </header>
            )}
            <Grid container spacing={2} flexDirection="column">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardContent>
                            <Memberships memberships={memberships.current} hasPendingNMEs={hasPendingNMEs} isLoading={isLoadingMemberships} />
                        </CardContent>
                    </Card>
                </Grid>
                {
                    hasPendingNMEs && (
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <PaymentMethod />
                        </Grid>
                    )
                }
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Profile />
                </Grid>
            </Grid>
        </>
    )
}

export default SettingsView;