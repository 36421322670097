import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Fab, FormControlLabel, Grid, Link, TextField, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { AccountBalanceWallet, ArrowDownward, Replay, Warning } from '@mui/icons-material';
import DwollaService from '../../services/DwollaService';
import MemberService from '../../services/MemberService';
import { useAuth } from '../../providers/AuthProvider';
import { useNotifications } from '../../providers/NotificationProvider';
import FEATermsOfServiceLink from '../TermsOfServiceLink/FEA';
import FEAPrivacyPolicyLink from '../PrivacyPolicyLink/FEA';
import DwollaTermsOfServiceLink from '../TermsOfServiceLink/Dwolla';
import DwollaPrivacyPolicyLink from '../PrivacyPolicyLink/Dwolla';
import PlaidAccountVerification from '../PlaidAccountVerification';
import useOnScreen from '../../util/useOnScreen';
import { texts } from '../../resources';

// Prevents user from closing tab before finishing sign up process
function preventClosing(event) {
    event.preventDefault();
    return event.returnValue = '';
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: '1rem',
        gap: '1rem',
        marginBottom: '2rem',
    },
    icon: {
        fontSize: "3rem",
    },
    bankIcon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

function DwollaACHSetup({ dwollaCustomerId, paymentTerms, onCancel, onSuccess, containerStyle }) {
    const classes = useStyles();
    const { user } = useAuth();
    const showNotification = useNotifications();

    const message = useRef("");
    const terms = paymentTerms;
    const termsCheckbox = useRef();
    const agreeButton = useRef();
    
    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ agreedToTerms, setAgreedToTerms ] = useState(false);
    
    const [ customerId, setCustomerId ] = useState(dwollaCustomerId);
    const [ plaidConfig, setPlaidConfig ] = useState();
    
    const isMobileDevice = useMediaQuery(theme => theme.breakpoints.down('md'));
    const isCheckboxVisible = useOnScreen(termsCheckbox);

    const onChangeTermsCheckbox = () => {
        // Inverts current value
        setAgreedToTerms(prevValue => !prevValue);
        // Scrolls down to agree button
        agreeButton.current.scrollIntoView();
    }

    const beforeClosingDialog = () => {
        // Clear Plaid Config
        setPlaidConfig(undefined);
        // Removes event listener when user reaches last step
        window.removeEventListener("beforeunload", preventClosing);
    }

    const handleCancel = () => {
        beforeClosingDialog();
        onCancel();
    }

    const handleSuccess = () => {
        beforeClosingDialog();
        onSuccess();
    }

    const createDwollaCustomer = () => {
        message.current = "Saving...";
        setLoading(true);

        MemberService.createMemberACH(user.id)
            .then((response) => {
                setCustomerId(response.customerId);
            })
            .catch((error) => {
                showNotification({ message: error.message, severity: 'error', duration: 30000 });
            });
    }
    
    const createPlaidLink = (cancelToken) => {
        // Add event listener to prevent user from closing browser window
        window.addEventListener("beforeunload", preventClosing);

        message.current = "Preparing bank account set up...";
        setLoading(true);
        // Creates Plaid Link Token
        DwollaService.createPlaidLinkToken(customerId, cancelToken)
            .then(({ expiration, linkToken }) => setPlaidConfig({
                expiration,
                linkToken
            }))
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    message.current = 'Failed to create Plaid Link';
                    setError(true);
                }
            })
            .then(() => setLoading(false));
    }

    const retryPlaidAccountSetup = () => {
        // Removes previous error message
        message.current = '';
        setError(false);
        // Loads new Plaid Link
        createPlaidLink();
    }

    const onFundingSourceSetupSuccess = ({ public_token, accounts }) => {
        const source = axios.CancelToken.source();
        // Triggers loading state
        message.current = "Saving...";
        setLoading(true);
        
        if (accounts && accounts.length > 0) {
            // Looks ahead to see if there are any accounts pending verification (micro-deposit)
            const accountPendingVerification = accounts.find(({ verification_status }) => {
                return verification_status === "pending_manual_verification" || verification_status === "pending_automatic_verification";
            });

            // If there are pending accounts
            if (accountPendingVerification) {
                // Save account id and token in the NME, then close dialog
                DwollaService.savePlaidAccountVerificationInfo(customerId, public_token, accountPendingVerification.id, accountPendingVerification.name, accountPendingVerification.verification_status, source.token)
                    .then(() => {
                        // Closes dialog
                        handleSuccess();
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            message.current = "Failed to save account information";
                            setError(true);
                        }
                    });
            } else {
                Promise.all(
                    // Returns a promise for every account
                    // NOTE: There will always be only ONE record returned by Plaid in our use case
                    //       but still, their data stutructure returns an array
                    accounts.map((account) => {
                        return DwollaService.createAndSetFundingSourceWithPlaidPublicToken(customerId, public_token, account["id"], account["name"], source.token);
                    })
                )
                .then(() => {
                    // Closes dialog
                    handleSuccess()
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        message.current = error.response?.data?.message || error.message;
                        setError(true);
                    }
                });
            }
        } else {
            // Loads list of available funding sources for the member (Dwolla's Customer)
            // loadMemberFundingSources();
            message.current = "Failed to create Dwolla's funding source";
            setError(true);
        }
    }

    const onFundingSourceSetupError = (error_metadata) => {
        console.log("error", error_metadata)
        if (error_metadata?.error_code === "INVALID_FIELD" || error_metadata?.error_code === "TOO_MANY_VERIFICATION_ATTEMPTS") {
            message.current = "Loading...";
            setLoading(true);
            // Clear up token for account verification
            DwollaService.removePlaidAccountVerificationInfo(customerId)
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                })
                .then(() => {
                    setLoading(false);
                    message.current = "You exceeded the number of tries.";
                    setError(true);
                })
        } else {
            message.current = "Failed to link bank account using Plaid";
            setError(true);
        }
    }
    
    const onFundingSourceSetupCancel = () => {
        message.current = "Bank account linking was cancelled";
        setError(true);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (customerId) {
            createPlaidLink(source.token);
        }

        return function cleanup() {
            // Cancels requests on component unmount
            source.cancel()
        }
        // eslint-disable-next-line
    }, [customerId])

    return (
        <Card variant="outlined" style={containerStyle}>
            <CardHeader title="Set up ACH Payment" subheader="Set up a recurring payment of dues" />
            {error ? (
                <>
                    <CardContent>
                        <div className={classes.container}>
                            <Warning color="inherit" className={classes.icon} />
                            <Typography variant="h6" sx={{ textAlign: "center" }}>
                                {message.current}
                            </Typography>
                            <Alert severity="info">
                                <AlertTitle>Running into trouble?</AlertTitle>
                                For further assistance please send an email to <strong><a target="_blank" rel="noreferrer" href="mailto:centralmembership@floridaea.org" style={{ textDecoration: "none", color: "inherit" }}>centralmembership@floridaea.org</a></strong>.
                            </Alert>
                        </div>
                    </CardContent>
                    {plaidConfig && (
                    <CardActions className={onCancel ? "space-between" : "align-right"}>
                        {onCancel && (
                            <Link onClick={handleCancel}>Cancel</Link>
                        )}
                        <Button 
                            onClick={retryPlaidAccountSetup}
                            variant="contained"
                            color="primary"
                            startIcon={<Replay />}
                        >
                            Try Again
                        </Button>
                    </CardActions>
                    )}
                </>
            ) : loading ? (
                <div className={classes.container}>
                    <CircularProgress color="inherit" className={classes.icon} />
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {message.current}
                    </Typography>
                </div>
            ) : !customerId ? (
                <>
                    <CardContent>
                        <Box sx={{ mb: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        value={user.firstName}
                                        disabled fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        value={user.lastName}
                                        disabled fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email Address"
                                        variant="outlined"
                                        value={user.username}
                                        disabled fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    {isMobileDevice && !isCheckboxVisible && (
                                        <Fab color="primary" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => agreeButton.current.scrollIntoView()}>
                                            <ArrowDownward />
                                        </Fab>
                                    )}
                                    <TextField 
                                        label="Payment Terms"
                                        variant="outlined"
                                        value={terms?.current}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        minRows={3}
                                        multiline fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox ref={termsCheckbox} color="primary" checked={agreedToTerms} onChange={onChangeTermsCheckbox} required />}
                                        label={<>
                                            By checking this box acknowledge and agree you are enrolling in recurring payments and agree to the payment terms above, <FEATermsOfServiceLink>Our Terms of Service</FEATermsOfServiceLink> and <FEAPrivacyPolicyLink>Privacy Policy</FEAPrivacyPolicyLink> as well as our partner <DwollaTermsOfServiceLink>Dwolla's Terms of Service</DwollaTermsOfServiceLink> and <DwollaPrivacyPolicyLink>Privacy Policy</DwollaPrivacyPolicyLink>. Your membership will automatically renew on an annual basis and you will continue to be charged unless you cancel.
                                        </>}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                    <CardActions className={onCancel ? "space-between" : "align-right"}>
                        {onCancel && (
                            <Link onClick={handleCancel}>Cancel</Link>
                        )}
                        <Button
                            ref={agreeButton}
                            variant={"contained"}
                            color={"primary"}
                            onClick={createDwollaCustomer}
                            disabled={!agreedToTerms}
                            startIcon={<AccountBalanceWallet />}
                        >
                            Agree and continue
                            </Button>
                    </CardActions>
                </>
            ) : plaidConfig ? (
                <PlaidAccountVerification linkToken={plaidConfig.linkToken} onSuccess={onFundingSourceSetupSuccess} onError={onFundingSourceSetupError} onCancel={onFundingSourceSetupCancel} />
            ) : (
                <></>
            )}
        </Card>
    );
}

export default DwollaACHSetup;