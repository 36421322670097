import { useEffect, useState } from "react";
import axios from "axios";
import { Business } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Grid, Skeleton, Typography } from "@mui/material";
import { useMembership } from "../../views/SettingsView/Memberships";
import EnrollmentService from "../../services/EnrollmentService";
import LocalContactInfo from "../LocalContactInfo";
import MembershipDetails from "../MembershipDetails";
import { useAuth } from "../../providers/AuthProvider";

import "./LocalUnionCard.css";

function LocalUnionCard({ id, name }) {

    const { user } = useAuth();
    const { membership, localUnion } = useMembership();

    const localId = id || localUnion?.id;
    const localName = name || localUnion?.name;

    const [ isLoadingLogo, setIsLoadingLogo ] = useState(true);
    const [ localUnionLogo, setLocalUnionLogo ] = useState();

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        EnrollmentService.loadLocalUnionLogo(localId, source.token)
            .then((logo) => {
                if(active) {
                    setLocalUnionLogo(logo);
                }
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    setLocalUnionLogo(null);
                    console.log("Couldn't load logo for Local Union #" + localId);
                }
            })
            .then(() => {
                if(active) {
                    setIsLoadingLogo(false);
                }
            });
                        
        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
    }, [localId])

    return (
        <Card variant="outlined" className="local-union-card">
            {isLoadingLogo ? (
                <Skeleton sx={{ height: 90 }} animation="pulse" variant="rectangular" />
            ) : localUnionLogo ? (
                <CardMedia
                    component="img"
                    alt="logo"
                    height="90"
                    image={localUnionLogo}
                    sx={{ width: "auto", margin: "0 auto" }}
                />
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: "10px" }}>
                    <Business sx={{ height: 80, width: 80 }} color="disabled" />
                </Box>
            )}
            <CardContent>
                <Typography variant="body2" sx={{ textAlign: "center", fontWeight: "bold", mb: ".5rem" }}>
                    {localName}
                </Typography>
                <LocalContactInfo leftAlign localUnionId={localId} />
                {/* Only displays the membership details button if user is logged in */}
                {user && user?.id && membership && (
                    <Grid container justifyContent="center" sx={{ mt: "1rem" }}>
                        <Grid item>
                            <MembershipDetails />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    )
}

export default LocalUnionCard;