import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, InputAdornment, Link, TextField, Typography } from "@mui/material";
import { CheckCircle, Save } from "@mui/icons-material";
import LoadingButton from "../../../components/LoadingButton";
import { useNotifications } from "../../../providers/NotificationProvider";
import { useAuth } from "../../../providers/AuthProvider";
import axios from "axios";
import PasswordRequirements from "../../../components/ChangePassword/PasswordRequirements";
import AuthService from "../../../services/AuthService";
import { isValidPassword } from "../../../util/Validators";

function NewPasswordForm() {

    const history = useHistory();
    const { guid } = useParams();
    const { isSignedIn, recoverAccountWithLink, signout } = useAuth();
    const showNotification = useNotifications();

    const { control, formState: { errors, isValid }, handleSubmit, reset, trigger, setValue, watch } = useForm({
        mode: "onChange"
    });

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ showRequirements, setShowRequirements ] = useState(false);
    
    const newPassword = watch("newPassword");
    const confirmPassword = watch("confirmPassword");

    const showPasswordRequirements = () => setShowRequirements(true);
    const hidePasswordRequirements = () => setShowRequirements(false);
    
    const handleResetPassword = ({ newPassword }) => {
        setIsSubmitting(true);

        recoverAccountWithLink(guid, newPassword)
            .then(() => {
                showNotification({ message: "Password updated successfully!", severity: 'success', duration: 10000 });
            })
            .catch(() => {
                showNotification({ message: "Invalid password recovery link. Try again.", severity: 'error', duration: 20000 });
            })
            .finally(() => {
                history.replace('/signin');
            })
    }
    
    const submitForm = handleSubmit(handleResetPassword);

    const onClickSubmit = () => {
        setIsSubmitting(true);
        trigger()
            .then((isValid) => {
                if (isValid) {
                    submitForm();
                } else {
                    showNotification({ message: "Fix the highlighted fields and try again.", severity: 'error', duration: 10000 });
                    setIsSubmitting(false);
                }
            })
        }

    useEffect(() => {
        let active = true;
        const source = axios.CancelToken.source();

        if (active) {
            if (isSignedIn && !isSubmitting) {
                signout();
            } else {
                AuthService.validateAccountRecoveryLink(guid, source.token)
                    .then(() => {
                        if (active) {
                            setIsLoading(false);
                        }
                    })
                    .catch(() => {
                        if (active) {
                            showNotification({ 
                                message: <Typography variant="body1">Your password reset link has expired. Please <Link onClick={() => history.replace('/signin/forgotPassword')}>click here</Link> to request a new reset link.</Typography>, 
                                severity: 'error', 
                                duration: 20000 
                            });
                            // Redirects to login screen
                            history.replace('/signin')
                        }
                    });
            }
        }

        return function cleanup() {
            active = false;
            // Cancels requests (cleanup) on component unmount
            source.cancel()
        }
        //eslint-disable-next-line
    }, [isSignedIn, guid, reset])

    return (
        <Card>
            <CardHeader title="Password Reset" subheader="Let's create new credentials for your account."/>
            {isLoading ? (
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                </CardContent>
            ) : (
                <>
                    <CardContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ 
                                            required: "Please type a new password.",
                                            validate: {
                                                isValidPassword
                                            }
                                        }}
                                        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                                            <TextField 
                                                error={Boolean(error)}
                                                helperText={(error && error.message)} 
                                                onChange={(e) => {
                                                    // Empties confirm password field
                                                    setValue("confirmPassword", "");
                                                    onChange(e);
                                                }}
                                                inputProps={{
                                                    onFocus: showPasswordRequirements,
                                                    onBlur: hidePasswordRequirements,
                                                }}
                                                label="New password" 
                                                variant="outlined" 
                                                type="password" 
                                                autoComplete="new-password" 
                                                required fullWidth {...field}/>
                                        )}
                                    />
                                </Grid>
                                <PasswordRequirements hide={!showRequirements} password={newPassword} />
                                <Grid item xs={12}>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ 
                                            required: "Please confirm the new password.",
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                    return newPassword === value || "Passwords should match.";
                                                }
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField 
                                                error={Boolean(error)}
                                                helperText={(error && error.message)} 
                                                disabled={!newPassword || Boolean(errors["newPassword"])}
                                                label="Confirm new password" 
                                                variant="outlined" 
                                                type="password" 
                                                autoComplete="confirm-password"
                                                InputProps={{
                                                    endAdornment: confirmPassword && !error && (
                                                        <InputAdornment position="end">
                                                            <CheckCircle color="primary"/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                required fullWidth {...field}/>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <CardActions className="align-right">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={onClickSubmit}
                            disabled={!isValid}
                            loading={isSubmitting}
                            startIcon={<Save />}
                        >
                            Save Changes
                        </LoadingButton>
                    </CardActions>
                </>
            )}
        </Card>
    )
}

export default NewPasswordForm;