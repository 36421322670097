import { Button, CircularProgress } from "@mui/material";

const LoadingIcon = () => <CircularProgress size="1rem" color="inherit" />

const LoadingButton = ({ children, disabled, loading, startIcon, endIcon, ...props }) => {
    const newProps = {
        ...props,
        // Button is disabled when loading or disabled=true
        disabled: disabled || loading,
        // Uses startIcon for loading if endIcon is not defined
        startIcon: loading && startIcon && !endIcon ? (
            <LoadingIcon /> 
        ) : startIcon,
        // Uses endIcon for loading if there is no startIcon or if there is an endIcon defined
        endIcon: loading && (!startIcon || endIcon) ? (
            <LoadingIcon />
        ) : endIcon
    };

    return (
        <Button {...newProps}>
            {children}
        </Button>
    )
}

export default LoadingButton;